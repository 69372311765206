import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit'

type AdminVehicleHistoryState = {
  page: number;
  perPage: number;
  filters: Record<string, any>;
}

const initialState: AdminVehicleHistoryState = {
  page: 1,
  perPage: 24,
  filters: {}
};

const adminVehicleHistorySlice = createSlice({
  name: 'adminVehicleHistory',
  initialState,
  reducers: {
      setPage: (state, action: PayloadAction<number>) => {
        state.page = action.payload;
      },
      setFilters(state, action: PayloadAction<Record<string, any>>) {
        state.filters = action.payload
      },
      resetFilters(state) {
        state.page = 1;
        state.perPage = 24;
        state.filters = {
        }
      }
    }
});

export const { setPage, setFilters, resetFilters } = adminVehicleHistorySlice.actions
export default adminVehicleHistorySlice;
