import React from 'react';
import { Offer, OfferAuction, OfferStatus } from '../../types/vehicle';
import styled from 'styled-components';
import FormattedAmount from '../FormattedAmount';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { Divider, Grid } from '@material-ui/core';
import { cutTextElipsis } from '../../utils';
import { AutoaxessMessageIcon, SystemAlertIcon } from './icons';

type Props = {
  offerAuction: OfferAuction;
  withDivider?: boolean;
  cutNames?: boolean;
  withTopDivider?: boolean;
};

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  padding: 0px 8px 8px 8px;

  background: rgba(249, 249, 249, 1);
  border-radius: 30px;
`;

const PageHeaderWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-left: 16px;
  padding-top: 24px;
`;

const HeaderTitle = styled.span`
  font-weight: 700;
  font-size: 22px;
  line-height: 26px;
  color: rgba(33, 33, 33, 1);
`;

const BlockWrapper = styled.div`
  border-radius: 30px;
  background: #fff;
  padding-top: 8px;
  display: flex;
  flex-direction: column;
  max-height: 230px;
  overflow-y: auto;

  /* total width */
  &::-webkit-scrollbar {
    background-color: #f5f5f5;
    width: 12px;
  }

  /* background of the scrollbar except button or resizer */
  &::-webkit-scrollbar-track {
    background-color: #f5f5f5;
  }

  /* scrollbar itself */
  &::-webkit-scrollbar-thumb {
    background-color: #babac0;
    border-radius: 16px;
    border: 4px solid #f5f5f5;
  }

  /* set button(top and bottom of the scrollbar) */
  &::-webkit-scrollbar-button {
    display: none;
  }
`;

const OfferWrapper = styled.div`
  padding: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const TitleWrapper = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

const Title = styled.span`
  color: #000;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
`;

const Amount = styled.span`
  color: #3994de;
  background: rgba(57, 148, 222, 0.05);
  font-family: DM Sans;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  border-radius: 20px;
  padding: 4px 16px;
`;

const Timestamp = styled.span`
  color: rgba(114, 119, 122, 1);
  font-family: DM Sans;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
`;

const NotesWrapper = styled.div<{ isAlert?: boolean }>`
  padding: 8px 16px;
  width: 100%;
  border-radius: 4px;
  color: ${props => (props.isAlert ? 'rgba(175, 41, 48, 1)' : '#000')};
  background: ${props => (props.isAlert ? 'rgba(175, 41, 48, 0.1)' : 'rgba(86, 111, 141, 0.1)')};
  font-size: 14px;
  font-weight: 400;
  border-radius: 24px;
  border-top-left-radius: 0px;
`;

const MainContent = styled.div<{ isReverse?: boolean }>`
  display: flex;
  gap: 8px;
  flex-direction: ${props => (props.isReverse ? 'row-reverse' : 'row')};
`;

const StyledDivider = styled(Divider)`
  && {
    margin: 24px 0;
  }
`;

type Alert = {
  amount?: number | null;
  text: string;
  created_at: string | moment.Moment;
  type: 'alert';
};

const formatTimestamp = (timestamp: string | moment.Moment) => {
  return moment(timestamp).format('ddd DD.MM.YY h:mm A');
};

const isAlert = (item: Alert | Offer): item is Alert => {
  return (item as Alert).type === 'alert';
};

const resolveAmount = (amount: number) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0
  }).format(amount);
};

export const OfferHistory = ({
  offerAuction,
  withDivider = false,
  withTopDivider = false,
  cutNames = false
}: Props) => {
  const user = useSelector((state: any) => state.user.user);
  const buyerResponseTime = useSelector(
    (state: any) => state.locations?.currentLocation?.buyer_response_time || 900000
  );
  const sellerResponseTime = useSelector(
    (state: any) => state.locations?.currentLocation?.seller_response_time || 900000
  );

  let privateOffers = offerAuction?.privateOffers?.filter(el => el.type === 'private') ?? [];
  const publicOffers = (() => {
    if (user.role === 'buyer') return [];
    return offerAuction?.privateOffers?.filter(el => el.type === 'public') ?? [];
  })();

  if (privateOffers.length === 0 && publicOffers.length === 0) return null;

  if (user.role === 'buyer') {
    privateOffers = privateOffers.filter(offer => offer.status !== OfferStatus.ADMIN_COUNTERED);
  }

  const offerBids = offerAuction?.offerBids ?? [];

  const rejectedAlerts: Alert[] = (() => {
    const lastOffer = privateOffers[0];
    if (!lastOffer || lastOffer.status !== OfferStatus.REJECTED) return [];

    const buyerRejectedOfferText = ({
      buyer: `You've missed your chance negotiating as Top Buyer. You can still continue making offers with other buyers.`,
      admin: `Buyer is not responding. Take any action to sell this car.`,
      seller: `Buyer is not responding. Our Team is working on it.`
    } as Record<string, string>)[user.role];

    const hasAdminPrivateCounter = privateOffers.some(el => el.is_admin);
    const sellerRejectedOfferText = ({
      buyer: `Seller is not responding. Our Team is working on it. You can still continue making offers with other buyers.`,
      admin: hasAdminPrivateCounter
        ? `Seller is not responding. The counter was sent to all buyers`
        : `Seller is not responding. Take any action to sell this car.`,
      seller: `You've missed your chance to negotiate with the Top Buyer. You can still receive offers from other buyers.`
    } as Record<string, string>)[user.role];

    const responseTime = lastOffer.sender.role === 'buyer' ? sellerResponseTime : buyerResponseTime;
    const text = lastOffer.sender.role === 'buyer' ? sellerRejectedOfferText : buyerRejectedOfferText;

    const amount = (() => {
      if (lastOffer.sender.role !== 'buyer' || !hasAdminPrivateCounter || publicOffers.length === 0) return null;

      return publicOffers.reverse().find(el => el.is_admin)?.amount || null;
    })();

    return [
      {
        amount,
        text,
        created_at: moment(lastOffer.created_at).add(responseTime, 'milliseconds'),
        type: 'alert'
      }
    ];
  })();

  const offerAcceptedAlerts: Alert[] = (() => {
    if (user.role === 'buyer') return [];
    const sellerAcceptedOffer = offerBids.find(el => el.status === 'seller_accepted');
    if (!sellerAcceptedOffer) return [];

    const acceptedOfferText = ({
      admin: `Seller accepted the top offer.`,
      seller: `You've accepted the top offer. Admin is reviewing it. You will get the updates shortly.`
    } as Record<string, string>)[user.role];

    return [{ text: acceptedOfferText, created_at: sellerAcceptedOffer.updated_at, type: 'alert' }];
  })();

  const negotiationStoppedAlerts: Alert[] = (() => {
    const negotiationStoppedOffer = offerAuction?.privateOffers?.find(el => el.is_admin && el.is_negotiation_stopped);
    if (!negotiationStoppedOffer || user.role !== 'admin') return [];

    const negotiationStoppedAlertText = ({
      admin: `AutoAxess stopped countering.`,
      seller: `AutoAxess stopped countering.`,
      buyer: 'AutoAxess stopped countering.'
    } as Record<string, string>)[user.role];

    return [
      {
        text: negotiationStoppedAlertText,
        created_at: negotiationStoppedOffer.created_at, // moment(negotiationStoppedOffer.created_at).subtract(-1, 'milliseconds'),
        type: 'alert'
      }
    ];
  })();

  const offerEditedAlerts: Alert[] = (() => {
    const editedOffers = offerAuction?.privateOffers?.filter(el => el.is_edited);
    return editedOffers
      .map<Alert[]>(offer => {
        return [
          {
            text: `New offer sum ${resolveAmount(offer.amount)}.`,
            created_at: offer.edited_at!,
            type: 'alert'
          },
          {
            text: `AutoAxess changed the offer.`,
            created_at: offer.edited_at!,
            type: 'alert'
          }
        ];
      })
      .flat();
  })();

  const items = [
    ...privateOffers,
    ...publicOffers,
    ...negotiationStoppedAlerts,
    ...offerAcceptedAlerts,
    ...rejectedAlerts,
    ...offerEditedAlerts
  ].sort((a, b) => moment(b.created_at).diff(a.created_at));

  const getLabelAndIcon = (offer: Offer) => {
    const label =
      offer.is_admin && user.role !== 'buyer'
        ? 'AutoAxess'
        : cutTextElipsis(offer.sender.dealership_name, cutNames ? 15 : 100);
    const Icon =
      offer.is_admin && user.role !== 'buyer'
        ? AutoaxessMessageIcon
        : createIconComponent(offer.sender.dealership_name);
    return { Icon, label };
  };

  const OfferComponent = ({ offer }: { offer: Offer }) => {
    const { Icon, label } = getLabelAndIcon(offer);
    const isReverse = (() => {
      if (user.role === 'admin') return !offer.is_admin;
      return offer.sender_id === user.id;
    })();
    return (
      <OfferWrapper>
        <Header>
          <TitleWrapper>
            <Title>{label}</Title>
            <Amount>
              <FormattedAmount amount={offer.amount} />
            </Amount>
          </TitleWrapper>
          <Timestamp>{formatTimestamp(offer.created_at)}</Timestamp>
        </Header>
        <MainContent isReverse={isReverse}>
          <Icon />
          <NotesWrapper>
            <span>{offer.notes}</span>
          </NotesWrapper>
        </MainContent>
      </OfferWrapper>
    );
  };

  const AlertComponent = ({ alert }: { alert: Alert }) => (
    <OfferWrapper>
      <Header>
        <TitleWrapper>
          <Title>{'System Alert'}</Title>
          {alert.amount && (
            <Amount>
              <FormattedAmount amount={alert.amount} />
            </Amount>
          )}
        </TitleWrapper>
        <Timestamp>{formatTimestamp(alert.created_at)}</Timestamp>
      </Header>
      <MainContent>
        <SystemAlertIcon />
        <NotesWrapper isAlert>
          <span>{alert.text}</span>
        </NotesWrapper>
      </MainContent>
    </OfferWrapper>
  );

  return (
    <>
      {withTopDivider && (
        <Grid item xs={12}>
          <StyledDivider />
        </Grid>
      )}
      <PageWrapper>
        <PageHeaderWrapper>
          <HeaderTitle>Notes</HeaderTitle>
        </PageHeaderWrapper>
        <BlockWrapper>
          {items.map((item, i) =>
            isAlert(item) ? <AlertComponent alert={item} key={i} /> : <OfferComponent offer={item} key={i} />
          )}
        </BlockWrapper>
      </PageWrapper>
      {withDivider && (
        <Grid item xs={12}>
          <StyledDivider />
        </Grid>
      )}
    </>
  );
};

const UserIcon = styled.div`
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  text-transform: capitalize;
  background: rgba(57, 148, 222, 0.05);
`;

const createIconComponent = (senderName: string) => {
  const firstLetter = senderName[0];
  return () => <UserIcon>{firstLetter}</UserIcon>;
};
