import styled from 'styled-components';
import { SectionConfig } from '../../../../services/api/vehicle-sections/types';
import { useGetSubSectionConfig } from '../../../CreateVehicle/hooks/useGetSubSectionConfig';
import { NotesView } from './NotesView';
import { BottomControls } from './BottomControls';
import { useState } from 'react';
import { PhotosView } from './PhotosView';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  background: #fff;
  padding: 14px 16px 16px;
`;

const Title = styled.div`
  font-size: 14px;
  font-weight: 600;
  line-height: 16.41px;
  color: rgba(0, 0, 0, 1);
`;

type Props = {
  sectionId: keyof SectionConfig;
  subSectionId: string;
};

export const ExpandedSubSection = ({ sectionId, subSectionId }: Props) => {
  const subSectionConfig = useGetSubSectionConfig(sectionId, subSectionId);
  const [activeControl, setActiveControl] = useState('notes');

  if (!subSectionConfig) return null;

  const sectionName = `${sectionId}.${subSectionId}`;

  return (
    <Wrapper>
      <Title>{subSectionConfig.label}</Title>
      {activeControl === 'photos' ? <PhotosView sectionName={sectionName} /> : <NotesView sectionName={sectionName} />}
      <BottomControls activeControl={activeControl} setActiveControl={setActiveControl} />
    </Wrapper>
  );
};
