import React from 'react';
import styled from 'styled-components';
import { Button } from '@material-ui/core';
import { useAdminCounterControls } from 'hooks';
import { SendOfferModal, AcceptOfferModal } from 'components/Offers';
import { Vehicle } from '../../../../../../../types/vehicle';

const CounterControlsWrapper = styled.div`
  display: flex;
  gap: 8px;
  padding-bottom: 30px;
  width: 100%;

  & > button {
    flex: 1 1 100%;
  }
`;

const ControlButton = styled(Button)<{ color: string; textColor: string }>`
  border-radius: 6px;
  width: 94px;
  height: 44px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  background: ${props => props.color};
  color: ${props => props.textColor};
  font-size: 14px;
  font-weight: 500;

  &:hover {
    background: ${props => props.color};
  }
`;

export const ControlsComponent = ({ vehicle }: { vehicle: Vehicle }) => {
  const buttons = useAdminCounterControls(vehicle.id);

  return (
    <>
      <CounterControlsWrapper>
        {buttons.map(btn => (
          <ControlButton key={btn.title} color={btn.color as any} textColor={btn.textColor} onClick={btn.onClick}>
            {btn.title}
          </ControlButton>
        ))}
      </CounterControlsWrapper>
      <AcceptOfferModal vehicleTitle={`${vehicle.year} ${vehicle.make} ${vehicle.model}`} vehicleId={vehicle.id} />
      <SendOfferModal maxAmount={vehicle.reserve_price} form={`sendVehicleOffer-${vehicle.id}`} vehicle={vehicle} />
    </>
  );
};
