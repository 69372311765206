import { Form } from 'react-final-form';
import { useViewedVehicle } from '../../hooks/useViewedVehicle';
import { IncreaseAmount } from '../../../../../components/IncreaseAmount';
import { useCurrentUser } from '../../../../../hooks/selectors';
import { usePlaceBidMutation } from '../../../../../services/api/my-bids';
import { useDispatch } from 'react-redux';
import { snackShow } from '../../../../../actions';
import { AUCTION_STATUSES, BID_INCREMENT, BUYER_VEHICLE_STATUS } from '../../../../../constants';
import { auctionOfferStatuses } from '../../../../../utils/statusCalculator';
import { useMemo } from 'react';

export const IncreaseMaxBid = () => {
  const vehicle = useViewedVehicle();
  const user = useCurrentUser();
  const [placeBid] = usePlaceBidMutation();
  const dispatch = useDispatch();

  const initialAmount = useMemo(() => {
    if (!vehicle || !user) return 0;
    if (user && user.id === vehicle.proxy_bid?.user_id) {
      return vehicle.proxy_bid.amount + BID_INCREMENT;
    }

    if (vehicle?.highest_bid?.amount) {
      return vehicle?.highest_bid?.amount + BID_INCREMENT;
    }

    return vehicle.starting_bid || 100;
  }, [vehicle?.proxy_bid, user, vehicle?.highest_bid?.amount]);

  if (!vehicle) return null;
  if (user.role !== 'buyer') return null;

  const status = auctionOfferStatuses(vehicle, null, user);

  const canPlaceBid =
    [BUYER_VEHICLE_STATUS.OUT_BID, BUYER_VEHICLE_STATUS.HIGH_BID, BUYER_VEHICLE_STATUS.ACTIVE].includes(status) &&
    vehicle.auction?.status === AUCTION_STATUSES.ACTIVE;

  if (!canPlaceBid) return null;

  const onSubmit = async (values: { amount: string }) => {
    const response = await placeBid({
      vehicle_id: vehicle.id,
      amount: Number(values.amount)
    });

    if ('data' in response) {
      dispatch(snackShow({ message: 'Bid successfully placed' }));
    } else {
      dispatch(snackShow({ type: 'error', message: (response.error as any)?.data?.message || 'Failed to place bid' }));
    }
  };

  return (
    <Form onSubmit={onSubmit} initialValues={{ amount: initialAmount }} name="increase-max-bid">
      {props => (
        <IncreaseAmount
          isProxyAmount={vehicle.proxy_bid?.user_id === user.id}
          type="bid"
          initialAmount={initialAmount}
        />
      )}
    </Form>
  );
};
