import { DragEndEvent, DragStartEvent } from '@dnd-kit/core';
import { SectionsOrder, useGetSectionsOrderQuery, useUpdateSectionsOrderMutation } from '../api/vehicle-sections';
import { arrayMove } from '@dnd-kit/sortable';
import { useDispatch } from 'react-redux';
import api from 'services/api/vehicle-sections';
import { clearMovedSections, setMovedSection, setMovedSubSection } from './sectionDragSlice';
import { SectionConfig } from '../api/vehicle-sections/types';
    
export const useSectionDragStart = () => {
  const dispatch = useDispatch();
  return (e: DragStartEvent) => {
    const dragId = e.active.id as string;
    const split = dragId.split('.');
    if (split.length === 1) {
      dispatch(setMovedSection(dragId));
    } else {
      dispatch(setMovedSubSection(dragId));
    }
  };
};

export const useSectionDragEnd = () => {
  const { data: sectionOrder = [] } = useGetSectionsOrderQuery();
  const dispatch = useDispatch<any>();
  const [updateSectionOrder] = useUpdateSectionsOrderMutation();

  return (e: DragEndEvent) => {
    const { active, over } = e;
    dispatch(clearMovedSections());

    if (over && active.id !== over.id) {
      const oldI = sectionOrder.findIndex(e => e[0] === (active.id as string));
      const newI = sectionOrder.findIndex(e => e[0] === (over.id as string));

      const newArray = arrayMove(sectionOrder, oldI, newI);

      dispatch(
        api.util.updateQueryData('getSectionsOrder', undefined, draft => {
          return newArray;
        })
      );

      updateSectionOrder(newArray);
    }
  };
};

export const useSubSectionDragEnd = (sectionId: keyof SectionConfig) => {
  const { data: sectionOrder = [] } = useGetSectionsOrderQuery();
  const dispatch = useDispatch<any>();
  const [updateSectionOrder] = useUpdateSectionsOrderMutation();

  return (e: DragEndEvent) => {
    const { active, over } = e;
    dispatch(clearMovedSections());

    if (over && active.id !== over.id) {
      const [, activeId] = String(active.id).split('.');
      const [, overId] = String(over.id).split('.');

      const sectionIndex = sectionOrder.findIndex(s => s[0] === sectionId);
      if (sectionIndex === -1) return;

      const copy: SectionsOrder = sectionOrder.map(([section, subsections]) => {
        return [section, [...subsections]];
      });
      const [, subsections] = copy[sectionIndex];
      const oldI = subsections.findIndex(e => e === activeId);
      const newI = subsections.findIndex(e => e === overId);

      copy[sectionIndex][1] = arrayMove(subsections, oldI, newI);

      dispatch(
        api.util.updateQueryData('getSectionsOrder', undefined, draft => {
          return copy;
        })
      );

      updateSectionOrder(copy);
    }
  };
};

export const useSubSectionDragStart = () => {
  const dispatch = useDispatch();
  return (e: DragStartEvent) => {
    const dragId = e.active.id as string;
    dispatch(setMovedSubSection(dragId));
  };
};
