import { Button, Grid } from '@material-ui/core';
import { usePublicOfferAmount } from 'hooks';
import styled from 'styled-components';
import { FormattedAmount } from 'components';
import { AcceptOfferModal } from 'components/Offers';
import { useDispatch } from 'react-redux';
import { modalsToggle } from 'actions';
import { Divider } from '@material-ui/core';
import { OfferAuction } from 'types/vehicle';

const Label = styled.span`
  color: rgba(33, 33, 33, 1);
  font-size: 16px;
  line-height: 18.75px;

  @media (max-width: 1024px) {
    font-size: 12px;
    line-height: 14px;
  }
`;

const AcceptButton = styled(Button)`
  border-radius: 20px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  width: 140px;
  height: 44px;
  cursor: pointer;
  background: #225a91;
  color: #fff;
  width: 100%;

  &:hover {
    background: #225a91;
  }

  @media (max-width: 960px) {
    width: 100%;
  }
`;

const StyledFormattedAmount = styled(FormattedAmount)`
  font-size: 16px;
  font-weight: 700;
  line-height: 18.75px;
  color: rgba(33, 33, 33, 1);
`;

const StyledGrid = styled(Grid)`
  gap: 16px;

  @media (max-width: 1024px) {
    gap: 8px;
  }
`;

type Props = {
  offerAuction: OfferAuction;
  vehicleTitle: string;
  vehicleId: number;
};

export const AcceptPublicOfferSection = ({ offerAuction, vehicleId, vehicleTitle }: Props) => {
  const { adminAmount } = usePublicOfferAmount(offerAuction);
  const dispatch = useDispatch();

  if (adminAmount === 0) return null;

  const handleAccept = () => dispatch(modalsToggle(`acceptPrivateOffer-${vehicleId}`));

  return (
    <StyledGrid container direction="column" alignItems="center" justify="center">
      <Label>
        {`Counter amount - `}
        <StyledFormattedAmount amount={adminAmount} />
      </Label>
      <AcceptButton onClick={handleAccept}>ACCEPT</AcceptButton>
      <AcceptOfferModal vehicleTitle={vehicleTitle} vehicleId={vehicleId} />
    </StyledGrid>
  );
};
