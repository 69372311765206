import React from 'react';
import styled from 'styled-components';
import FormattedAmount from '../FormattedAmount';

const CurrentBidWrapper = styled.div<{ isBold: boolean }>`
  background-color: rgba(57, 148, 222, 0.1);
  color: rgba(57, 148, 222, 1);
  border-radius: 10px;
  padding: 2px 10px;
  display: inline-flex;
  font-weight: ${props => (props.isBold ? '500' : 'inherit')};
`;

type Props = {
  amount: number;
  isBold?: boolean;
};

export const CurrentBidComponent = ({ amount, isBold = false }: Props) => (
  <CurrentBidWrapper isBold={isBold}>
    <FormattedAmount amount={amount} />
  </CurrentBidWrapper>
);
