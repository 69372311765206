import { Grid } from '@material-ui/core';
import { VEHICLE_STATUSES } from '../../../../../../../constants';
import { useViewedVehicle } from '../../../../hooks/useViewedVehicle';
import { PullVehicleBlock } from './PullBlock';
import { RelistVehicleBlock } from './RelistBlock';
import { DeactivateBlock } from './DeactivateBlock';
import { SectionDivider } from '../../../../../../Shared/VehicleDetails/components';

export const PullVehicleControls = () => {
  const currentVehicle = useViewedVehicle();

  console.log('renders pu;; vehicle controls');

  if (!currentVehicle) return null;

  if (currentVehicle.status !== VEHICLE_STATUSES.PULLED) {
    return (
      <Grid item xs={12}>
        <PullVehicleBlock />
        <SectionDivider />
      </Grid>
    );
  }

  return (
    <Grid item xs={12}>
      <RelistVehicleBlock />
      <SectionDivider />
      <DeactivateBlock />
      <SectionDivider />
    </Grid>
  );
};
