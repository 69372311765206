import { MouseSensor, TouchSensor, TouchSensorOptions } from '@dnd-kit/core';
import { TouchEvent } from 'react';

const shouldStartDrag = (target?: Node) => {
  if (!target) return false;
  const activators = document.querySelectorAll('[id^="activator"]');
  return !![...activators].find(node => node.contains(target));
};

export class DragIconMouseSensor extends MouseSensor {
  static activators: Array<{ eventName: 'onMouseDown'; handler: any }> = [
    {
      eventName: 'onMouseDown',
      handler: ({ nativeEvent: event }: TouchEvent, {}: TouchSensorOptions) => {
        return shouldStartDrag(event.target as Node);
      }
    }
  ];
}

export class DragIconTouchSensor extends TouchSensor {
  static activators: Array<{ eventName: 'onTouchStart'; handler: any }> = [
    {
      eventName: 'onTouchStart',
      handler: ({ nativeEvent: event }: TouchEvent, {}: TouchSensorOptions) => {
        return shouldStartDrag(event.target as Node);
      }
    }
  ];
}
