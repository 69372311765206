import { Button, Dialog, Icon } from '@material-ui/core';
import { closeConfirmImageUploadModal, useConfirmImageUploadOpen } from '../../../services/api/listerUploadVehicle';
import styled from 'styled-components';
import { useGetSectionConfigById, useGetSubSectionConfig } from '../../CreateVehicle/hooks/useGetSubSectionConfig';
import { SectionConfig } from '../../../services/api/vehicle-sections/types';
import { useDispatch } from 'react-redux';
import { NotesField } from './Fields/NotesField';
import { GradesField } from './Fields/GradesField';
import { useHistory } from 'react-router-dom';
import { useUpdateSearchParams } from '../../../hooks';

const StyledDialog = styled(Dialog)`
  .confirm-section-paper {
    margin: 0 12px;
    border-radius: 20px;
  }
`;

const Wrapper = styled.div`
  padding: 26px 24px 24px;
  gap: 16px;
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled.div`
  font-size: 22px;
  font-weight: 500;
  line-height: 25.78px;
  color: #000;
`;

const CloseIcon = styled(Icon)`
  width: 24px;
  height: 24px;
`;

const Action = styled(Button)<{ disabled?: boolean }>`
  border-radius: 6px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) !important;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  padding-top: 13px;
  padding-bottom: 12px;
  text-transform: none;
`;

const CancelAction = styled(Action)<{ disabled?: boolean }>`
  background: rgba(217, 217, 217, 1);
  color: rgba(117, 117, 117, 1);
`;

const SubmitAction = styled(Action)<{ disabled?: boolean }>`
  background-color: ${props => (props.disabled ? 'rgba(34, 90, 145, 0.5)' : 'rgba(34, 90, 145, 1)')} !important;
  color: #fff !important;
`;

const ActionsWrapper = styled.div`
  display: flex;
  gap: 19px;
`;

const HelperText = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 16.41px;
`;

export const ConfirmSectionModal = () => {
  const openedSectionId = useConfirmImageUploadOpen();

  const dispatch = useDispatch();

  const close = () => {
    dispatch(closeConfirmImageUploadModal());
  };

  return (
    <StyledDialog onBackdropClick={close} classes={{ paper: 'confirm-section-paper' }} open={!!openedSectionId}>
      {openedSectionId && <Content sectionName={openedSectionId} />}
    </StyledDialog>
  );
};

const Content = ({ sectionName }: { sectionName: string }) => {
  const [sectionId, subSectionId] = sectionName.split('.');
  const subSectionConfig = useGetSubSectionConfig(sectionId as keyof SectionConfig, subSectionId);

  const dispatch = useDispatch();

  const close = () => {
    dispatch(closeConfirmImageUploadModal());
  };

  const history = useHistory();
  const updateSearchParams = useUpdateSearchParams();

  if (!subSectionConfig) return null;

  const handleProceed = () => {
    const urlParams = new URLSearchParams({
      view: 'section_upload_success',
      sectionName
    });
    close();
    updateSearchParams({
      view: 'section_upload_success',
      sectionName
    });
    // history.push(`/lister/upload-vehicle/18975?${urlParams.toString()}`);
  };

  return (
    <Wrapper>
      <Header>
        <Title>{subSectionConfig.label}</Title>
        <CloseIcon onClick={close}>close</CloseIcon>
      </Header>
      <HelperText>{`Don't forget to grade ${subSectionConfig.label} overall and leave comment`}</HelperText>
      <NotesField sectionName={sectionName} />
      <GradesField sectionName={sectionName} />
      <ActionsWrapper>
        <CancelAction onClick={handleProceed} fullWidth variant="contained" type="button">
          Skip
        </CancelAction>
        <SubmitAction onClick={handleProceed} fullWidth variant="contained" type="button">
          Submit
        </SubmitAction>
      </ActionsWrapper>
    </Wrapper>
  );
};
