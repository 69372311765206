import { useMemo, useState } from 'react';
import { useCurrentUser } from '../../../../../hooks/selectors';
import styled from 'styled-components';
import { DoubleCaretDown, DoubleCaretUp } from 'components/Icons/DoubleCaret';
import { MobileVehicleCard } from '../../../../LiveAuction/components/mobile/MobileVehicleCard';
import { Vehicle } from '../../../../../types/vehicle';
import { useGetSidePanelBidsQuery } from '../../../../../services/api/my-bids';

const MyBidsWrapper = styled.div<{ isActive?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: ${props => (props.isActive ? 'auto' : 'auto')};
  width: 100%;
  padding: ${props => (props.isActive ? '16px 16px 8px' : '8px')};
  background: #f1f1f1;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  margin-top: -12px;
  width: calc(100% + 32px);
  margin-left: -16px;
`;

const ToggleMyBids = styled.div<{ isActive?: boolean }>`
  font-size: 14px;
  font-weight: 400;
  padding-top: ${props => (props.isActive ? '8px' : '0')};
  color: #3994de;
  cursor: pointer;
`;

const VehiclesWrapper = styled.div`
  display: flex;
  overflow-x: scroll;
  width: 100%;
  gap: 16px;
`;

const ToggleBidsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const MyBids = () => {
  const [myBidsVisible, setMyBidsVisible] = useState(false);
  const user = useCurrentUser();
  const { data: myBidsData } = useGetSidePanelBidsQuery(undefined, { skip: user.role !== 'buyer' });

  const myBidsVehicles = useMemo(() => myBidsData?.rows?.ids?.map(id => myBidsData?.rows?.entities?.[id]) ?? [], [
    myBidsData
  ]) as Vehicle[];

  if (user.role !== 'buyer' || myBidsVehicles.length === 0) return null;

  const toggleVisibility = () => setMyBidsVisible(!myBidsVisible);

  const toggleBidsText = myBidsVisible ? 'Hide My Bids' : 'Show My Bids';
  const toggleBidsIcon = myBidsVisible ? <DoubleCaretUp /> : <DoubleCaretDown />;

  return (
    <MyBidsWrapper isActive={myBidsVisible}>
      {myBidsVisible && <VehiclesSlides vehicles={myBidsVehicles} />}
      <ToggleMyBids isActive={myBidsVisible} onClick={toggleVisibility}>
        <ToggleBidsWrapper>
          <span>{toggleBidsText}</span>
          {toggleBidsIcon}
        </ToggleBidsWrapper>
      </ToggleMyBids>
    </MyBidsWrapper>
  );
};

const VehiclesSlides = ({ vehicles }: { vehicles: Vehicle[] }) => {
  return (
    <VehiclesWrapper>
      {vehicles.length > 0 &&
        vehicles.map((e, i) => {
          return <MobileVehicleCard vehicle={e} key={i} />;
        })}
    </VehiclesWrapper>
  );
};
