import styled from 'styled-components';
import { useViewedVehicle } from '../../hooks/useViewedVehicle';
import { DriveTrainIcon, EngineIcon, ExteriorIcon, TransmissionIcon, TrimIcon } from './icons';
import Tooltip from '../../../../../components/Tooltip';
import { useEffect, useRef, useState } from 'react';

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(5, minmax(0, 1fr));
  grid-template-rows: 1fr;
  gap: 10px;

  @media (max-width: 1024px) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-template-rows: 1fr 1fr;

    .info-child:nth-child(1) {
      grid-column: 1;
      grid-row: 1;
      justify-self: center;
      width: 100%;
    }

    .info-child:nth-child(2) {
      grid-column: 2;
      grid-row: 1;
      justify-self: center;
      width: 100%;
    }

    .info-child:nth-child(3) {
      grid-column: 3;
      justify-self: center;
      grid-row: 1;
      width: 100%;
    }

    .info-child:nth-child(4) {
      grid-column: 1 / 3;
      justify-self: center;
      grid-row: 2 / 2;
      width: calc(100% / 2);
    }

    .info-child:nth-child(5) {
      grid-column: 2 / 4;
      grid-row: 2 / 2;
      justify-self: center;
      width: calc(100% / 2);
    }
  }
`;

const InfoCard = styled.div`
  display: flex;
  max-width: 104px;
  background: rgba(249, 249, 249, 1);
  height: 113px;
  flex-direction: column;
  border-radius: 30px;
  gap: 4px;
  padding: 10px;

  @media (max-width: 1024px) {
    max-width: 250px;
    height: 123px;
  }
`;

// @media (min-width: 1800px) {
//   height: 158px;
//   max-width: unset;
// }

const Label = styled.div`
  margin-top: 12px;
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  color: #000;

  @media (max-width: 1024px) {
    margin-top: 18px;
  }
`;

// @media (min-width: 1800px) {
//   margin-top: auto;
// }

const Value = styled.div`
  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
  color: #000;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

type InfoCardType = {
  Icon: () => JSX.Element;
  label: string;
  value: string;
};

export const MainCharacteristics = () => {
  const vehicle = useViewedVehicle();
  if (!vehicle) return null;

  const data = [
    {
      Icon: TrimIcon,
      label: 'Trim',
      value: vehicle.trim
    },
    {
      Icon: EngineIcon,
      label: 'Engine',
      value: vehicle.engine
    },
    {
      Icon: ExteriorIcon,
      label: 'Exterior',
      value: vehicle.ext_color
    },
    {
      Icon: TransmissionIcon,
      label: 'Transmission',
      value: vehicle.transmission
    },
    {
      Icon: DriveTrainIcon,
      label: 'Drive Train',
      value: vehicle.drive_train
    }
  ];

  return (
    <Wrapper>
      {data.map(item => (
        <InfoCardComponent item={item} key={item.label} />
      ))}
    </Wrapper>
  );
};

const InfoCardComponent = ({ item }: { item: InfoCardType }) => {
  const ref = useRef<HTMLDivElement | null>(null);

  const [isTextOverflown, setIsTextOverflown] = useState(false);

  useEffect(() => {
    if (!ref.current) return;
    setIsTextOverflown(ref.current.offsetWidth < ref.current.scrollWidth);
  }, [ref.current]);

  return (
    <InfoCard className="info-child">
      <item.Icon />
      <Label>{item.label}</Label>
      <Tooltip isTextOverflown={isTextOverflown} titleStyle="uploadVehicle" title={item.value}>
        <Value ref={ref}>{item.value}</Value>
      </Tooltip>
    </InfoCard>
  );
};
