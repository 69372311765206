import styled from 'styled-components';
import { Vehicle } from '../../types/vehicle';
import Tooltip from '../Tooltip';
import { useDispatch, useSelector } from 'react-redux';
import { modalsToggle } from '../../actions';
import { EditIcon } from '../Icons';
import MyBidEditModal from '../../pages/Buyer/BidsList/components/MyBidEditModal';
import { useRef } from 'react';
import { useIsTextOverflown } from '../../hooks';

const Wrapper = styled.div<{ columnView: boolean }>`
  display: flex;
  gap: 8px;
  flex-direction: ${({ columnView }) => (columnView ? 'column' : 'row')};
`;

const NotesContainer = styled.div`
  border-radius: 4px;
  border: 0.5px solid #d9d9d9;
  background: #f5f5f5;
  width: 100%;
  padding: 8px 8px;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const EditIconWrapper = styled.div`
  cursor: pointer;
  display: flex;
`;

const AddNotesLabel = styled.div`
  text-decoration: underline;
  cursor: pointer;
  color: #3994de;
  margin-left: 4px;
  min-width: fit-content;

  &:hover {
    color: #000;
  }
`;

const NotesText = styled.span`
  padding-right: 6px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const AddNotesWrapper = styled.div`
  display: flex;
  gap: 2px;
  align-items: center;
  font-size: 12px;
  padding: 8px 0;
`;

type NotesSectionProps = {
  vehicle: Vehicle;
  columnView?: boolean;
  Icon?: () => JSX.Element;
};

export const NotesSection = ({ vehicle, Icon = EditIcon, columnView = false }: NotesSectionProps) => {
  const existingNotes = vehicle?.notes;
  const dispatch = useDispatch();
  const toggleModal = () => {
    dispatch(modalsToggle(`myBidsEditModal-${vehicle.id}`));
  };

  const ref = useRef<HTMLSpanElement>(null);
  const isTextOverflown = useIsTextOverflown(ref);

  const user = useSelector((state: any) => state.user?.user);
  if (!user || user.role === 'admin') return null;

  return (
    <Wrapper columnView={columnView}>
      {(!existingNotes || columnView) && (
        <AddNotesWrapper className="add-notes-container" onClick={toggleModal}>
          <EditIconWrapper>
            <Icon />
          </EditIconWrapper>
          <AddNotesLabel>Add Notes</AddNotesLabel>
        </AddNotesWrapper>
      )}
      {existingNotes && (
        <NotesContainer className="notes-container">
          <Tooltip isTextOverflown={isTextOverflown} disableFocusListener title={existingNotes} arrow light>
            <NotesText className="notes-text" ref={ref}>
              {existingNotes}
            </NotesText>
          </Tooltip>
          <EditIconWrapper onClick={toggleModal}>
            <Icon />
          </EditIconWrapper>
        </NotesContainer>
      )}
      <MyBidEditModal
        useRtk
        form={`myBidsEditModal-${vehicle.id}`}
        initialValues={{
          ...vehicle,
          notes: vehicle.notes
        }}
      />
    </Wrapper>
  );
};
