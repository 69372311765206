import styled from 'styled-components';

type Props = {
  notes: string;
  fullWidth?: boolean;
};

const Wrapper = styled.div<{ fullWidth: boolean }>`
  background: rgba(34, 34, 34, 0.04);
  color: #000;
  border-radius: 8px;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  padding: 5px 16px 9px 9px;
  max-width: ${props => (props.fullWidth ? '100%' : '600px')}
  overflow: hidden;
  text-overflow: ellipsis;

  @media (max-width: 1024px) {
    font-size: 10px;
    line-height: 14px;
  }
`;

export const SubSectionNotes = ({ notes, fullWidth = false }: Props) => {
  return <Wrapper fullWidth={fullWidth}>{notes}</Wrapper>;
};
