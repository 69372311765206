import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux';

type SectionDragState = {
    movedSectionId: string | null
    movedSubSectionId: string | null
}

const initialState: SectionDragState = {
    movedSectionId: null,
    movedSubSectionId: null
};

const sectionDragSlice = createSlice({
  name: 'sectionDrag',
  initialState,
  reducers: {
      setMovedSection: (state, action: PayloadAction<string | null>) => {
        state.movedSectionId = action.payload;
      },
      setMovedSubSection: (state, action: PayloadAction<string | null>) => {
        state.movedSubSectionId = action.payload;
      },
      clearMovedSections(state) {
          state.movedSectionId = null;
          state.movedSubSectionId = null;
      },
    }
});

export const useMovedSectionId = () => {
  const id = useSelector<{ sectionDrag: SectionDragState }, string | null>(s => {
    return s.sectionDrag.movedSectionId
  })
  return id
}

export const useMovedSubSectionId = () => {
  const id = useSelector<{ sectionDrag: SectionDragState }, string | null>(s => s.sectionDrag.movedSubSectionId)
  return id
}

export const { setMovedSection, setMovedSubSection, clearMovedSections } = sectionDragSlice.actions
export default sectionDragSlice;
