import styled from 'styled-components';
import { useCurrentUser } from '../../../../../hooks/selectors';
import { useBidsOfferAuction } from '../hooks';
import { OfferAuctionStatus, Vehicle } from '../../../../../types/vehicle';
import { getHighestBid } from '../../../../../utils/vehicle';

const RoundedLabel = styled.div<{ isHighest?: boolean }>`
  background: ${props => (props.isHighest ? 'rgba(71, 151, 71, 1)' : 'rgba(175, 41, 48, 1)')};
  color: #fff;
  position: absolute;
  top: 12px;
  right: 12px;
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  border-radius: 20px;
  padding: 3px 10px;

  @media (max-width: 1024px) {
    top: 8px;
    right: 8px;
  }
`;

export const useGetBidInfoLabel = (currentVehicle?: Vehicle) => {
  if (!currentVehicle) {
    return { label: '', isHighest: false };
  }

  const user = useCurrentUser();
  const offerAuction = useBidsOfferAuction(currentVehicle.id);

  const highestBid = getHighestBid(currentVehicle);
  const topBidder = highestBid.user_id === user?.id;
  const label = (() => {
    if (offerAuction?.status === OfferAuctionStatus.PUBLIC_OFFERS) {
      const isTopOffer = offerAuction?.offerProxyBid?.user_id === user?.id;
      if (currentVehicle.status === 'awarded') {
        return currentVehicle.buyer_id === user.id ? 'Top Offer' : 'Offer Outbid';
      }
      return isTopOffer ? 'Top Offer' : 'Offer Outbid';
    }

    return topBidder ? 'Top Bidder' : 'Outbid';
  })();

  return { label, isHighest: label.startsWith('Top') };
};

export const RoundedBidInfoLabel = ({ item: currentVehicle }: { item: Vehicle }) => {
  const { label, isHighest } = useGetBidInfoLabel(currentVehicle);

  return (
    <RoundedLabel className="rounded-label" isHighest={isHighest}>
      {label}
    </RoundedLabel>
  );
};
