import { Fragment } from 'react';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { modalsToggle } from 'actions';
import ConfirmModal from 'components/Modals/ConfirmModal';
import { useViewedVehicle } from '../../../../hooks/useViewedVehicle';
import { usePullVehicleMutation } from 'services/api/vehicle-actions';

const modalPullTitle = 'pullVehicleModal';

const StyledPullSpan = styled.span`
  color: #d50808;
  text-decoration: underline;
  cursor: pointer;
  font-weight: 500;
`;

export const PullVehicleBlock = () => {
  const currentVehicle = useViewedVehicle();
  const [pullVehicleFn] = usePullVehicleMutation();
  const dispatch = useDispatch();

  if (!currentVehicle) return null;

  const handlePull = () => {
    pullVehicleFn(currentVehicle.id);
  };

  const openConfirmModal = () => {
    dispatch(modalsToggle(modalPullTitle));
  };

  const currentTime = moment.utc();
  const isVehicleTimerEnded = currentVehicle.date_end && moment.utc(currentVehicle.date_end).isBefore(currentTime);

  return (
    <Fragment>
      <StyledPullSpan onClick={openConfirmModal}>Pull vehicle from the auction</StyledPullSpan>
      <ConfirmModal
        submitText={'Confirm'}
        modalId={modalPullTitle}
        title={`Are you sure you want to pull this vehicle from the auction?${
          !isVehicleTimerEnded ? ' Once it is pulled, no more bids can be made.' : ''
        }`}
        handleSubmit={handlePull}
      />
    </Fragment>
  );
};
