import React from 'react';
import { LeftNavIcon, RightNavIcon } from '../../../icons/NavIcons';
import styled from 'styled-components';

type NavProps = {
  disabled: boolean;
  onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  isFullScreen?: boolean;
};

const StyledNavButton = styled.button<{ navType: 'left' | 'right'; isFullScreen?: boolean }>`
  width: ${props => (props.isFullScreen ? '58px' : '40px')};
  height: ${props => (props.isFullScreen ? '58px' : '40px')};
  border-radius: 8px;
  cursor: pointer;
  background: rgba(102, 102, 102, 0.6);
  position: absolute;
  z-index: 100;
  top: 50%;
  border: none;
  transform: translateY(-50%);

  left: ${props => (props.navType === 'left' ? '16px' : 'auto')};
  right: ${props => (props.navType === 'right' ? '16px' : 'auto')};

  @media (max-width: 1024px) {
    width: 40px;
    height: 40px;
  }
`;

export const RenderLeftNav = React.memo(({ disabled, onClick, isFullScreen = false }: NavProps) => {
  if (disabled) return null;

  return (
    <StyledNavButton
      navType="left"
      type="button"
      disabled={disabled}
      isFullScreen={isFullScreen}
      onClick={onClick}
      aria-label="Previous Slide"
    >
      <LeftNavIcon />
    </StyledNavButton>
  );
});

export const RenderRightNav = React.memo(({ disabled, onClick, isFullScreen = false }: NavProps) => {
  return (
    <StyledNavButton
      isFullScreen={isFullScreen}
      navType="right"
      type="button"
      disabled={disabled}
      onClick={onClick}
      aria-label="Next Slide"
    >
      <RightNavIcon />
    </StyledNavButton>
  );
});
