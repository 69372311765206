import { generateRandomKey } from 'utils';

export function dataURItoFile(dataURI: string) {
  // Split the dataURI string to get the base64 data
  let arr = dataURI.split(',');

  // Extract the Base64 content
  let mime = arr?.[0].match(/:(.*?);/)?.[1];
  let bstr = atob(arr[1]);

  // Convert base64 to binary format
  let n = bstr.length;
  let u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], generateRandomKey(), { type: mime });
}
