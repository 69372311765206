import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { useGetSectionConfigById } from '../../CreateVehicle/hooks/useGetSubSectionConfig';
import { useGetSectionsOrderQuery } from '../../../services/api/vehicle-sections';
import { SectionConfig } from '../../../services/api/vehicle-sections/types';
import { CarIcon } from '../icons/CarIcon';
import { Icon } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { openCamera } from '../../../services/api/listerUploadVehicle';
import { useSearchParams, useUpdateSearchParams } from '../../../hooks';

const PageWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: -72px;
  padding-bottom: 16px;
  flex: 1 1 100%;
  flex-direction: column;
  justify-content: space-between;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  flex-direction: column;
  gap: 14px;
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: 700;
  line-height: 23.44px;
  text-align: center;
`;

const HelperText = styled.div`
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  line-height: 16.41px;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  padding: 0 28px;
`;

const Action = styled.div`
  font-size: 16px;
  font-weight: 500;
  line-height: 18.75px;
  display: flex;
  gap: 8px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  border-radius: 8px;
`;

const GoBackAction = styled(Action)`
  color: rgba(117, 117, 117, 1);
  background: rgba(217, 217, 217, 1);
`;

const ContinueAction = styled(Action)`
  color: #fff;
  background: rgba(34, 90, 145, 1);
`;

const BackIcon = styled(Icon)`
  width: 24px;
  height: 24px;
  color: rgba(117, 117, 117, 1);
`;

export const SectionUploadSuccessView = () => {
  const searchParams = useSearchParams();
  const sectionName = searchParams.get('sectionName') || '';
  const { data: sectionsOrder = [] } = useGetSectionsOrderQuery();
  const [sectionId, subSectionId] = sectionName.split('.');
  const config = useGetSectionConfigById(sectionId as keyof SectionConfig);

  const history = useHistory();
  const dispatch = useDispatch();

  const updateSearchParams = useUpdateSearchParams();

  if (!config) return null;

  const currSection = config.subsections[subSectionId];

  const orderBySectionId = sectionsOrder.find(e => e[0] === sectionId)?.[1] || [];
  const currSectionI = orderBySectionId.findIndex(e => e === subSectionId);
  const nextSection = currSectionI + 1;
  const hasNextSection = currSectionI !== -1 && nextSection < orderBySectionId.length;
  const nextSectionSlug = orderBySectionId[nextSection];
  const nextSectionLabel = config.subsections[nextSectionSlug]?.label;

  const handleGoBack = () => {
    updateSearchParams({
      view: 'section_expanded_view',
      sectionId
    });
  };

  const handleContinue = () => {
    updateSearchParams({
      view: 'sections_list'
    });
    dispatch(openCamera(`${sectionId}.${nextSectionSlug}`));
  };

  return (
    <PageWrapper>
      <div />
      <Wrapper>
        <Title>{currSection.label}</Title>
        <CarIcon />
        <HelperText>Section is complete! Continue with next section.</HelperText>
      </Wrapper>
      <ButtonsWrapper>
        <GoBackAction onClick={handleGoBack}>
          <BackIcon>arrow_back</BackIcon>
          <span>{`Back to ${config.label}`}</span>
        </GoBackAction>
        {hasNextSection && (
          <ContinueAction onClick={handleContinue}>
            <span>{`Continue to ${nextSectionLabel}`}</span>
          </ContinueAction>
        )}
      </ButtonsWrapper>
    </PageWrapper>
  );
};
