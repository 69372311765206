import { Prompt } from 'react-router-dom';
import { useIsSaveImagesLoading } from '../../../../services/uploadVehicle/saveImagesStateSlice';
import styled from 'styled-components';
import { CircularProgress } from '@material-ui/core';

const Wrapper = styled.div`
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  top: 80px;
  z-index: 99999;
`;

export const TopSpinner = () => {
  const savingImages = useIsSaveImagesLoading();
  if (!savingImages) return null;

  return (
    <Wrapper>
      <CircularProgress />
    </Wrapper>
  );
};
