import { Button, Dialog, Icon } from '@material-ui/core';
import styled from 'styled-components';
import { FormImages } from './FormImages';
import { LocalImages } from './LocalImages';

import { useDispatch } from 'react-redux';
import {
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors
} from '@dnd-kit/core';
import { DragItem } from './DragItem';
import { useForm } from 'react-final-form';
import { useSaveSectionImagesMutation } from '../../../../services/api/upload-vehicle';
import { snackShow } from '../../../../actions';
import {
  FieldLocalImage,
  LocalImage,
  closeAssignImageDialog,
  removeActiveDragImage,
  replaceFormImages,
  setActiveDragImage,
  uploadLocalImage,
  useActiveDragImage,
  useFormImages,
  useLocalImages,
  useOpenedAssignVehicleImageDialog
} from '../../../../services/uploadVehicle/assignVehicleImagesSlice';
import { useGetVehicleId } from '../../hooks/useGetVehicleId';
import { useGetSubSectionConfig } from '../../hooks/useGetSubSectionConfig';
import { SectionConfig } from '../../../../services/api/vehicle-sections/types';
import { arrayMove } from '@dnd-kit/sortable';

const StyledDialog = styled(Dialog)`
  .upload-image-dialog {
    width: 645px;
    height: 720px;
    margin: 32px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border: 1px solid rgba(217, 217, 217, 1);
    background: rgba(255, 255, 255, 1);
  }
`;

const Wrapper = styled.div`
  border-radius: 8px;
  padding: 16px;
  display: flex;
  height: 100%;
  overflow-y: scroll;
  justify-content: space-between;
  flex-direction: column;
`;

const TopSection = styled.div`
  display: flex;
  flex-direction: column;
`;

const BottomSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 18px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled.div`
  font-size: 16px;
  font-weight: 700;
  line-height: 18.75px;

  @media (min-width: 1024px) {
    font-size: 18px;
    line-height: 21px;
  }
`;

const SaveButton = styled(Button)`
  margin-top: auto;
`;

export const UploadImageDialog = () => {
  const dispatch = useDispatch();
  const sectionId = useOpenedAssignVehicleImageDialog();
  const close = () => dispatch(closeAssignImageDialog());

  return (
    <StyledDialog
      classes={{ paperScrollPaper: 'upload-image-dialog' }}
      fullWidth
      maxWidth={'lg'}
      onClose={close}
      open={!!sectionId}
    >
      {!!sectionId && <UploadImageDialogContent sectionId={sectionId} />}
    </StyledDialog>
  );
};

const UploadImageDialogContent = ({ sectionId }: { sectionId: string }) => {
  const dispatch = useDispatch();
  const close = () => dispatch(closeAssignImageDialog());

  const [sectionName, subSectionName] = sectionId.split('.');
  const config = useGetSubSectionConfig(sectionName as keyof SectionConfig, subSectionName);

  const vehicleId = useGetVehicleId();

  const [saveImages] = useSaveSectionImagesMutation();

  const touchSensor = useSensor(TouchSensor, {
    activationConstraint: {
      delay: 300,
      tolerance: 8
    }
  });
  const mouseSensor = useSensor(MouseSensor, {
    activationConstraint: {
      distance: 8
    }
  });

  const sensors = useSensors(mouseSensor, touchSensor);

  const activeDragImage = useActiveDragImage();

  const handleDragStart = (e: DragStartEvent) => {
    dispatch(setActiveDragImage(e.active.data.current as any));
  };

  const formImages = useFormImages();
  const localImages = useLocalImages();
  const form = useForm();

  const handleSaveChange = async () => {
    const [sectionName, subSectionName] = sectionId.split('.');
    const nonUploadedLocal = localImages
      .filter(i => !i.isUploaded)
      .map(
        i =>
          ({
            file: i.file,
            type: 'user_defined',
            url: '',
            url_thumb: ''
          } as FieldLocalImage)
      );

    close();

    const response = await saveImages({
      images: [...formImages, ...nonUploadedLocal],
      sectionName,
      subSectionName,
      vehicleId
    });

    if ('data' in response) {
      const serverImages = response.data.data;
      form.change(`${sectionId}.images`, serverImages);
      dispatch(snackShow({ message: 'Images uploaded' }));
    } else {
      dispatch(snackShow({ message: 'Uploading image failed', type: 'error' }));
    }
  };

  const handleDragEnd = (e: DragEndEvent) => {
    const { active, over } = e;

    const isReorder =
      over && active.id.toString().startsWith('FormImage') && over.id.toString().startsWith('FormImage');

    if (isReorder) {
      const [, activeI] = active.id.toString().split('-');
      const [, overI] = over.id.toString().split('-');
      dispatch(replaceFormImages(arrayMove(formImages, Number(activeI), Number(overI))));
      return;
    }

    const item = active.data.current as LocalImage;

    dispatch(removeActiveDragImage());

    if (!item || !over) return;

    const [sectionName, index] = (over.id as string).split('//');

    const parsedI = Number(index);

    dispatch(uploadLocalImage({ localId: item.id, fieldImageType: formImages[parsedI].type }));
  };

  return (
    <>
      {/* <LoaderModal forceOpen={isLoading} modalId="images-loading" loaderText="Saving images..." /> */}
      <DndContext sensors={sensors} onDragEnd={handleDragEnd} onDragStart={handleDragStart}>
        <Wrapper>
          <TopSection>
            <Header>
              <Title>Upload Photos</Title>
              <Icon onClick={close}>close</Icon>
            </Header>
            <LocalImages name={sectionId} />
          </TopSection>
          <BottomSection>
            <FormImages name={sectionId} />
            <SaveButton onClick={handleSaveChange} variant="contained" color="primary">
              Save
            </SaveButton>
          </BottomSection>
        </Wrapper>
        <DragOverlay style={{ transformOrigin: '0 0 ' }} adjustScale>
          {activeDragImage && <DragItem item={activeDragImage} />}
        </DragOverlay>
      </DndContext>
    </>
  );
};
