import styled from 'styled-components';
import { useViewedVehicle } from '../../../../hooks/useViewedVehicle';
import { useViewVehicleContext } from '../../../../context/ViewVehicleContext';
import { SectionConfig } from '../../../../../../../services/api/vehicle-sections/types';
import { openFullScreenWithId } from '../../../../../../../services/viewVehicleSlice/viewVehicleSlice';
import { useDispatch } from 'react-redux';
import { VehicleSection } from '../../../../../../../types/vehicle';
import { gradeColorMap } from '../../../../../../CreateVehicle/components/Grades';

const OuterContainer = styled.div`
  background: rgba(249, 249, 249, 1);
  border-radius: 8px;
  padding: 16px;
`;

const Wrapper = styled.div`
  display: flex;
  gap: 8px;

  @media (max-width: 1024px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
  }
`;

const SubSectionWrapper = styled.div<{ url: string; hasNotes?: boolean }>`
  flex: 1 1 100%;
  aspect-ratio: 1;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: ${props =>
    props.hasNotes ? 'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%),' : ''} url("${props =>
  props.url}"); 
`;

const ImageNotes = styled.div<{ isHovered?: boolean }>`
  position: absolute;
  bottom: 3px;
  padding: 0 10px;
  display: flex;
  max-width: 100%;
  min-width: 100%;
  align-items: center;
  justify-content: center;
  color: #fff;

  & > span {
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
  }
`;

const GradeLabel = styled.div<{ color: string }>`
  border-radius: 15px;
  font-size: 14px;
  line-height: 16.5px;
  width: fit-content;
  padding: 5px 8px;
  position: absolute;
  top: 16px;
  right: 16px;
  color: #fff;
  background: ${props => props.color};
`;

export const TiresAndWheelsSection = () => {
  const vehicle = useViewedVehicle();
  const { activeSection } = useViewVehicleContext();

  if (!vehicle) return null;

  const subSections = vehicle.groupedSections[activeSection as keyof SectionConfig];

  return (
    <OuterContainer>
      <Wrapper>
        {subSections?.map(subSection => {
          return <TiresAndWheelsSubSection item={subSection} key={subSection.id} />;
        })}
      </Wrapper>
    </OuterContainer>
  );
};

const TiresAndWheelsSubSection = ({ item }: { item: VehicleSection }) => {
  const dispatch = useDispatch();
  const image = item.images[0] || {};

  const openImageGallery = () => {
    dispatch(openFullScreenWithId(image.id));
  };

  const hasFilledLabel = !!image.label && !image.label.includes('_/');

  return (
    <SubSectionWrapper onClick={openImageGallery} url={image.url} hasNotes={hasFilledLabel}>
      {hasFilledLabel && (
        <ImageNotes>
          <span>{image.label}</span>
        </ImageNotes>
      )}
      {item.grade && <GradeLabel color={gradeColorMap[item.grade.color]}>{item.grade.label}</GradeLabel>}
    </SubSectionWrapper>
  );
};
