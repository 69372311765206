import styled from 'styled-components';
import { Vehicle } from 'types/vehicle';
import { formatCurrency } from '../../../../utils';
import { CurrentBidComponent } from '../../../../components/Typography';
import { IncreaseHighBid } from '../../../Buyer/BidsList/containers';
import { BID_INCREMENT } from '../../../../constants';
import { getMinBitAmount, getVehicleTimer } from '../../../../utils/vehicle';
import { Timer } from '../../../../components';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  width: 204px;
  border: 1px solid #cccccc;
  border-radius: 16px;
  background-color: #ffffff;

  // figure out a way to make this work
  // box-shadow: 0px -4px 10px 0px #00000040;
`;

const ImageContainer = styled.div`
  width: 100%;
  height: 120px;
  width: 170px;
  border: 1px solid #eeeeee;
  position: relative;
`;

const ReservePriceLabel = styled.div<{ isMet: boolean }>`
  background-color: ${props => (props.isMet ? 'rgb(71, 151, 71)' : '#CA8D57')};
  position: absolute;
  color: #fff;
  bottom: 0;
  padding: 2px 0;
  width: 100%;
  text-align: center;
  font-size: 10px;
  font-weight: 500;
  z-index: 10;
`;

const TimeLeft = styled.div`
  font-weight: 500;
  color: #757575;
  border: 1px solid #cccccc;
  background: #ffffff;
  padding: 0px 12px;
  font-size: 10px;
  position: absolute;
  top: 0;
  left: 0;
`;

const VehicleTitle = styled.div`
  color: #000000;
  font-size: 12px;
  font-weight: 500;
  padding: 8px 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  min-height: 19px;
`;

const BookRow = styled.div`
  font-size: 10px;
  color: #757575;
`;

const NumericValue = styled.span`
  font-weight: 500;
  color: #757575;
`;

const BidRow = styled.div`
  padding-top: 2px;
  font-size: 10px;
  color: #757575;
  display: flex;
  align-items: center;
  width: 100%;
  gap: 4px;

  & div:first-child {
    min-width: fit-content;
  }

  & > div {
    flex: 1 1 100%;
  }
`;

const LabelValueRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2px;
`;

const CurrentBidLabel = styled.span`
  color: #000;
  font-weight: 500;
`;

const PlaceBidWrapper = styled.div`
  & .mobile-card_input-wrapper {
    background: #eeeeee;
    padding: 6px 8px;
    height: 32px;
    border-radius: 4px;
  }

  & .mobile-card_input {
    background: #eeeeee;

    input {
      font-size: 12px;
      padding: 0;
    }
  }

  & .mobile-card_control-button {
    width: 20px;
    height: 20px;
    font-size: 12px;
  }

  & .mobile-card_submit-button-wrapper {
    height: 32px;
  }

  & .mobile-card_submit-button {
    background: #d9d9d9;
    color: #757575;
    padding: 0;
    height: 32px;

    min-height: unset;

    font-weight: 400;

    span {
      font-size: 14px;
      line-height: unset;
    }
  }

  & .mobile-card_submit-button-active {
    background: #005a96;
    color: #fff;
  }
`;

export const MobileVehicleCard = ({ vehicle }: { vehicle: Vehicle }) => {
  const image = vehicle.images?.[0];
  const vehicleTimer = getVehicleTimer(vehicle);

  const user = useSelector((state: any) => state.user?.user);
  const minBidAmount = getMinBitAmount(vehicle, user);

  const history = useHistory();

  return (
    <Wrapper>
      <ImageContainer onClick={() => history.push(`/vehicles/${vehicle.id}/view`)}>
        <img width="100%" height="100%" alt="vehicle" src={image?.url} />
        {vehicle.reserve_price_set && (
          <ReservePriceLabel isMet={vehicle.reserve_price_met}>
            {vehicle.reserve_price_met ? 'Reserve price met' : 'Reserve price not met'}
          </ReservePriceLabel>
        )}
        <TimeLeft>
          <Timer toDate={vehicleTimer} />
        </TimeLeft>
      </ImageContainer>
      <VehicleTitle>{`${vehicle.year} ${vehicle.make} ${vehicle.model}`}</VehicleTitle>
      <BookRow>
        Book: <NumericValue>{formatCurrency(vehicle.bluebook_mileage)}</NumericValue>/ miles{' '}
        <NumericValue>{formatCurrency(vehicle.bluebook_value)}</NumericValue>
      </BookRow>
      <BidRow>
        <LabelValueRow>
          <CurrentBidLabel>Current Bid:</CurrentBidLabel>
          <CurrentBidComponent isBold amount={vehicle.highest_bid?.amount ?? 100} />
        </LabelValueRow>
        <LabelValueRow>
          <span>Your Bid:</span>
          <NumericValue>{formatCurrency(vehicle.my_bid?.amount ?? 100)}</NumericValue>
        </LabelValueRow>
      </BidRow>
      <PlaceBidWrapper>
        <IncreaseHighBid
          classNamePrefix="mobile-card"
          form={`biddingForm_${vehicle.id}`}
          vehicleId={vehicle.id}
          minAmount={minBidAmount}
          bidIncrement={BID_INCREMENT}
          variant={'default'}
          vehicle={vehicle}
          isSidePanelBid
        />
      </PlaceBidWrapper>
    </Wrapper>
  );
};
