import styled from 'styled-components';
import { useViewedVehicle } from '../../hooks/useViewedVehicle';
import { InfoBlock } from '../InfoBlock';
import { EmptyAvatarPlaceholder } from 'components/Icons';

const Wrapper = styled.div`
  display: flex;
  padding: 32px 120px 24px 32px;
  flex-direction: column;
  gap: 14px;

  @media (max-width: 1024px) {
    padding: 16px;
    gap: 12px;
  }
`;

const MainContent = styled.div`
  display: flex;
  gap: 16px;

  @media (max-width: 1024px) {
    gap: 32px;
  }
`;

const TextInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  @media (max-width: 1024px) {
    gap: 4px;
  }
`;

const Title = styled.div`
  display: flex;
  gap: 14px;
  font-size: 16px;
  font-weight: 600;
  line-height: 18.75px;

  @media (max-width: 1024px) {
    font-size: 14px;
    line-height: 16.41px;
  }
`;

const Avatar = styled.div`
  width: 80px;
  height: 80px;

  svg,
  img {
    width: 80px;
    height: 80px;
    border-radius: 50px;
  }

  @media (max-width: 1024px) {
    width: 54px;
    height: 54px;

    svg,
    img {
      width: 54px;
      height: 54px;
    }
  }
`;

const Name = styled.div`
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  color: #000;

  @media (max-width: 1024px) {
    font-size: 12px;
    line-height: 14.06px;
  }
`;

const Bio = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: rgba(97, 97, 97, 1);

  @media (max-width: 1024px) {
    font-size: 12px;
    line-height: 16px;
  }
`;

export const ListerInformation = () => {
  const vehicle = useViewedVehicle();
  if (!vehicle || !vehicle.lister) return null;

  const lister = vehicle.lister.options;

  return (
    <InfoBlock>
      <Wrapper>
        <Title>Lister Information</Title>
        <MainContent>
          <Avatar>{lister.avatar ? <img src={lister.avatar} alt="avatar" /> : <EmptyAvatarPlaceholder />}</Avatar>
          <TextInfo>
            <Name>{lister.name_for_bio ?? 'N/A'}</Name>
            <Bio>{lister.bio ?? 'N/A'}</Bio>
          </TextInfo>
        </MainContent>
      </Wrapper>
    </InfoBlock>
  );
};
