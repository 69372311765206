import React, { Component } from 'react';
import { compose } from 'redux';
import { Grid, Typography, MenuItem } from '@material-ui/core';

import { Field, getFormValues, reduxForm, reset } from 'redux-form';
import { connect } from 'react-redux';
import { DateTimePicker, TimePicker } from 'material-ui-pickers';
import moment from 'moment';

import ModalWrapper from '../ModalWrapper';
import FormSelect from '../Form/FormSelect';
import FormError from '../Form/FormError';

import { dayOfWeek, MINUTES, SECONDS } from '../../constants';
import { modalsToggle, updateLocation, snackShow, locationsUnsetSuccess } from '../../actions';
import { FormFormattedField } from '../Form';
import { normalizeFormattedField } from '../../utils';

const extensionFieldNames = ['extension_time_1', 'extension_time_2', 'extension_time_3', 'extension_time_4'];

const validate = values => {
  const errors = {};
  [
    'end_day',
    'timeHours',
    'timeMinutes',
    'timeSeconds',
    'endTimeMinutes',
    'endTimeSeconds',
    'buyer_response_time',
    'seller_response_time',
    'offers_end_time',
    'offers_end_day',
    ...extensionFieldNames
  ].forEach(item => {
    if (!Object.prototype.hasOwnProperty.call(values, item)) {
      errors[item] = 'Required';
    }
  });
  return errors;
};

const formName = 'LocationEditModal';

const TimePickerField = ({ input: { onChange, value }, ...custom }) => (
  <TimePicker label="" value={value || moment().format()} onChange={onChange} {...custom} />
);

const DateTimePickerField = ({ input: { onChange, value }, ...custom }) => (
  <DateTimePicker label="" value={value || moment()} format={'MM/DD/YY hh:mm A'} onChange={onChange} {...custom} />
);

class LocationEditModal extends Component {
  submit = values => {
    const { editLocation } = this.props;
    console.log('Submitting', values);
    editLocation(values);
  };

  renderFrontlineForm = () => {
    return (
      <>
        <Grid item xs={16} lg={4}>
          <Typography align="right" variant="body1">
            Frontline End Date Time
          </Typography>
        </Grid>
        <Grid item xs={16} lg={8}>
          <Field name="frontline_end_date_time" component={DateTimePickerField} margin="dense" type="text" fullWidth />
        </Grid>
      </>
    );
  };

  renderAutoaxessForm = () => {
    return (
      <>
        <Grid item xs={12} lg={4}>
          <Typography align="right" variant="body1">
            End Day
          </Typography>
        </Grid>
        <Grid item xs={12} lg={8}>
          <Field
            name="end_day"
            label="Day of week"
            component={FormSelect}
            margin="dense"
            placeholder="Prefered day of auction"
            fullWidth
          >
            {dayOfWeek.map((item, i) => (
              <MenuItem key={i} value={i}>
                {item}
              </MenuItem>
            ))}
          </Field>
        </Grid>
        <Grid item xs={12} lg={4}>
          <Typography align="right" variant="body1">
            End Time
          </Typography>
        </Grid>
        <Grid item xs={12} lg={8}>
          <Field name="end_time" component={TimePickerField} margin="dense" type="text" fullWidth />
        </Grid>
        <Grid item xs={12} lg={4}>
          <Typography align="right" variant="body1">
            Offers End Date
          </Typography>
        </Grid>
        <Grid item xs={12} lg={4}>
          <Field name="offers_end_day" component={FormSelect} margin="dense" placeholder="Offers End Day" fullWidth>
            {dayOfWeek.map((item, i) => (
              <MenuItem key={i} value={i}>
                {item}
              </MenuItem>
            ))}
          </Field>
        </Grid>
        <Grid item xs={12} lg={4}>
          <Field name="offers_end_time" component={TimePickerField} margin="dense" fullWidth />
        </Grid>
        <Grid item xs={12} lg={4}>
          <Typography align="right" variant="body1">
            Auction End Time Spacing
          </Typography>
        </Grid>
        <Grid item xs={12} lg={2}>
          <Field name="endTimeMinutes" label="Min" component={FormSelect} margin="dense" fullWidth>
            {MINUTES.map((item, i) => (
              <MenuItem key={i} value={item}>
                {item}
              </MenuItem>
            ))}
          </Field>
        </Grid>
        <Grid item xs={12} lg={2}>
          <Field name="endTimeSeconds" label="Sec" component={FormSelect} margin="dense" fullWidth>
            {SECONDS.map((item, i) => (
              <MenuItem key={i} value={item}>
                {item}
              </MenuItem>
            ))}
          </Field>
        </Grid>
        <Grid item xs={12} lg={4} />
        <Grid item xs={12} lg={4}>
          <Typography align="right" variant="body1">
            Final Auction Shuffle
          </Typography>
        </Grid>
        <Grid item xs={12} lg={4}>
          <Field name="shuffleDay" component={FormSelect} margin="dense" placeholder="Day" fullWidth>
            {dayOfWeek.map((item, i) => (
              <MenuItem key={i} value={i}>
                {item}
              </MenuItem>
            ))}
          </Field>
        </Grid>
        <Grid item xs={12} lg={4}>
          <Field name="shuffleTime" component={TimePickerField} margin="dense" fullWidth />
        </Grid>
        <Grid item xs={12} lg={4}>
          <Typography align="right" variant="body1">
            Buyer response time
          </Typography>
        </Grid>
        <Grid item xs={12} lg={8}>
          <Field
            name="buyer_response_time"
            component={FormFormattedField}
            normalize={normalizeFormattedField}
            decimalScale={0}
            allowNegative={false}
            margin="dense"
            type="text"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <Typography align="right" variant="body1">
            Seller response time
          </Typography>
        </Grid>
        <Grid item xs={12} lg={8}>
          <Field
            name="seller_response_time"
            component={FormFormattedField}
            normalize={normalizeFormattedField}
            decimalScale={0}
            allowNegative={false}
            margin="dense"
            type="text"
            fullWidth
          />
        </Grid>

        <Grid item xs={12} lg={4}>
          <Typography align="right" variant="body1">
            Extension time
          </Typography>
        </Grid>
        {extensionFieldNames.map(name => (
          <Grid item xs={12} lg={2}>
            <Field
              name={name}
              component={FormFormattedField}
              normalize={normalizeFormattedField}
              decimalScale={0}
              allowNegative={false}
              margin="dense"
              type="text"
              fullWidth
            />
          </Grid>
        ))}
      </>
    );
  };

  render() {
    const { handleSubmit, submitting, formSubmitting, formError, selectedAuctionType } = this.props;

    return (
      <ModalWrapper
        title="Edit Timing"
        handleSubmit={handleSubmit(this.submit)}
        submitting={submitting || formSubmitting}
        modalId="editLocationModal"
      >
        <form onSubmit={this.handleSubmit}>
          <Grid container spacing={16} alignItems="center">
            {selectedAuctionType === 'Autoaxess' && this.renderAutoaxessForm()}
            {selectedAuctionType === 'Frontline' && this.renderFrontlineForm()}
          </Grid>
          {formError && <FormError msg={formError} />}
        </form>
      </ModalWrapper>
    );
  }
}

const mapStateToProps = state => {
  return {
    formSubmitting: state.locations.submitting,
    formError: state.locations.error,
    created: state.locations.success,
    locationData: state.locations.editLocationData || {},
    values: getFormValues(formName)(state)
  };
};

const mapDispatchToProps = dispatch => ({
  toggleModal: key => dispatch(modalsToggle(key)),
  editLocation: data => dispatch(updateLocation(data).request),
  showSnack: message => dispatch(snackShow(message)),
  resetForm: () => dispatch(reset(formName)),
  unsetSuccess: () => dispatch(locationsUnsetSuccess())
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: formName,
    validate
  })
)(LocationEditModal);
