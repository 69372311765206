import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { Grid, Divider } from '@material-ui/core';
import { VEHICLE_STATUSES } from 'constants';
import { useViewedVehicle } from '../../../../hooks/useViewedVehicle';
import { useGetActiveAuctionsQuery } from '../../../../../../../services/api/live-auction';
import { RelistVehicleBlock } from './RelistBlock';

const StyledDivider = styled(Divider)`
  && {
    margin: 24px 0;
  }
`;

const ReactivateBlock = () => {
  const currentVehicle = useViewedVehicle();

  if (!currentVehicle || currentVehicle.status !== VEHICLE_STATUSES.DEACTIVATED) return null;

  return (
    <Grid item xs={12}>
      {currentVehicle.status === VEHICLE_STATUSES.DEACTIVATED && <RelistVehicleBlock />}
      <StyledDivider />
    </Grid>
  );
};

export default ReactivateBlock;
