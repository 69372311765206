import styled from 'styled-components';
import { useImageUploadContext } from '../../../context/ImageUploadContext';

const Wrapper = styled.div`
  background: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 56px;
  right: 0;
  height: calc(100% - 56px);
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 13px;

  @media (orientation: portrait) {
    width: 100%;
    bottom: 0;
    left: 0;
    top: unset;
    height: 110px;
  }
`;

const TakeImageButton = styled.div`
  width: 58px;
  height: 58px;
  background: rgba(255, 255, 255, 1);
  border-radius: 100%;
  outline: 3px solid rgba(255, 255, 255, 1);
  outline-offset: 5px;
`;

export const TakePhoto = () => {
  const { takeImage } = useImageUploadContext();
  return (
    <Wrapper>
      <TakeImageButton onClick={takeImage} />
    </Wrapper>
  );
};
