import styled from 'styled-components';

const Title = styled.div<{ size: 'lg' | 'md'}>`
  font-size: ${props => (props.size === 'lg' ? '22px' : '16px')};
  font-weight: 700;
  line-height: ${props => (props.size === 'lg' ? '25.78px' : '18.75px')};
  color: rgba(33, 33, 33, 1);

  @media (max-width: 1024px) {
    font-size: 14px;
    line-height: 16.41px
  }
`;

type Props = {
  title: string;
  size?: 'lg' | 'md';
};

export const BlockTitle = ({ title, size = 'md' }: Props) => {
  return <Title size={size}>{title}</Title>;
};
