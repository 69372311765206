import styled from 'styled-components';
import { useGetSectionConfigById } from '../../CreateVehicle/hooks/useGetSubSectionConfig';
import { SectionConfig } from '../../../services/api/vehicle-sections/types';
import { useGetSectionsOrderQuery } from '../../../services/api/vehicle-sections';
import { ExpandedSubSection } from '../components/ExpandedSubSection';
import { useSearchParams } from '../../../hooks';
import { useEventListener, useWindowSize } from 'usehooks-ts';

const ContentWrapper = styled.div`
  background: rgba(233, 237, 247, 1);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  background: rgba(34, 90, 145, 1);
  padding: 11px 16px 10px 16px;
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  color: #fff;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
`;

const MainContent = styled.div`
  padding: 10px 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const addClassName = (elem: HTMLElement, className: string) => {
  if (!elem.className.includes(className)) {
    elem.className = `${elem.className} ${className}`;
  }
};

const removeClassName = (elem: HTMLElement, className: string) => {
  elem.className = elem.className.replace(className, '');
};

export const SectionExpandedView = () => {
  const searchParams = useSearchParams();
  const sectionId = searchParams.get('sectionId') as keyof SectionConfig;
  const config = useGetSectionConfigById(sectionId);
  const { data: sectionsOrder = [] } = useGetSectionsOrderQuery();

  const { height = 0 } = useWindowSize();

  useEventListener('scroll', e => {
    const nav = document.getElementById('mobile-nav');
    if (!nav) return;
    const totalScrollHeight = document.body.offsetHeight - window.innerHeight;
    const scrollDiff = Math.abs(totalScrollHeight - window.scrollY);
    if (window.scrollY <= height || scrollDiff < 100) {
      removeClassName(nav, 'hidden');
      addClassName(nav, 'visible');
    } else {
      removeClassName(nav, 'visible');
      addClassName(nav, 'hidden');
    }
  });

  if (!config) return null;

  const subSections = sectionsOrder.find(e => e[0] === sectionId)?.[1] || [];

  return (
    <ContentWrapper>
      <Title>{config.label}</Title>
      <MainContent>
        {subSections.map(subSectionId => (
          <ExpandedSubSection key={subSectionId} sectionId={sectionId} subSectionId={subSectionId} />
        ))}
      </MainContent>
    </ContentWrapper>
  );
};
