import React from 'react';
import { InputAdornment, TextField, withStyles } from '@material-ui/core';
import styled from 'styled-components';

const StyledIcon = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0;
  margin-left: 6px;
`;

const classes = {
  root: {
    fontWeight: 500,
    color: '#000'
  },
  filledLgInput: {
    padding: '15px 16px 14px 12px !important',
    borderBottom: 'none',
    background: '#f5f5f5',
    fontSize: '18px',
    lineHeight: '21px',
    marginTop: '8px !important'
  },
  filledLgLabel: {
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '18.75px',
    color: 'rgba(117, 117, 117, 1) !important'
  },
  input: {
    padding: '12px 6px !important',
    borderBottom: 'none',
    background: '#f5f5f5'
  },
  underline: {
    '&:before': {
      borderBottom: 'none !important'
    },
    '&:after': {
      borderBottom: 'none !important'
    }
  },
  rootOutlined: {
    border: '1px solid #cccccc',
    borderRadius: '8px',
    marginTop: '22px !important'
  },
  rootOutlinedError: {
    border: '1px solid #AF2930',
    borderRadius: '8px',
    marginTop: '22px !important'
  },
  outlinedSelectInput: {
    padding: '8.5px 12px !important',
    borderRadius: '8px',
    height: '1em'
  },
  outlinedInput: {
    padding: '10px 12px !important',
    borderRadius: '8px',
    height: '1em'
  },
  outlinedLabel: {
    fontSize: '14px',
    fontWeight: 500,
    marginBottom: '22px',
    lineHeight: '22px',
    color: '#B9B9B9'
  },
  outlinedLabelError: {
    fontWeight: 500,
    fontSize: '14px',
    marginBottom: '22px',
    color: '#AF2930 !important',
    lineHeight: '22px'
  },
  outlinedRootHelperText: {
    marginTop: '4px'
  },
  adornedStart: {
    background: '#f5f5f5'
  },
  adornedEnd: {
    cursor: 'pointer'
  },
  filledAdornedEnd: {
    background: 'rgba(245, 245, 245, 1)',
    paddingRight: '8px'
  }
};

const getStyleProps = (variant, classes, Icon = null, iconPosition = 'start', iconHandler = null, isSelect = false) => {
  const iconProps = {
    startAdornment:
      Icon && iconPosition === 'start' ? (
        <InputAdornment position="start">
          <StyledIcon>
            <Icon onClick={iconHandler ? iconHandler : undefined} />
          </StyledIcon>
        </InputAdornment>
      ) : null,
    endAdornment:
      Icon && iconPosition === 'end' ? (
        <InputAdornment
          onClick={iconHandler ? iconHandler : undefined}
          classes={{ root: classes.adornedEnd }}
          position="start"
        >
          <StyledIcon>
            <Icon />
          </StyledIcon>
        </InputAdornment>
      ) : null
  };

  if (variant === 'filled' || variant === 'filled_lg') {
    return {
      InputLabelProps: {
        shrink: true,
        classes:
          variant === 'filled_lg'
            ? {
                root: classes.filledLgLabel
              }
            : undefined
      },
      InputProps: {
        ...iconProps,
        classes: {
          adornedStart: classes.adornedStart,
          adornedEnd: classes.filledAdornedEnd,
          root: classes.root,
          input: variant === 'filled' ? classes.input : classes.filledLgInput,
          underline: classes.underline
        }
      },
      classes: {
        root: classes.root
      }
    };
  }

  if (variant === 'outlined' || variant === 'outlined_error') {
    return {
      InputLabelProps: {
        shrink: true,
        classes: {
          root: variant === 'outlined_error' ? classes.outlinedLabelError : classes.outlinedLabel
        }
      },
      FormHelperTextProps: {
        style: { minHeight: '32px', marginTop: '4px', paddingLeft: '12px' }
      },
      InputProps: {
        startAdornment:
          Icon && iconPosition === 'start' ? (
            <InputAdornment position="start">
              <StyledIcon>
                <Icon onClick={iconHandler ? iconHandler : undefined} />
              </StyledIcon>
            </InputAdornment>
          ) : null,
        endAdornment:
          Icon && iconPosition === 'end' ? (
            <InputAdornment
              onClick={iconHandler ? iconHandler : undefined}
              classes={{ root: classes.adornedEnd }}
              position="start"
            >
              <StyledIcon>
                <Icon />
              </StyledIcon>
            </InputAdornment>
          ) : null,
        classes: {
          root: variant === 'outlined_error' ? classes.rootOutlinedError : classes.rootOutlined,
          input: isSelect ? classes.outlinedSelectInput : classes.outlinedInput,
          underline: classes.underline
        }
      }
    };
  }

  return {};
};

const FormTextField = ({
  input,
  label = '',
  meta: { touched, error, submitError, invalid },
  customError,
  classes,
  styleVariant,
  Icon,
  iconOptions = { position: 'start', handler: null },
  includeBottomIndent = false,
  ...custom
}) => {
  const styleProps = getStyleProps(
    styleVariant,
    classes,
    Icon,
    iconOptions.position,
    iconOptions.handler,
    custom.select
  );

  return (
    <TextField
      error={(touched && invalid) || !!customError}
      placeholder={label}
      label={label}
      helperText={(touched && invalid && (submitError || error)) || customError || (includeBottomIndent ? ' ' : '')}
      {...styleProps}
      {...input}
      {...custom}
    />
  );
};

export default withStyles(classes)(FormTextField);

/* Search_Input_Field */
