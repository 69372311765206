import { useEffect } from 'react';
import { SocketMessage } from '../api/types';
import { useDispatch } from 'react-redux';
import { WS_NEW_BID, WS_NEW_PROXY, WS_VEHICLE_DETAILS_UPDATE } from '../../constants/actionTypes';
import api from 'services/api/upload-vehicle';
import getSocket from '.';

export const useViewVehicleSocketEffect = (vehicleId?: number) => {
  const dispatch = useDispatch<any>();

  const listener = ({ payload, type }: SocketMessage) => {
    if (!vehicleId) return;

    if (type === WS_VEHICLE_DETAILS_UPDATE) {
      dispatch(
        api.util.updateQueryData('getVehicleById', vehicleId, draft => {
          if (payload.id !== vehicleId) return;
          for (const key in payload) {
            if (key === 'id') continue;
            draft[key] = payload[key];
          }

          // draft = { ...draft, ...payload.changes };
        })
      );
    }

    if (type === WS_NEW_PROXY) {
      dispatch(
        api.util.updateQueryData('getVehicleById', vehicleId, draft => {
          if (!payload || payload.vehicle_id !== vehicleId) return;
          console.log('new proxy hit');
          draft.proxy_bid = payload;
        })
      );
    }

    if (type === WS_NEW_BID) {
      dispatch(
        api.util.updateQueryData('getVehicleById', vehicleId, draft => {
          if (!payload || payload.vehicle_id !== vehicleId) return;
          draft.highest_bid = payload;
          if (draft.bids) {
            draft.bids.push(payload);
          }
        })
      );
    }
  };

  useEffect(() => {
    if (!vehicleId) return;
    const socket = getSocket();
    socket.emit('subscribe', {
      prefix: `vehicles`,
      ids: [vehicleId]
    });
    socket.on('message', listener);
    return () => {
      socket.emit('unsubscribe', {
        prefix: `vehicles`,
        ids: [vehicleId]
      });
      socket.off('message', listener);
    };
  }, [vehicleId]);
};
