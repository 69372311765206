export const GasStationIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44" fill="none">
    <path
      d="M40.956 17.1024L37.2893 15.2691C36.611 14.9391 35.7677 15.1957 35.4377 15.8741C35.0893 16.5707 35.3643 17.3957 36.0427 17.7257L38.9577 19.1741V27.9557L32.0827 27.9741V9.16406C32.0827 5.4974 29.626 3.66406 26.5827 3.66406H11.916C8.87268 3.66406 6.41602 5.4974 6.41602 9.16406V38.9557H3.66602C2.91435 38.9557 2.29102 39.5791 2.29102 40.3307C2.29102 41.0824 2.91435 41.7057 3.66602 41.7057H34.8327C35.5843 41.7057 36.2077 41.0824 36.2077 40.3307C36.2077 39.5791 35.5843 38.9557 34.8327 38.9557H32.0827V30.7241L40.3327 30.7057C41.1027 30.7057 41.7077 30.0824 41.7077 29.3307V18.3307C41.7077 17.8174 41.4143 17.3407 40.956 17.1024ZM10.9993 12.6291C10.9993 10.0807 12.5577 9.16406 14.4643 9.16406H24.0527C25.941 9.16406 27.4993 10.0807 27.4993 12.6291V14.8841C27.4993 17.4141 25.941 18.3307 24.0343 18.3307H14.4643C12.5577 18.3307 10.9993 17.4141 10.9993 14.8657V12.6291ZM11.916 22.4557H17.416C18.1677 22.4557 18.791 23.0791 18.791 23.8307C18.791 24.5824 18.1677 25.2057 17.416 25.2057H11.916C11.1643 25.2057 10.541 24.5824 10.541 23.8307C10.541 23.0791 11.1643 22.4557 11.916 22.4557Z"
      fill="#3994DE"
    />
  </svg>
);

export const MoneyIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44" fill="none">
    <path
      d="M25.6673 23.8359C24.1395 23.8359 22.8409 23.3012 21.7715 22.2318C20.702 21.1623 20.1673 19.8637 20.1673 18.3359C20.1673 16.8082 20.702 15.5095 21.7715 14.4401C22.8409 13.3707 24.1395 12.8359 25.6673 12.8359C27.1951 12.8359 28.4937 13.3707 29.5631 14.4401C30.6326 15.5095 31.1673 16.8082 31.1673 18.3359C31.1673 19.8637 30.6326 21.1623 29.5631 22.2318C28.4937 23.3012 27.1951 23.8359 25.6673 23.8359ZM12.834 29.3359C11.8257 29.3359 10.9625 28.9769 10.2444 28.2589C9.52634 27.5408 9.16732 26.6776 9.16732 25.6693V11.0026C9.16732 9.99427 9.52634 9.13108 10.2444 8.41302C10.9625 7.69497 11.8257 7.33594 12.834 7.33594H38.5006C39.509 7.33594 40.3722 7.69497 41.0902 8.41302C41.8083 9.13108 42.1673 9.99427 42.1673 11.0026V25.6693C42.1673 26.6776 41.8083 27.5408 41.0902 28.2589C40.3722 28.9769 39.509 29.3359 38.5006 29.3359H12.834ZM16.5007 25.6693H34.834C34.834 24.6609 35.193 23.7977 35.9111 23.0797C36.6291 22.3616 37.4923 22.0026 38.5006 22.0026V14.6693C37.4923 14.6693 36.6291 14.3102 35.9111 13.5922C35.193 12.8741 34.834 12.0109 34.834 11.0026H16.5007C16.5007 12.0109 16.1416 12.8741 15.4236 13.5922C14.7055 14.3102 13.8423 14.6693 12.834 14.6693V22.0026C13.8423 22.0026 14.7055 22.3616 15.4236 23.0797C16.1416 23.7977 16.5007 24.6609 16.5007 25.6693ZM36.6673 36.6693H5.50065C4.49232 36.6693 3.62912 36.3102 2.91107 35.5922C2.19301 34.8741 1.83398 34.0109 1.83398 33.0026V12.8359H5.50065V33.0026H36.6673V36.6693Z"
      fill="#CA8D57"
    />
  </svg>
);

export const DollarIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44" fill="none">
    <path
      d="M20.213 38.5V34.5583C18.5936 34.1917 17.1957 33.4889 16.0193 32.45C14.8429 31.4111 13.9797 29.9444 13.4297 28.05L16.8214 26.675C17.2797 28.1417 17.9595 29.2569 18.8609 30.0208C19.7623 30.7847 20.9464 31.1667 22.413 31.1667C23.6658 31.1667 24.7276 30.884 25.5984 30.3188C26.4693 29.7535 26.9047 28.875 26.9047 27.6833C26.9047 26.6139 26.5686 25.766 25.8964 25.1396C25.2241 24.5132 23.6658 23.8028 21.2214 23.0083C18.5936 22.1833 16.7908 21.1979 15.813 20.0521C14.8352 18.9062 14.3464 17.5083 14.3464 15.8583C14.3464 13.8722 14.988 12.3292 16.2714 11.2292C17.5547 10.1292 18.8686 9.50278 20.213 9.35V5.5H23.8797V9.35C25.4075 9.59444 26.6679 10.1521 27.6609 11.0229C28.654 11.8938 29.3797 12.9556 29.838 14.2083L26.4464 15.675C26.0797 14.6972 25.5602 13.9639 24.888 13.475C24.2158 12.9861 23.2991 12.7417 22.138 12.7417C20.7936 12.7417 19.77 13.0396 19.0672 13.6354C18.3644 14.2312 18.013 14.9722 18.013 15.8583C18.013 16.8667 18.4714 17.6611 19.388 18.2417C20.3047 18.8222 21.8936 19.4333 24.1547 20.075C26.263 20.6861 27.8595 21.6562 28.9443 22.9854C30.029 24.3146 30.5714 25.85 30.5714 27.5917C30.5714 29.7611 29.9297 31.4111 28.6464 32.5417C27.363 33.6722 25.7741 34.375 23.8797 34.65V38.5H20.213Z"
      fill="#479747"
    />
  </svg>
);
