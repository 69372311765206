/* eslint-disable no-else-return */
import React from 'react';
import styled from 'styled-components';
import { PAYMENT_METHODS, VEHICLE_STATUSES } from '../../../../constants';
import { EasyPayStatus } from '../../../../components/EasyPay';

const StyledStatus = styled.span`
  color: ${props => props.statusColor || '#000'};,
`;

const statusText = {
  awarded: 'Awarded',
  pulled: 'Pulled',
  active: 'Active',
  draft: 'Draft',
  deactivated: 'Deactivated',
  under_review: 'Under Review'
};

const statusColor = {
  awarded: '#009F0F',
  active: '#009F0F',
  pulled: '#E47E00',
  under_review: '#E47E00',
  draft: '#E47E00',
  deactivated: '#E47E00',
  fee_not_charged: '#E47E00'
};

const BidStatus = ({ vehicle, status, rounded = false }) => {
  const easyPaid = vehicle.payment_method === PAYMENT_METHODS.EASY_PAY;
  const awarded = status === VEHICLE_STATUSES.AWARDED;
  const feeCharged = vehicle.charge_completed;

  return (
    <>
      {easyPaid && <EasyPayStatus vehicle={vehicle} />}

      {!easyPaid && <StyledStatus statusColor={statusColor[status]}>{statusText[status] || 'None'}</StyledStatus>}

      {awarded && !feeCharged && (
        <StyledStatus statusColor={statusColor.fee_not_charged}>(Fee not charged)</StyledStatus>
      )}
    </>
  );
};

export default BidStatus;
