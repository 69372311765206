import { ActiveOfferInfo } from '../../../../../components/Offers';
import { useNoSelectorVehicleOfferAuctionById } from '../../../../../hooks';
import { useCurrentUser } from '../../../../../hooks/selectors';
import StatusComponent from '../../../../Shared/VehicleDetails/RoleRestrictedSection/BuyerRestrictionSection/StatusComponent';
import { useViewedVehicle } from '../../hooks/useViewedVehicle';
import AdminActiveOffers from './Admin';
import { BuyerActiveOffers } from './Buyer';
import SellerActiveOffers from './Seller';

export const ActiveOffers = () => {
  const vehicle = useViewedVehicle();
  const currentUser = useCurrentUser();
  if (!vehicle) return null;

  if (currentUser.role === 'buyer') {
    return <BuyerActiveOffers vehicle={vehicle} />;
  }

  if (currentUser.role === 'admin') {
    return <AdminActiveOffers />;
  }

  if (currentUser.role === 'seller') {
    return <SellerActiveOffers />;
  }

  return null;
};
