import { Grid, Typography } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import moment from 'moment';
import styled from 'styled-components';
import { COLOR_SUCCESSFUL, COLOR_ERROR, roles } from 'constants';
import { getHighestBid, getVehicleTimer } from 'utils/vehicle';
import { useEffect, useState } from 'react';
import { InfoBlock } from '../InfoBlock';
import { Timer } from 'components';
import { useCurrentUser } from '../../../../../hooks/selectors';
import { useViewedVehicle } from '../../hooks/useViewedVehicle';
import { useNoSelectorVehicleOfferAuctionById } from '../../../../../hooks';
import { OfferAuctionStatus } from '../../../../../types/vehicle';
import { AUCTION_STATUSES, BUYER_VEHICLE_STATUS, SELLER_VEHICLE_STATUS } from 'constants';
import { auctionOfferStatuses } from '../../../../../utils/statusCalculator';
import { formatCurrency } from '../../../../../utils';
import { useScreen, useWindowSize } from 'usehooks-ts';

const StyledGridWrapper = styled(Grid)``;

const SectionWrapper = styled.div`
  padding: 20px 16px 20px 16px;
  display: flex;
  flex-direction: column;
`;

const SectionTitle = styled(Typography)`
  font-size: 18px;
  color: #212121;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 2px;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Label = styled.span`
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: rgba(102, 102, 102, 1);

  @media (max-width: 1024px) {
    font-size: 12px;
    line-height: 20px;
  }
`;

const IconWrapper = styled.div`
  cursor: pointer;
  display: flex;
`;

const ArrowDownIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path d="M12 15.6016L5.97501 9.60156H18.025L12 15.6016Z" fill="#757575" />
  </svg>
);

const TitleTextWrapper = styled.div`
  display: flex;
  gap: 4px;
  margin-right: auto;
  font-size: 16px;
  font-weight: 700;
  line-height: 18.75px;
  color: rgba(33, 33, 33, 1);

  @media (max-width: 1024px) {
    font-size: 14px;
    line-height: 16.41px;
  }
`;

const StyledHighestBidder = styled(Typography)`
  display: inline;
  color: rgba(21, 182, 132, 1);

  @media (max-width: 1024px) {
    font-size: 10px;
    line-height: 12px;
  }
`;

const StyledOutbidded = styled(Typography)`
  display: inline;
  color: ${COLOR_ERROR};
`;

const HighlightedValue = styled.div`
  color: rgba(71, 151, 71, 1);
  font-weight: 600;
  font-size: 14px;
  line-height: 16.41px;

  @media (max-width: 1024px) {
    font-size: 12px;
    line-height: 20px;
  }
`;

const HelperValue = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 16.41px;
  color: rgba(153, 153, 153, 1);

  @media (max-width: 1024px) {
    font-size: 12px;
    line-height: 20px;
  }
`;

const BoldValue = styled.div`
  font-size: 14px;
  font-weight: 600;
  line-height: 16.41px;
  padding: 4px 6px;
  color: rgba(34, 90, 145, 1);
  border-radius: 8px;
  background: rgba(57, 148, 222, 0.4);

  @media (max-width: 1024px) {
    font-size: 12px;
    line-height: 20px;
  }
`;

export const useShowOfferBidsSection = () => {
  const vehicle = useViewedVehicle();
  const offerAuction = useNoSelectorVehicleOfferAuctionById(vehicle?.id || 0);
  const currentUser = useCurrentUser();
  const vehicleOfferBids = offerAuction?.offerBids || [];

  if (!vehicle) return false;

  const status = auctionOfferStatuses(vehicle, offerAuction, currentUser);

  const showOfferBidsSectionSellerAwarded = (() => {
    if (status !== SELLER_VEHICLE_STATUS.AWARDED) return false;

    const hasPublicOffer = offerAuction?.privateOffers?.some(offer => offer.type === 'public');
    return hasPublicOffer;
  })();

  if (vehicleOfferBids.length === 0) return false;
  if (currentUser.role === 'admin') return true;
  if (vehicle.auction?.status === AUCTION_STATUSES.ENDED) return false;
  if (currentUser.role === 'buyer')
    return [
      BUYER_VEHICLE_STATUS.AWARDED,
      BUYER_VEHICLE_STATUS.DECLINED,
      BUYER_VEHICLE_STATUS.AWARDED_NOT_CHARGED,
      BUYER_VEHICLE_STATUS.AWARDED_PENDING_CHARGE,
      BUYER_VEHICLE_STATUS.SELLER_ACCEPTING_OFFERS,
      BUYER_VEHICLE_STATUS.TOP_BUYER_SELLER_ACCEPTING_OFFER
    ].includes(status);
  if (currentUser.role === 'seller') {
    return (
      showOfferBidsSectionSellerAwarded ||
      (offerAuction?.status === OfferAuctionStatus.PUBLIC_OFFERS &&
        [
          SELLER_VEHICLE_STATUS.NEED_SELLER_REVIEW,
          SELLER_VEHICLE_STATUS.PUBLIC_COUNTER_SENT_TO_BUYERS,
          SELLER_VEHICLE_STATUS.PENDING_ADMIN_APPROVAL,
          SELLER_VEHICLE_STATUS.ACCEPTED_OFFER_PENDING_ADMIN_APPROVAL
        ].includes(status))
    );
  }
};

const AuctionSummarySection = () => {
  const currentUser = useCurrentUser();
  const vehicle = useViewedVehicle();
  const highestBid = getHighestBid(vehicle || {});
  const bid = highestBid; // vehicle.status === 'awarded'
  const bidder = highestBid.bidder; // vehicle.status === 'awarded' ? :
  const offerAuction = useNoSelectorVehicleOfferAuctionById(vehicle?.id || 0);
  const shouldRenderOfferSummary = offerAuction?.status === OfferAuctionStatus.PUBLIC_OFFERS;
  const shouldRenderOfferBidsSection = useShowOfferBidsSection();
  const initiallyCollapsed = shouldRenderOfferBidsSection || shouldRenderOfferSummary;

  const [isExpanded, setIsExpanded] = useState(true);

  useEffect(() => {
    if (initiallyCollapsed) {
      setIsExpanded(false);
    }
  }, [initiallyCollapsed]);

  if (!vehicle || !vehicle.auction) return null;

  const toggleExpanded = () => setIsExpanded(!isExpanded);

  const vehicleTimer = getVehicleTimer(vehicle);

  const currentTime = moment.utc();
  const vehicleProxyBid = (vehicle.proxy_bid || {}).amount || 0;
  const isUserProxyBidder = (vehicle.proxy_bid || {}).user_id === currentUser.id;
  const isVehicleProxyBidHigherThanBid = vehicleProxyBid > (bid?.amount || 0);
  const isVehicleTimerEnded =
    vehicle.end_time_spacing !== null &&
    moment(vehicle.auction.date_end)
      .utc()
      .add(vehicle.end_time_spacing, 'seconds')
      .isBefore(currentTime);

  const isBuyerHighestBidder = highestBid?.user_id === currentUser.id;
  const isUserBuyer = currentUser.role === roles.BUYER;
  const isBuyerPlacedBid = vehicle.bids.find((vehicleBid: any) => vehicleBid.user_id === currentUser.id);

  const { width = 0 } = useWindowSize();

  return (
    <InfoBlock>
      <SectionWrapper>
        <SectionTitle>
          <TitleTextWrapper>{isVehicleTimerEnded ? 'Auction Summary' : 'Auction In Progress'}</TitleTextWrapper>
          {isBuyerPlacedBid && (
            <>
              <StyledHighestBidder component="span">
                {isUserBuyer && isBuyerHighestBidder && " (You're the top bidder)"}
              </StyledHighestBidder>
              <StyledOutbidded component="span">
                {isUserBuyer && !isBuyerHighestBidder && " (You've been outbid)"}
              </StyledOutbidded>
            </>
          )}
          <IconWrapper onClick={toggleExpanded}>
            <ArrowDownIcon />
          </IconWrapper>
        </SectionTitle>
        {isExpanded && (
          <StyledGridWrapper container direction="column">
            <Row>
              <Label>Current Bid</Label>
              {bid ? (
                <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                  <HighlightedValue>{formatCurrency(bid.amount || 0)}</HighlightedValue>
                  {isUserProxyBidder && isVehicleProxyBidHigherThanBid && (
                    <HelperValue>{`(${formatCurrency(vehicleProxyBid)} max)`}</HelperValue>
                  )}
                </div>
              ) : (
                <span>$0</span>
              )}
            </Row>
            <Row>
              <Label>Highest Bidder</Label>
              <BoldValue>{bidder?.dealership_name || 'N/A'}</BoldValue>
            </Row>
            <Row>
              <Label>Time Remaining</Label>
              <Timer
                colorsConfig={{ activeColor: 'rgb(71, 151, 71)', active: true, stale: true }}
                extraStyles={
                  width > 1024
                    ? { fontSize: '14px', fontWeight: 600, lineHeight: '16.41px' }
                    : {
                        fontSize: '12px',
                        lineHeight: '20px',
                        fontWeight: 600
                      }
                }
                fullWidth={false}
                withColors
                toDate={vehicleTimer}
              />
            </Row>
          </StyledGridWrapper>
        )}
      </SectionWrapper>
    </InfoBlock>
  );
};

export default AuctionSummarySection;
