import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import api from '../api/upload-vehicle';
import { useSelector } from 'react-redux';

const saveImagesStateSlice = createSlice({
  name: 'saveImagesState',
  initialState: { loading: false },
  reducers: {},
  extraReducers: builder => {
    builder
      .addMatcher(api.endpoints.saveSectionImages.matchPending, state => {
        state.loading = true;
      })
      .addMatcher(api.endpoints.saveSectionImages.matchFulfilled, state => {
        state.loading = false;
      })
      .addMatcher(api.endpoints.saveSectionImages.matchRejected, state => {
        state.loading = false;
      });
  }
});

export const useIsSaveImagesLoading = () => {
  const isLoading = useSelector<{ saveImagesState: { loading: boolean } }, boolean>(
    state => state.saveImagesState.loading
  );
  return isLoading;
};

export default saveImagesStateSlice;
