export const NotesIcon = ({
  isActive,
  disableOuterBackground,
  hasNotes
}: {
  isActive?: boolean;
  hasNotes?: boolean;
  disableOuterBackground?: boolean;
  }) => {
  const fillColor = (() => {
    if (isActive) return '#459B4A';
    if (hasNotes) return 'rgba(34, 90, 145, 1)';
    return '#999999';
  })()
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
      {!disableOuterBackground && <rect width="32" height="32" rx="8" fill="#F3F3F3" fill-opacity="0.933333" />}
      <path
        d="M11.8582 12.6484C12.0693 12.6484 12.2541 12.5693 12.4124 12.4109C12.5707 12.2526 12.6499 12.0679 12.6499 11.8568C12.6499 11.6457 12.5705 11.4609 12.4118 11.3026C12.253 11.1443 12.0699 11.0651 11.8624 11.0651C11.6485 11.0651 11.4624 11.1445 11.3041 11.3032C11.1457 11.462 11.0666 11.6451 11.0666 11.8526C11.0666 12.0665 11.1457 12.2526 11.3041 12.4109C11.4624 12.5693 11.6471 12.6484 11.8582 12.6484ZM11.8582 16.7859C12.0669 16.7859 12.251 16.7082 12.4106 16.5526C12.5701 16.397 12.6499 16.2123 12.6499 15.9984C12.6499 15.7846 12.5705 15.5984 12.4118 15.4401C12.253 15.2818 12.0699 15.2026 11.8624 15.2026C11.6485 15.2026 11.4624 15.2818 11.3041 15.4401C11.1457 15.5984 11.0666 15.7846 11.0666 15.9984C11.0666 16.2123 11.1463 16.397 11.3059 16.5526C11.4654 16.7082 11.6495 16.7859 11.8582 16.7859ZM11.8582 20.9318C12.0693 20.9318 12.2541 20.8526 12.4124 20.6943C12.5707 20.5359 12.6499 20.3512 12.6499 20.1401C12.6499 19.929 12.5705 19.7443 12.4118 19.5859C12.253 19.4276 12.0699 19.3484 11.8624 19.3484C11.6485 19.3484 11.4624 19.4278 11.3041 19.5866C11.1457 19.7453 11.0666 19.9284 11.0666 20.1359C11.0666 20.3498 11.1457 20.5359 11.3041 20.6943C11.4624 20.8526 11.6471 20.9318 11.8582 20.9318ZM8.73323 24.8484C8.30614 24.8484 7.93549 24.6913 7.62125 24.3771C7.30702 24.0629 7.1499 23.6922 7.1499 23.2651V8.73176C7.1499 8.30468 7.30702 7.93402 7.62125 7.61979C7.93549 7.30555 8.30614 7.14844 8.73323 7.14844H19.3083C19.5251 7.14844 19.7317 7.1907 19.9282 7.27524C20.1247 7.35979 20.2958 7.47585 20.4416 7.62344L24.3749 11.5568C24.5225 11.7025 24.6386 11.8736 24.7231 12.0701C24.8076 12.2666 24.8499 12.4733 24.8499 12.6901V23.2651C24.8499 23.6922 24.6928 24.0629 24.3786 24.3771C24.0643 24.6913 23.6937 24.8484 23.2666 24.8484H8.73323ZM8.73323 23.2651H23.2666V12.8818H19.9124C19.6902 12.8818 19.502 12.8047 19.3478 12.6505C19.1937 12.4963 19.1166 12.3082 19.1166 12.0859V8.73176H8.73323V23.2651Z"
        fill={fillColor}
      />
    </svg>
  );
};

// export const ActiveNotesIcon = () => (
//   <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
//     <path
//       d="M7.85835 8.64844C8.06947 8.64844 8.25419 8.56927 8.41252 8.41094C8.57086 8.2526 8.65002 8.06788 8.65002 7.85676C8.65002 7.64566 8.57065 7.46095 8.4119 7.30261C8.25315 7.14428 8.07002 7.06511 7.86252 7.06511C7.64864 7.06511 7.46253 7.14449 7.3042 7.30324C7.14587 7.46197 7.0667 7.64509 7.0667 7.85259C7.0667 8.06649 7.14587 8.2526 7.3042 8.41094C7.46253 8.56927 7.64725 8.64844 7.85835 8.64844ZM7.85835 12.7859C8.06703 12.7859 8.25115 12.7082 8.4107 12.5526C8.57025 12.397 8.65002 12.2123 8.65002 11.9984C8.65002 11.7846 8.57065 11.5984 8.4119 11.4401C8.25315 11.2818 8.07002 11.2026 7.86252 11.2026C7.64864 11.2026 7.46253 11.2818 7.3042 11.4401C7.14587 11.5984 7.0667 11.7846 7.0667 11.9984C7.0667 12.2123 7.14647 12.397 7.306 12.5526C7.46555 12.7082 7.64967 12.7859 7.85835 12.7859ZM7.85835 16.9318C8.06947 16.9318 8.25419 16.8526 8.41252 16.6943C8.57086 16.5359 8.65002 16.3512 8.65002 16.1401C8.65002 15.929 8.57065 15.7443 8.4119 15.5859C8.25315 15.4276 8.07002 15.3484 7.86252 15.3484C7.64864 15.3484 7.46253 15.4278 7.3042 15.5866C7.14587 15.7453 7.0667 15.9284 7.0667 16.1359C7.0667 16.3498 7.14587 16.5359 7.3042 16.6943C7.46253 16.8526 7.64725 16.9318 7.85835 16.9318ZM4.73335 20.8484C4.30627 20.8484 3.93561 20.6913 3.62137 20.3771C3.30714 20.0629 3.15002 19.6922 3.15002 19.2651V4.73176C3.15002 4.30468 3.30714 3.93402 3.62137 3.61979C3.93561 3.30555 4.30627 3.14844 4.73335 3.14844H15.3084C15.5252 3.14844 15.7318 3.1907 15.9283 3.27524C16.1248 3.35979 16.296 3.47585 16.4417 3.62344L20.375 7.55676C20.5226 7.7025 20.6387 7.87361 20.7232 8.07011C20.8078 8.26661 20.85 8.47327 20.85 8.69009V19.2651C20.85 19.6922 20.6929 20.0629 20.3787 20.3771C20.0644 20.6913 19.6938 20.8484 19.2667 20.8484H4.73335ZM4.73335 19.2651H19.2667V8.88176H15.9125C15.6903 8.88176 15.5021 8.80468 15.3479 8.65051C15.1938 8.49635 15.1167 8.30815 15.1167 8.08594V4.73176H4.73335V19.2651Z"
//       fill="#459B4A"
//     />
//   </svg>
// );
