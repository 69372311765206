import { Fragment } from 'react';
import { useDispatch } from 'react-redux';
import { Grid, Button, Divider } from '@material-ui/core';
import styled from 'styled-components';
import NumberFormat from 'react-number-format';
import { modalsToggle, auctionVehiclesSetModal } from 'actions';
import ConfirmModal from 'components/Modals/ConfirmModal';
import { ActionButton } from 'components/ActionButton';
import moment from 'moment';
import { useViewedVehicle } from '../../../../hooks/useViewedVehicle';
import { useNoSelectorVehicleOfferAuctionById } from '../../../../../../../hooks';

const modalTitle = 'confirmApproveModal';
const chargeModalTitle = 'auctionDetailAwardBuyerModal';

const StyledDivider = styled(Divider)`
  && {
    margin: 24px 0;
  }
`;

const StyledGrid = styled(Grid)`
  padding: 8px 32px 8px 8px;
  font-weight: 500;
  padding-left: 0;
`;

const ApproveButton = styled(Button)`
  border-radius: 6px;
  min-width: 200px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
`;

export const HighestBid = () => {
  const currentVehicle = useViewedVehicle();
  const dispatch = useDispatch();
  const offerAuction = useNoSelectorVehicleOfferAuctionById(currentVehicle?.id || 0);

  const highestBid = currentVehicle?.highest_bid;

  if (!highestBid || !highestBid.bidder || !currentVehicle || !currentVehicle.proxy_bid) {
    return null;
  }

  const openApproveModal = () => {
    dispatch(modalsToggle(modalTitle));
  };

  const handleApprove = () => {
    const preSelectedBuyer = { value: highestBid.bidder.id, label: highestBid.bidder.dealership_name };
    const predefinedAwardPrice = highestBid.amount;
    dispatch(auctionVehiclesSetModal({ ...currentVehicle, preSelectedBuyer, predefinedAwardPrice }));
    dispatch(modalsToggle(modalTitle));
    dispatch(modalsToggle(chargeModalTitle));
  };

  const proxyBid = currentVehicle.proxy_bid || { amount: 0 };
  const isProxyHigherThanCurrentBid = proxyBid.amount >= highestBid.amount;
  const currentTime = moment.utc();
  const proxyOfferBid = offerAuction?.offerProxyBid;
  const showProxyOffer =
    proxyOfferBid && currentVehicle?.date_end && moment.utc(currentVehicle.date_end).isBefore(currentTime);
  return (
    <Fragment>
      <Grid container item xs={12}>
        <StyledGrid item xs={12} sm={8}>
          <div>
            <span>Highest Bid — </span>
            <NumberFormat value={highestBid.amount} displayType="text" thousandSeparator prefix="$" />
            <span>{` (${highestBid.bidder.dealership_name})`}</span>
          </div>
        </StyledGrid>
        <Grid item xs={12} sm={4}>
          <ActionButton onClick={openApproveModal} fullWidth variant="contained">
            Approve
          </ActionButton>
        </Grid>
        <Grid item xs={12}>
          <StyledDivider />
        </Grid>
        {isProxyHigherThanCurrentBid && !showProxyOffer && (
          <>
            <StyledGrid item xs={12} sm={8}>
              <div>
                <span>Proxy Bid — </span>
                <NumberFormat value={proxyBid.amount} displayType="text" thousandSeparator prefix="$" />
                <span>
                  {highestBid.bidder.user_id === proxyBid.user_id ? ` (${highestBid.bidder.dealership_name})` : ''}
                </span>
              </div>
            </StyledGrid>
            <Grid item xs={12}>
              <StyledDivider />
            </Grid>
          </>
        )}
        {showProxyOffer && (
          <>
            <StyledGrid item xs={12} sm={8}>
              <div>
                <span>Proxy Offer — </span>
                <NumberFormat value={proxyOfferBid?.amount} displayType="text" thousandSeparator prefix="$" />
              </div>
            </StyledGrid>
            <Grid item xs={12}>
              <StyledDivider />
            </Grid>
          </>
        )}
      </Grid>
      <ConfirmModal
        submitText={'Submit'}
        modalId={modalTitle}
        title={
          <>
            You are about to approve the high bid of{' '}
            <NumberFormat displayType="text" prefix="$" value={highestBid.amount} thousandSeparator /> from{' '}
            {highestBid.bidder.dealership_name}.
          </>
        }
        handleSubmit={handleApprove}
      />
    </Fragment>
  );
};
