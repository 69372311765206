import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { modalsToggle } from 'actions';
import ConfirmModal from 'components/Modals/ConfirmModal';
import { useViewedVehicle } from '../../../../hooks/useViewedVehicle';
import { useDeactivateVehicleMutation } from 'services/api/vehicle-actions';

const modalDeactivateTitle = 'deactivateVehicleModal';

const StyledDeactivateSpan = styled.span`
  color: #616161;
  text-decoration: underline;
  cursor: pointer;
  font-weight: 500;
  display: block;
`;

export const DeactivateBlock = () => {
  const currentVehicle = useViewedVehicle();
  const [deactivateVehicleFn] = useDeactivateVehicleMutation();
  const dispatch = useDispatch();

  if (!currentVehicle) return null;

  const openDeactivateModal = () => dispatch(modalsToggle(modalDeactivateTitle));
  const handleDeactivate = () => {
    deactivateVehicleFn(currentVehicle.id);
  };

  return (
    <>
      <StyledDeactivateSpan onClick={openDeactivateModal}>Deactivate vehicle</StyledDeactivateSpan>
      <ConfirmModal
        modalId={modalDeactivateTitle}
        title={`Are you sure you want to deactivate the vehicle ${currentVehicle.year} ${currentVehicle.make} ${currentVehicle.model}? Once it is deactivated, the vehicle has to be reactivated before taking any other action on it.`}
        handleSubmit={handleDeactivate}
        submitText="Deactivate"
      />
    </>
  );
};
