import apiSlice from '..';
import { modalsHide, snackShow } from '../../../actions';
import { User } from '../../../types/user';

export const vehicleActionsApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    pullVehicle: builder.mutation<void, number>({
      query: vehicleId => ({
        url: `/vehicles/${vehicleId}/pull`,
        method: 'POST'
      }),
      onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
          dispatch(snackShow({ type: 'error', message: 'Vehicle was pulled' }));
        } catch (error) {
          dispatch(snackShow({ type: 'error', message: 'Failed to pull the vehicle' }));
        } finally {
          dispatch(modalsHide('pullVehicleModal'));
        }
      }
    }),
    deactivateVehicle: builder.mutation<void, number>({
      query: vehicleId => ({
        url: `/vehicles/${vehicleId}/deactivate`,
        method: 'POST'
      }),
      onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
          dispatch(snackShow({ message: 'Vehicle was deactivated' }));
        } catch (error) {
          dispatch(snackShow({ type: 'error', message: 'Failed to deactivate the vehicle' }));
        } finally {
          dispatch(modalsHide('deactivateVehicleModal'));
        }
      }
    }),
    approveHighBid: builder.mutation<void, { vehicleId: number; data: any }>({
      query: ({ vehicleId, data }) => ({
        url: `vehicles/${vehicleId}/approve-bid`,
        body: data
      }),
      onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
          dispatch(snackShow({ message: 'Successfully approved bid' }));
        } catch (error) {
          dispatch(snackShow({ type: 'error', message: 'Failed to approve bid' }));
        } finally {
          dispatch(modalsHide('deactivateVehicleModal'));
        }
      }
    }),
    getBuyerFee: builder.query<Array<{ min_amount: number; max_amount: number; fee: number }>, number>({
      query: price => ({
        url: `/financials/buyer-fee?price=${price}`
      }),
      transformResponse: (r: any) => r.data
    }),
    unawardVehicle: builder.mutation<void, number>({
      query: vehicleId => ({
        url: `vehicles/${vehicleId}/unaward`,
        method: 'POST'
      })
    }),
    retryFeeCharge: builder.mutation<void, any>({
      query: body => ({
        url: `financials/retry-fee-charging`,
        method: 'POST',
        body
      })
    }),
    getUsers: builder.query<{ users: User[] }, void>({
      query: () => ({
        url: `/users`
      }),
      transformResponse: (r: any) => r.data
    })
  }),
  overrideExisting: true
});

export const {
  usePullVehicleMutation,
  useDeactivateVehicleMutation,
  useApproveHighBidMutation,
  useGetBuyerFeeQuery,
  useUnawardVehicleMutation,
  useRetryFeeChargeMutation,
  useGetUsersQuery
} = vehicleActionsApiSlice;

export default vehicleActionsApiSlice;
