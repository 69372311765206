import styled from 'styled-components';
import { SectionConfig } from '../../../services/api/vehicle-sections/types';
import { useGetSectionsOrderQuery } from '../../../services/api/vehicle-sections';
import { CollapsibleSubSection } from './CollapsibleSubSection';
import {
  DndContext,
  DragOverlay,
  useSensor,
  useSensors
} from '@dnd-kit/core';
import { SortableContext } from '@dnd-kit/sortable';
import {
  DragIconMouseSensor,
  DragIconTouchSensor,
  useSubSectionDragEnd,
  useSubSectionDragStart
} from '../../../services/dndkit';
import { useMovedSubSectionId } from '../../../services/dndkit/sectionDragSlice';
import { DraggableSubSectionOverlay } from '../overlay/DraggableSubSectionOverlay';

type Props = {
  sectionId: keyof SectionConfig;
};

const Wrapper = styled.div`
  background: rgba(211, 231, 247, 1);
  flex-direction: column;
  padding: 8px;
  display: flex;
  gap: 4px;
`;

export const CollapsibleSubSections = ({ sectionId }: Props) => {
  const { data: order = [] } = useGetSectionsOrderQuery();
  const subSections = order.find(e => e[0] === sectionId)?.[1] || [];

  const touchSensor = useSensor(DragIconTouchSensor, {
    activationConstraint: {
      delay: 300,
      tolerance: 8
    }
  });

  const mouseSensor = useSensor(DragIconMouseSensor, {
    activationConstraint: {
      distance: 8
    }
  });

  const sensors = useSensors(mouseSensor, touchSensor);

  const onDragEnd = useSubSectionDragEnd(sectionId);
  const onDragStart = useSubSectionDragStart();

  const movedSubSection = useMovedSubSectionId();

  return (
    <DndContext sensors={sensors} onDragEnd={onDragEnd} onDragStart={onDragStart}>
      <SortableContext disabled={subSections.length <= 1} items={subSections.map(name => `${sectionId}.${name}`)}>
        <Wrapper>
          {subSections.map(subSection => (
            <CollapsibleSubSection subSectionId={subSection} sectionId={sectionId} key={subSection} />
          ))}
        </Wrapper>
      </SortableContext>
      <DragOverlay>{movedSubSection && <DraggableSubSectionOverlay sectionName={movedSubSection} />}</DragOverlay>
    </DndContext>
  );
};
