import { useDispatch, useSelector } from 'react-redux';
import { Vehicle } from '../../types/vehicle';
import styled from 'styled-components';
import { User } from '../../types/user';
import { getHighestBid, getMinBitAmount, getVehicleTimer } from '../../utils/vehicle';
import { NotesSection } from '../Notes';
import { VEHICLE_STATUSES } from '../../constants';
import { IncreaseHighBid } from '../../pages/Buyer/BidsList/containers';
import FormattedAmount from '../FormattedAmount';
import Timer from '../Timer';
import { InfoValue, Title, InfoWrapper } from './styled';
import { CurrentBidComponent } from '../Typography';
import { initVehicleDetailsData } from '../../actions/vehicles';
import { useParams } from 'react-router-dom';
import { push } from 'connected-react-router';
import { BidInfoLabel } from '../../pages/Buyer/BidsList/components/tableBlocks';
import { ReservePriceLabel } from '../ReservePriceMet';
import { NotesIcon } from '../../pages/Admin/ViewVehicle/icons/NotesIcon';
import { Form } from 'react-final-form';
import { IncreaseAmountMobile } from '../IncreaseAmount/mobile';
import { usePlaceSidePanelBidMutation } from '../../services/api/my-bids';
import { RoundedBidInfoLabel } from '../../pages/Buyer/BidsList/components/tableBlocks/RoundedBidInfoLabel';

type Props = { vehicle: Vehicle; includeIndents?: boolean };

const Container = styled.div<{ includeIndents?: boolean }>`
  display: flex;
  width: ${({ includeIndents }) => (includeIndents ? '400px' : '357px')};

  @media (max-width: 567px) {
    width: 100%;
    max-width: 450px;
    justify-content: center;
  }
`;

const InnerContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 357px;
  position: relative;

  @media (max-width: 567px) {
    width: calc(100% - 18px);
    max-width: 450px;
  }
`;

const BorderElement = styled.div`
  width: 1px;
  height: 80%;
  background-color: #b0b0b0;
  position: absolute;
  bottom: 10%;
  right: -22px;
`;

const ContentWrapper = styled.div`
  display: flex;
  gap: 8px;
  position: relative;

  & > div {
    flex: 1 1 100%;
  }
`;

const ContentInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const VehicleImage = styled.div<{ imageUrl: string }>`
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  border-radius: 20px;
  border: 0.5px solid rgba(210, 210, 210, 1);
  width: 100%;
  height: 117px;
  background-position: center;
  background-image: ${({ imageUrl }) => `url(${imageUrl})`};
`;

const VehicleInfoLabelWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const CurrentBidLabel = styled.div`
  font-size: 12px;
  line-height: 14px;
  font-weight: 500;
  color: rgba(102, 102, 102, 1);
`;

const ContentContainer = styled.div`
  display: flex;
  margin-top: 6px;
  flex-direction: column;
  & > div {
    flex: 1 1 100%;
  }
`;

const InfoLabel = styled.div`
  color: #757575;
  font-size: 10px;
  font-weight: 400;
  line-height: 11.72px;
`;

const ValueWrapper = styled.div`
  display: flex;
  gap: 2px;
  align-items: baseline;
`;

const BidInfoLabelWrapper = styled.div`
  position: absolute;
  right: 8px;
  top: 8px;
  z-index: 998;
`;

const Header = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

const NotesWrapper = styled.div`
  background-color: rgba(57, 148, 222, 0.1);
  color: rgba(57, 148, 222, 1);
  border-radius: 10px;
  display: flex;
  height: 24px;
  flex: 1 1 100%;
  padding: 1px 0;
  justify-content: center;
  align-items: center;

  & * .notes-container {
    font-size: 8px;
    line-height: 9.38px;

    background: transparent;
    border: none;
  }

  & * .add-notes-container {
    font-size: 8px;
    line-height: 9.38px;

    background: transparent;
    border: none;
  }

  & * .notes-text {
    max-width: 50px;
  }
`;

const ReserveAndNotesContainer = styled.div`
  display: flex;
  gap: 8px;
  margin-top: auto;
`;

const StyledForm = styled(Form)``;
export const VehicleSideCardMobile = ({ vehicle, includeIndents }: Props) => {
  const user: User = useSelector((state: any) => state.user?.user);

  const { vehicleId } = useParams<{ vehicleId?: string }>();

  const highestBid = getHighestBid(vehicle);
  const myBid = vehicle.my_bid;
  const vehicleTimer = getVehicleTimer(vehicle);
  const minBidAmount = getMinBitAmount(vehicle, user);

  const vehicleImageSrc = vehicle.images?.[0]?.url ?? '';
  const vehicleName = `${vehicle.year} ${vehicle.make} ${vehicle.model}`;

  const canPlaceBid = !!user && user.role === 'buyer' && vehicle.status === VEHICLE_STATUSES.ACTIVE;
  const dispatch = useDispatch();

  const handleOnClick = () => {
    if (vehicleId && Number(vehicleId) === vehicle.id) return;
    dispatch(push(`/vehicles/${vehicle.id}/view`));
    dispatch(initVehicleDetailsData(vehicle));
  };

  const VehicleValueInfo = () => (
    <VehicleInfoLabelWrapper>
      <ValueWrapper>
        <InfoLabel>Book: </InfoLabel>
        <InfoValue lineHeight={11.72} size={10}>
          <FormattedAmount amount={vehicle.bluebook_value} />
        </InfoValue>
      </ValueWrapper>

      <InfoLabel>/</InfoLabel>
      <ValueWrapper>
        <InfoLabel style={{ marginLeft: '2px' }}>miles: </InfoLabel>
        <InfoValue lineHeight={11.72} size={10}>
          <FormattedAmount prefix="" amount={vehicle.bluebook_mileage} />
        </InfoValue>
      </ValueWrapper>
    </VehicleInfoLabelWrapper>
  );

  return (
    <Container includeIndents={includeIndents}>
      <InnerContent>
        <ContentWrapper>
          <VehicleImage imageUrl={vehicleImageSrc} onClick={handleOnClick}>
            <RoundedBidInfoLabel item={vehicle} />
          </VehicleImage>
          <ContentInfoContainer>
            <Header>
              <Title size="lg">{vehicleName}</Title>
            </Header>
            <VehicleValueInfo />
            <ContentContainer>
              <InfoWrapper>
                <CurrentBidLabel>Current bid:</CurrentBidLabel>
                <InfoValue size={12} lineHeight={14}>
                  {myBid?.amount ? <CurrentBidComponent amount={highestBid.amount} /> : '-'}
                </InfoValue>
              </InfoWrapper>
              <InfoWrapper>
                <InfoLabel>Time left:</InfoLabel>
                <InfoValue black size={12} lineHeight={14}>
                  <Timer toDate={vehicleTimer} />
                </InfoValue>
              </InfoWrapper>
              <InfoWrapper>
                <InfoLabel>Your bid:</InfoLabel>
                <InfoValue black size={12} lineHeight={14}>
                  {myBid?.amount ? <FormattedAmount amount={myBid.amount} /> : '-'}
                </InfoValue>
              </InfoWrapper>
            </ContentContainer>
            <ReserveAndNotesContainer>
              <ReservePriceLabel fitContent size="sm" activeVehicle={vehicle} />
              <NotesWrapper>
                <NotesSection Icon={NotesIcon} vehicle={vehicle} />
              </NotesWrapper>
            </ReserveAndNotesContainer>
          </ContentInfoContainer>
        </ContentWrapper>
        {canPlaceBid && <PlaceBidForm minAmount={minBidAmount} id={vehicle.id} />}
        {includeIndents && <BorderElement />}
      </InnerContent>
    </Container>
  );
};

const PlaceBidForm = ({ minAmount, id }: { minAmount: number; id: number }) => {
  const [placeBid] = usePlaceSidePanelBidMutation();

  const onSubmit = () => {
    placeBid({ vehicle_id: id, amount: Number(minAmount) });
  };

  return (
    <StyledForm onSubmit={onSubmit} name={`place-side-bid-${id}`}>
      {() => <IncreaseAmountMobile row type="bid" initialAmount={minAmount} />}
    </StyledForm>
  );
};
