import styled from 'styled-components';
import { Vehicle } from '../../../../../../types/vehicle';
import { useGetBidInfoLabel } from '../../../../../Buyer/BidsList/components/tableBlocks/RoundedBidInfoLabel';

const BidInfoLabel = styled.div<{ isHighest: boolean }>`
  position: absolute;
  left: 12px;
  top: 10px;
  padding: 5px 27px;
  background: ${props => (props.isHighest ? 'rgba(71, 151, 71, 1)' : 'rgba(175, 41, 48, 1)')};
  color: #fff;
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  border-radius: 20px;

  @media (max-width: 1024px) {
    font-size: 10px;
    line-height: 12px;
    padding: 6px 24px;
  }
`;

export const BiddingLabel = ({ vehicle }: { vehicle: Vehicle }) => {
  const { label, isHighest } = useGetBidInfoLabel(vehicle);

  if (vehicle.status !== 'active') {
    return null;
  }

  return <BidInfoLabel isHighest={isHighest}>{label}</BidInfoLabel>;
};
