import styled from 'styled-components';
import { useViewedVehicle } from '../../../hooks/useViewedVehicle';
import { AddressIcon, HomeIcon, ListerIcon, PhoneIcon } from '../../../icons/InfoIcons';
import { formatAddress } from '../../../../ListerDashboard/utils/formatAddress';
import { directionLink, formatCurrency } from '../../../../../../utils';
import { useCurrentUser } from '../../../../../../hooks/selectors';
import { useGetListerRouteDistancesQuery } from '../../../../../../services/api/list-vehicles';
import { PhoneNumberText } from '../../../../../../components';

const SellerInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  @media (max-width: 1024px) {
    gap: 2px;
  }
`;

const InfoRow = styled.div`
  display: flex;
  position: relative;
  justify-content: space-between;
  flex: 1 1 100%;
`;

const InfoText = styled.span<{ bold?: boolean }>`
  font-size: 14px;
  font-weight: ${props => (props.bold ? '600' : '400')};
  line-height: 16.41px;
  align-items: center;
  display: flex;
  gap: 16px;

  @media (max-width: 1024px) {
    font-size: 12px;
    line-height: 14px;
    gap: 8px;
  }
`;

const InfoValue = styled.span`
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;

  @media (max-width: 1024px) {
    font-size: 12px;
    line-height: 20px;
  }
`;

const RowHelperText = styled.span`
  position: absolute;
  color: rgba(102, 102, 102, 1);
  font-size: 10px;
  line-height: 12px;
  left: 40px;
  bottom: -8px;

  @media (max-width: 1024px) {
    left: 26px;
  }
`;

const PriceLabel = styled.div`
  border: 1px solid rgba(204, 204, 204, 1);
  background: #fff;
  border-radius: 30px;
  height: 32px;
  width: fit-content;
  padding: 4px 12px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 1024px) {
    padding: 8px;
  }
`;

const DirectionLink = styled.a`
  text-decoration: none;
  margin-top: 12px;
  cursor: pointer;
  display: flex;
  border: 1px solid rgba(34, 90, 145, 1);
  height: 42px;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 16px;
  border-radius: 20px;
  line-height: 18.75px;

  @media (max-width: 1024px) {
    margin-top: 16px;
  }
`;

const IconWrapper = styled.div`
  display: flex;
  width: 24px;
  height: 26px;

  @media (max-width: 1024px) {
    width: 18px;
    height: 18px;
  }
`;

export const SellerInfo = () => {
  const vehicle = useViewedVehicle();

  const user = useCurrentUser();

  const origin = formatAddress(user);
  const destination = vehicle?.user ? formatAddress(vehicle.user) : '';

  const { data: route } = useGetListerRouteDistancesQuery(
    {
      origin,
      destinations: [destination],
      poolId: -1
    },
    {
      skip: !user || !vehicle || !vehicle.user
    }
  );

  if (!vehicle) return null;

  const infoArray = [
    {
      Icon: ListerIcon,
      label: 'Lister Name:',
      value: vehicle.user.dealership_name
    },
    {
      Icon: AddressIcon,
      label: 'Address:',
      value: formatAddress(vehicle.user)
    },
    {
      Icon: PhoneIcon,
      label: 'Phone Number:',
      value: vehicle.user?.seller?.phone_number ? <PhoneNumberText text={vehicle.user?.seller?.phone_number} /> : 'N/A'
    }
  ];

  return (
    <SellerInfoWrapper>
      {infoArray.map(({ Icon, value, label }) => (
        <InfoRow key={label}>
          <InfoText>
            <IconWrapper>
              <Icon />
            </IconWrapper>
            <span>{label}</span>
          </InfoText>
          <InfoValue>{value}</InfoValue>
        </InfoRow>
      ))}
      {route && (
        <InfoRow>
          <InfoText bold>
            <IconWrapper>
              <HomeIcon />
            </IconWrapper>
            <span>{`${route.distanceInMiles} miles away > ${vehicle.user.city}, ${vehicle.user.state}`}</span>
          </InfoText>
          <RowHelperText>{`Approximate shipping quote from ${user.city}, ${user.state} to ${vehicle.user.city}, ${vehicle.user.state}`}</RowHelperText>
          <PriceLabel>
            <InfoValue>{vehicle.vehicle_price ? formatCurrency(vehicle.vehicle_price) : 'N/A'}</InfoValue>
          </PriceLabel>
        </InfoRow>
      )}
      <DirectionLink rel="noopener noreferrer" target="_blank" href={directionLink(user, vehicle.user)}>
        <span>Get Direction</span>
      </DirectionLink>
    </SellerInfoWrapper>
  );
};
