/* eslint-disable no-else-return */
import { Button, Divider, Grid, Typography } from '@material-ui/core';
import moment from 'moment';
import NumberFormat from 'react-number-format';
import { connect, useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { modalsToggle } from '../../../../../actions';
import { Timer } from '../../../../../components';
import { ActionButton } from '../../../../../components/ActionButton';
import ConfirmModal from '../../../../../components/Modals/ConfirmModal';
import {
  AcceptOfferModal,
  ActiveOfferInfo,
  OfferHistory,
  PublicOfferInfo,
  SendOfferModal
} from '../../../../../components/Offers';
import { SELLER_VEHICLE_LABELS, SELLER_VEHICLE_STATUS, VEHICLE_STATUSES } from '../../../../../constants';
import { useNoSelectorVehicleOfferAuctionById, useOfferAuctionBidsInfo } from '../../../../../hooks';
import { useSellerCounterControls } from '../../../../../hooks/useSellerCounterControls';
import { auctionOfferStatuses } from '../../../../../utils/statusCalculator';
import { ReservePriceSection } from '../../components';
import SectionTitle from '../../components/SectionTitle';
import TopOfferBlock from './TopOfferBlock';
import { OfferAuctionStatus } from 'types/vehicle';
import { useAcceptBidMutation } from 'services/api/offer-auction';

const TimerWrapper = styled(Grid)`
  margin-bottom: 16px;
  & > h2 {
    margin: 0;
  }

  & > span {
    width: unset;
  }
`;

const StyledDivider = styled(Divider)`
  && {
    margin: 24px 0;
  }
`;

const StyledDescription = styled(Typography)`
  && {
    color: #616161;
  }
`;

const SectionTitleWithStatus = styled(SectionTitle)`
  span {
    color: ${props => props.statusColor || '#000'};
  }
`;

const StyledViewReportOfSale = styled(Typography)`
  && {
    color: #005a96;
    text-decoration: underline;
    cursor: pointer;
    text-align: right;
    font-weight: 500;
    margin-top: 17px;
  }
`;

const StyledAcceptButton = styled(Button)`
  && {
    padding: 8px 55px;
  }
`;

const StyledSubheading = styled(Typography)`
  && {
    font-weight: 500;
  }
`;

const HighlightedText = styled.span`
  && {
    color: #212121;
    font-weight: 500;
  }
`;

const ReservePriceSectionWrapper = styled.span`
  width: 100%;
`;

const CounterControlsWrapper = styled.div`
  display: flex;
  gap: 8px;
  padding: 20px 0;
  width: 100%;

  & > button {
    flex: 1 1 100%;
  }
`;

const ControlButton = styled(Button)`
  border-radius: 6px;
  width: ${props => (props.autoDimensions ? '100%' : '94px')};
  height: ${props => (props.autoDimensions ? 'auto' : '44px')};
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  background: ${props => props.color};
  color: ${props => props.textColor};
  font-size: 14px;
  font-weight: 500;

  &:hover {
    background: ${props => props.color};
  }
`;

const CounterButton = styled(Button)`
  border-radius: 6px;
  width: 100%;
  background: #3994de;
  color: #fff;
  height: 44px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  font-size: 14px;
  font-weight: 500;

  &:hover {
    background: #3994de;
  }
`;

const statusColor = {
  yellow: '#E47E00',
  red: '#D50808',
  green: '#06A000'
};

const StatusComponent = ({ bid, vehicle, sellerAcceptBid, toggleModal }) => {
  const user = useSelector(state => state.user?.user);
  const offerAuction = useNoSelectorVehicleOfferAuctionById(vehicle.id);
  const offerAuctionStatus = auctionOfferStatuses(vehicle, offerAuction, user);
  const buyerResponseTime = useSelector(state => state.locations?.currentLocation?.buyer_response_time || 900000);
  const sellerResponseTime = useSelector(state => state.locations?.currentLocation?.seller_response_time || 900000);

  const dispatch = useDispatch();

  const status = vehicle.status;
  const bidAccepted = status === VEHICLE_STATUSES.ACTIVE && vehicle.buyer_id;

  const currentTime = moment.utc();
  const isVehicleTimerEnded =
    vehicle.end_time_spacing !== null &&
    moment(vehicle.auction.date_end)
      .utc()
      .add(vehicle.end_time_spacing, 'seconds')
      .isBefore(currentTime);

  const buttons = useSellerCounterControls(vehicle.id);

  const [acceptBid] = useAcceptBidMutation();

  const handleAcceptBid = () => {
    acceptBid({ vehicle_id: vehicle.id, offer_id: highestBidOffer.id });
    dispatch(modalsToggle('acceptTopOffer'));
  };
  const { highestBidOffer } = useOfferAuctionBidsInfo(offerAuction);

  if (status === VEHICLE_STATUSES.PULLED) {
    return (
      <Grid container>
        <SectionTitleWithStatus variant="title" statusColor={statusColor.red}>
          Bid Status - <span>Pulled</span>
        </SectionTitleWithStatus>
        <StyledDescription>The vehicle has been pulled and it is no longer available for bidding</StyledDescription>
      </Grid>
    );
  }
  if (status === SELLER_VEHICLE_STATUS.PENDING) {
    return (
      <Grid container>
        <SectionTitleWithStatus variant="title" statusColor={statusColor.yellow}>
          Bid Status - <span>Pending</span>
        </SectionTitleWithStatus>
      </Grid>
    );
  }
  if (status === VEHICLE_STATUSES.AWARDED) {
    return (
      <Grid container direction="column">
        <SectionTitleWithStatus variant="title" statusColor={statusColor.green}>
          Bid Status - <span>Awarded</span>
        </SectionTitleWithStatus>
        <StyledDivider />
        <StyledDescription>
          The vehicle was awarded to <HighlightedText>{vehicle.awarded_user.dealership_name}</HighlightedText> for the
          amount of{' '}
          <HighlightedText>
            <NumberFormat displayType="text" prefix="$" value={vehicle.vehicle_price} thousandSeparator />
          </HighlightedText>
        </StyledDescription>
        <StyledViewReportOfSale
          onClick={() => {
            window.open(vehicle.seller_report_of_sale);
          }}
        >
          View Report of Sale
        </StyledViewReportOfSale>
        <StyledDivider />
        <ReservePriceSection vehicle={vehicle} />
      </Grid>
    );
  }
  if (status === VEHICLE_STATUSES.ACTIVE && !isVehicleTimerEnded && !vehicle.buyer_id) {
    return (
      <Grid container justify="space-between">
        <Grid item>
          <SectionTitleWithStatus variant="title" statusColor={statusColor.yellow}>
            Bid Status - <span>Undecided</span>
          </SectionTitleWithStatus>
          <StyledDescription>
            Please wait until the bid is resolved or contact AutoAxess for more information.
          </StyledDescription>
          <ReservePriceSectionWrapper>
            <ReservePriceSection vehicle={vehicle} includeDivider />
          </ReservePriceSectionWrapper>
        </Grid>
      </Grid>
    );
  }
  if (bidAccepted) {
    if (isVehicleTimerEnded) {
      return (
        <Grid container>
          <SectionTitleWithStatus variant="title" statusColor={statusColor.yellow}>
            Bid Status - <span>Pending AutoAxess Review</span>
          </SectionTitleWithStatus>
          <StyledDescription>
            You accepted the highiest bid of{' '}
            <HighlightedText>
              <NumberFormat displayType="text" prefix="$" value={bid.amount} thousandSeparator />
            </HighlightedText>{' '}
            from <HighlightedText>{bid.bidder.dealership_name}</HighlightedText>
            <br />
            AutoAxess is reviewing it.
          </StyledDescription>
          <ReservePriceSection vehicle={vehicle} includeDivider />
        </Grid>
      );
    } else {
      return (
        <Grid container>
          <SectionTitleWithStatus variant="title" statusColor={statusColor.yellow}>
            Bid Status - <span>Processing</span>
          </SectionTitleWithStatus>
          <StyledDescription>
            You accepted the highiest bid of{' '}
            <HighlightedText>
              <NumberFormat displayType="text" prefix="$" value={bid.amount} thousandSeparator />
            </HighlightedText>{' '}
            from <HighlightedText>{bid.bidder.dealership_name}</HighlightedText>
            <br />
            AutoAxess is reviewing it.
          </StyledDescription>
          <ReservePriceSection vehicle={vehicle} includeDivider />
        </Grid>
      );
    }
  }
  if (
    offerAuctionStatus === SELLER_VEHICLE_STATUS.NEED_SELLER_REVIEW &&
    offerAuction?.status === OfferAuctionStatus.PRIVATE_BIDS
  ) {
    return (
      <Grid container justify="space-between">
        <Grid item style={{ width: '100%' }}>
          <SectionTitleWithStatus variant="title" statusColor={statusColor.yellow}>
            Bid Status - <span>Needs Seller Review</span>
          </SectionTitleWithStatus>
          <StyledDescription>
            Auction's ended. You now have a solo chance to negotiate with Top Buyer.
          </StyledDescription>
          <StyledDivider />
          <Grid container alignItems="center">
            <Grid item xs={12} sm={8}>
              <StyledSubheading variant="subheading">
                Highest Bid - <NumberFormat displayType="text" prefix="$" value={bid.amount} thousandSeparator />
                {bid.bidder && ` (${bid.bidder.dealership_name})`}
              </StyledSubheading>
            </Grid>
            {bid.bidder && (
              <Grid item xs={12} sm={4}>
                <ActionButton
                  onClick={() => toggleModal('acceptBidConfirmModal')}
                  colorVariant="accept"
                  color="primary"
                >
                  accept
                </ActionButton>
              </Grid>
            )}
          </Grid>
          <StyledDivider />
          <ReservePriceSectionWrapper>
            <ReservePriceSection vehicle={vehicle} />
          </ReservePriceSectionWrapper>
        </Grid>

        {bid.bidder && (
          <ConfirmModal
            modalId="acceptBidConfirmModal"
            title={
              <>
                You are about to accept the high bid of{' '}
                <NumberFormat displayType="text" prefix="$" value={bid.amount} thousandSeparator /> from{' '}
                {bid.bidder.dealership_name}. Please confirm.
              </>
            }
            handleSubmit={() => sellerAcceptBid(vehicle.id)}
          />
        )}
      </Grid>
    );
  }
  if (offerAuctionStatus === SELLER_VEHICLE_STATUS.COUNTER_SENT_TO_BUYERS) {
    const sellerAmount = offerAuction?.privateOffers.find(el => el.sender_id === user?.id && !el.is_admin)?.amount;
    const adminAmount = offerAuction?.privateOffers.find(el => el.sender_id === user?.id && el.is_admin)?.amount;

    return (
      <Grid container direction="column">
        <TimerWrapper container justify="space-between" alignItems="center">
          <SectionTitleWithStatus variant="title" statusColor={statusColor.yellow}>
            Bid Status - <span>Counter Sent To Buyer</span>
          </SectionTitleWithStatus>
        </TimerWrapper>

        {sellerAmount && (
          <StyledSubheading variant="subheading">
            Seller Counter Amount -{' '}
            <NumberFormat displayType="text" prefix="$" value={sellerAmount} thousandSeparator />
          </StyledSubheading>
        )}
        {adminAmount && (
          <StyledSubheading variant="subheading">
            AutoAxess Counter Amount -{' '}
            <NumberFormat displayType="text" prefix="$" value={adminAmount} thousandSeparator />
          </StyledSubheading>
        )}
        <StyledDescription>The counter was sent to top buyer. You will see updates here.</StyledDescription>
        <StyledDivider />
        <ReservePriceSection vehicle={vehicle} includeDivider />
        <OfferHistory offerAuction={offerAuction} />
      </Grid>
    );
  }
  if (
    offerAuctionStatus === SELLER_VEHICLE_STATUS.PUBLIC_COUNTER_SENT_TO_BUYERS ||
    offerAuctionStatus === SELLER_VEHICLE_STATUS.PENDING_ADMIN_APPROVAL ||
    offerAuctionStatus === SELLER_VEHICLE_STATUS.ACCEPTED_OFFER_PENDING_ADMIN_APPROVAL ||
    offerAuctionStatus === SELLER_VEHICLE_STATUS.NEED_SELLER_REVIEW
  ) {
    return (
      <Grid container direction="column">
        <TimerWrapper container justify="space-between" alignItems="center">
          <SectionTitleWithStatus variant="title" statusColor={statusColor.yellow}>
            Bid Status - <span>{SELLER_VEHICLE_LABELS[offerAuctionStatus].label}</span>
          </SectionTitleWithStatus>
        </TimerWrapper>
        {offerAuctionStatus === SELLER_VEHICLE_STATUS.PUBLIC_COUNTER_SENT_TO_BUYERS ||
          (offerAuctionStatus === SELLER_VEHICLE_STATUS.NEED_SELLER_REVIEW && (
            <StyledDescription>The counter was sent to buyers. You will see updates here.</StyledDescription>
          ))}
        <StyledDivider />
        <TopOfferBlock offerAuction={offerAuction} vehicle={vehicle} />
        <ReservePriceSection vehicle={vehicle} includeDivider />
        <PublicOfferInfo offerAuction={offerAuction} />
        <div style={{ display: 'flex', gap: '8px', marginTop: '12px' }}>
          {offerAuctionStatus !== SELLER_VEHICLE_STATUS.ACCEPTED_OFFER_PENDING_ADMIN_APPROVAL && (
            <CounterButton onClick={() => dispatch(modalsToggle(`sendVehicleOffer-${vehicle.id}`))}>
              COUNTER
            </CounterButton>
          )}
          {offerAuctionStatus === SELLER_VEHICLE_STATUS.NEED_SELLER_REVIEW &&
            offerAuction?.status === OfferAuctionStatus.PUBLIC_OFFERS &&
            vehicle.highest_bid && (
              <>
                <ControlButton
                  autoDimensions
                  color="#225A91"
                  textColor="#fff"
                  onClick={() => dispatch(modalsToggle(`acceptTopOffer-${vehicle.id}`))}
                >
                  ACCEPT
                </ControlButton>
                <ConfirmModal
                  modalId={`acceptTopOffer-${vehicle.id}`}
                  title={
                    <>
                      You are about to accept the offer of{' '}
                      <NumberFormat displayType="text" prefix="$" value={highestBidOffer.amount} thousandSeparator />{' '}
                      from {highestBidOffer.bidder?.dealership_name ?? ''}
                    </>
                  }
                  handleSubmit={handleAcceptBid}
                />
              </>
            )}
        </div>

        <OfferHistory offerAuction={offerAuction} withTopDivider />
        <SendOfferModal maxAmount={vehicle.reserve_price} form={`sendVehicleOffer-${vehicle.id}`} vehicle={vehicle} />
      </Grid>
    );
  }
  if (offerAuctionStatus === SELLER_VEHICLE_STATUS.BUYER_COUNTER_SENT) {
    const lastOffer = offerAuction?.privateOffers?.[0];
    const timer = lastOffer ? moment(lastOffer.created_at).add(sellerResponseTime, 'milliseconds') : null;
    const colorsConfig = { active: true, stale: true, activeColor: '#AF2930' };
    return (
      <Grid container direction="column">
        <TimerWrapper container justify="space-between" alignItems="center">
          <SectionTitleWithStatus variant="title" statusColor={statusColor.yellow}>
            Bid Status - <span>Counter Sent to Seller</span>
          </SectionTitleWithStatus>
          {timer && <Timer withColors colorsConfig={colorsConfig} toDate={timer} />}
        </TimerWrapper>
        <StyledDivider />
        <ReservePriceSection vehicle={vehicle} includeDivider />
        <ActiveOfferInfo offerAuction={offerAuction} vehicle={vehicle} />
        <CounterControlsWrapper>
          {buttons.map(btn => (
            <ControlButton key={btn.title} color={btn.color} textColor={btn.textColor} onClick={btn.onClick}>
              {btn.title}
            </ControlButton>
          ))}
        </CounterControlsWrapper>
        <SendOfferModal maxAmount={vehicle.reserve_price} form={`sendVehicleOffer-${vehicle.id}`} vehicle={vehicle} />
        <AcceptOfferModal vehicleId={vehicle.id} />
        <OfferHistory offerAuction={offerAuction} />
      </Grid>
    );
  }
  return null;
};

export default connect(null, dispatch => ({
  toggleModal: modalId => dispatch(modalsToggle(modalId))
}))(StatusComponent);
