import { useFieldArray } from 'react-final-form-arrays';
import { SectionConfig } from '../../../../services/api/vehicle-sections/types';
import { useGetSectionConfigById } from '../../hooks/useGetSubSectionConfig';
import styled from 'styled-components';
import { CheckboxField } from './CheckboxField';

type Props = {
  sectionId: keyof SectionConfig;
};

const PredefinedContainer = styled.div<{ numberOfColumns: number }>`
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  gap: 12px;

  @media (min-width: 1024px) {
    display: grid;
    grid-template-columns: ${props => `repeat(${props.numberOfColumns}, 1fr)`};
    grid-template-rows: auto;
  }
`;

const UserAddedContainer = styled.div<{ numberOfColumns: number }>`
  display: flex;
  flex-direction: column;
  gap: 12px;

  @media (min-width: 1024px) {
    display: grid;
    grid-template-columns: ${props => `repeat(${props.numberOfColumns}, 1fr)`};
    grid-template-rows: auto;
  }
`;

const useCreateGridArea = (sectionId: keyof SectionConfig) => {
  const config = useGetSectionConfigById(sectionId);
  const gridIndexes = config?.gridCheckboxIndexes || [];

  return (i: number) => {
    const columnI = gridIndexes.findIndex(v => v.includes(i));
    const rowI = gridIndexes[columnI].findIndex(v => v === i);

    return `${rowI + 1} / ${columnI + 1} / ${rowI + 2} / ${columnI + 2}`;
  };
};

export const DesktopCheckboxGrid = ({ sectionId }: Props) => {
  const { fields } = useFieldArray(`${sectionId}.checkboxes`);
  const config = useGetSectionConfigById(sectionId);
  const predefinedAmount = config?.initialCheckboxFields?.length || 0;
  const gridIndexes = config?.gridCheckboxIndexes || [];

  const createGridArea = useCreateGridArea(sectionId);

  const names = fields.map(n => n);
  const predefined = names.slice(0, predefinedAmount);
  const userAdded = names.slice(predefinedAmount);
  const numberOfColumns = gridIndexes.length;

  return (
    <>
      <PredefinedContainer numberOfColumns={numberOfColumns}>
        {predefined.map((name, i) => {
          const gridArea = createGridArea(i);
          const isLast = userAdded.length === 0 && i === config?.lastGridElementIndex;
          return (
            <CheckboxField gridArea={gridArea} i={i} key={name} name={name} sectionId={sectionId} isLast={isLast} />
          );
        })}
        {userAdded.map((name, i) => {
          const realIndex = i + predefinedAmount;
          return (
            <CheckboxField
              i={realIndex}
              key={name}
              name={name}
              sectionId={sectionId}
              isLast={realIndex === fields.length! - 1}
            />
          );
        })}
      </PredefinedContainer>
      {/* {userAdded.length > 0 && (
        <UserAddedContainer numberOfColumns={numberOfColumns}>
          {userAdded.map((name, i) => {
            const realIndex = i + predefinedAmount;
            return (
              <CheckboxField
                i={realIndex}
                key={name}
                name={name}
                sectionId={sectionId}
                isLast={realIndex === fields.length! - 1}
              />
            );
          })}
        </UserAddedContainer>
      )} */}
    </>
  );
};
