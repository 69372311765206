import { Button, Grid, Typography } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import { SendOfferModal } from 'components/Offers';
import { Vehicle } from 'types/vehicle';
import { modalsToggle } from 'actions';
import { useDispatch } from 'react-redux';

const CounterSectionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  & > p {
    margin: 0;
  }
`;

const CounterButton = styled(Button)`
  background-color: #3994de;

  &:hover {
    background-color: #3994de;
  }
`;

const SectionTitle = styled(Typography)`
  font-size: 18px;
  color: #212121;
  font-weight: 500;
  margin-bottom: 16px;
`;

type Props = {
  text: string;
  vehicle: Vehicle;
};

export const CounterSection = ({ text, vehicle }: Props) => {
  const dispatch = useDispatch();
  const openModal = () => dispatch(modalsToggle(`sendVehicleOffer-${vehicle.id}`));

  return (
    <CounterSectionWrapper>
      <SendOfferModal maxAmount={vehicle.reserve_price} form={`sendVehicleOffer-${vehicle.id}`} vehicle={vehicle} />
      <SectionTitle>{text}</SectionTitle>
      <Grid item xs={12} sm={4}>
        <CounterButton onClick={openModal} color="primary" variant="contained" fullWidth disabled={false}>
          Counter
        </CounterButton>
      </Grid>
    </CounterSectionWrapper>
  );
};
