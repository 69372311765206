import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';

type ListerUploadVehicleState = {
  createDialogOpened: boolean;
  createInfoDialogOpened: boolean;
  activeNotesField: string | null;
  orientationPromptOpen: string | null;
  cameraWindowOpen: string | null;
  confirmImageUploadModalOpen: string | null;
}

const initialState: ListerUploadVehicleState = {
  createDialogOpened: false,
  createInfoDialogOpened: false,
  orientationPromptOpen: null,
  cameraWindowOpen: null,
  activeNotesField: null,
  confirmImageUploadModalOpen: null
};

const listerUploadVehicleSlice = createSlice({
  name: 'listerUploadVehicle',
  initialState,
  reducers: {
    openListerCreateDialog: (state) => {
      state.createDialogOpened = true
    },
    closeListerCreateDialog: (state) => {
      state.createDialogOpened = false
    },
    openListerCreateInfoDialog: (state) => {
      state.createInfoDialogOpened = true
    },
    closeListerCreateInfoDialog: (state) => {
      state.createInfoDialogOpened = false
    },
    openOrientationPrompt: (state, action: PayloadAction<string>) => {
      state.orientationPromptOpen = action.payload
    },
    closeOrientationPrompt: (state) => {
      state.orientationPromptOpen = null
    },
    openCamera: (state, action: PayloadAction<string>) => {
      state.cameraWindowOpen = action.payload
    },
    closeCamera: (state) => {
      state.cameraWindowOpen = null
    },
    openConfirmImageUploadModal: (state, action: PayloadAction<string>) => {
      state.confirmImageUploadModalOpen = action.payload
    },
    closeConfirmImageUploadModal: (state) => {
      state.confirmImageUploadModalOpen = null
    },
    openNotesField: (state, action: PayloadAction<string>) => {
      state.activeNotesField = action.payload;
    },
    closeNotesField: (state) => {
      state.activeNotesField = null;
    }
  }
});

export const useListerCreateDialogOpened = () => {
  const isOpen = useSelector<{ listerUploadVehicle: ListerUploadVehicleState }, boolean>((state) => state.listerUploadVehicle.createDialogOpened)
  return isOpen
}

export const useListerCreateInfoDialogOpened = () => {
  const isOpen = useSelector<{ listerUploadVehicle: ListerUploadVehicleState }, boolean>((state) => state.listerUploadVehicle.createInfoDialogOpened)
  return isOpen
}

export const useOrientationPromptOpen = () => {
  const isOpen = useSelector<{ listerUploadVehicle: ListerUploadVehicleState }, string | null>((state) => state.listerUploadVehicle.orientationPromptOpen)
  return isOpen
}

export const useCameraOpened = () => {
  const isOpen = useSelector<{ listerUploadVehicle: ListerUploadVehicleState }, string | null>((state) => state.listerUploadVehicle.cameraWindowOpen)
  return isOpen
}

export const useConfirmImageUploadOpen = () => {
  const isOpen = useSelector<{ listerUploadVehicle: ListerUploadVehicleState }, string | null>((state) => state.listerUploadVehicle.confirmImageUploadModalOpen)
  return isOpen
}

export const useActiveNotesField = () => {
  const isOpen = useSelector<{ listerUploadVehicle: ListerUploadVehicleState }, string | null>((state) => state.listerUploadVehicle.activeNotesField)
  return isOpen
}

export const {
    openListerCreateDialog,
    closeListerCreateDialog,
    openListerCreateInfoDialog,
    closeListerCreateInfoDialog,
    openOrientationPrompt,
    closeOrientationPrompt,
    openCamera,
    closeCamera,
    openConfirmImageUploadModal,
    closeConfirmImageUploadModal,
    openNotesField,
    closeNotesField
} = listerUploadVehicleSlice.actions

export default listerUploadVehicleSlice;
