import styled from 'styled-components';
import { useTimeout } from 'usehooks-ts';
import { RotateDeviceIcon } from '../icons/RotateDevice';
import { useDispatch } from 'react-redux';
import {
  closeOrientationPrompt,
  openCamera,
  useOrientationPromptOpen
} from '../../../services/api/listerUploadVehicle';
import { useScreenOrientation } from '../../../hooks';
import { useEffect } from 'react';

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  background: #000;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  rotate: 90deg;
`;

const RotateIconWrapper = styled.div`
  border-radius: 100%;
  background: rgba(59, 59, 59, 1);
  width: 123px;
  height: 123px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const HelperText = styled.span`
  color: rgba(153, 153, 153, 1);
  font-size: 14px;
  font-weight: 400;
  padding: 0 50px;
  text-align: center;
  line-height: 19px;
`;

const DoNotShowAgainButton = styled.div`
  background: rgba(59, 59, 59, 1);
  border-radius: 8px;
  padding: 8px 24px;
  color: rgba(153, 153, 153, 1);
  font-size: 16px;
  font-weight: 500;
  line-height: 18.75px;
`;

export const ToggleOrientationPrompt = () => {
  const dispatch = useDispatch();

  const sectionName = useOrientationPromptOpen();

  const close = () => {
    dispatch(closeOrientationPrompt());
    dispatch(openCamera(sectionName!));
  };

  const screenOrientation = useScreenOrientation();

  useEffect(() => {
    if (screenOrientation.startsWith('landscape')) {
      close();
    }
  }, [screenOrientation]);

  useTimeout(() => {
    close();
  }, 3000);

  return (
    <Wrapper>
      <ContentWrapper>
        <RotateIconWrapper>
          <RotateDeviceIcon />
        </RotateIconWrapper>
        <HelperText>Please rotate your device to landscape mode to capture the best photo.</HelperText>
        <DoNotShowAgainButton>Don't show it again</DoNotShowAgainButton>
      </ContentWrapper>
    </Wrapper>
  );
};
