import { useState, useEffect } from 'react';

export const useScreenOrientation = () => {
  const [orientation, setOrientation] = useState(window.screen.orientation.type);

  const handleOrientationChange = () => setOrientation(window.screen.orientation.type);

  useEffect(() => {
    window.addEventListener('orientationchange', handleOrientationChange);
    return () => window.removeEventListener('orientationchange', handleOrientationChange);
  }, []);

  return orientation;
};
