import { Grid, Divider } from '@material-ui/core';
import moment from 'moment';
import { ADMIN_VEHICLE_STATUS, VEHICLE_STATUSES } from 'constants';
import AwardBuyerBlock from '../../RoleSpecificComponents/Admin/AwardedBlock/AwardBuyerBlock';
import { PullVehicleControls } from '../../RoleSpecificComponents/Admin/PullVehicleControls';
import { AcceptHighestBid } from '../../RoleSpecificComponents/Admin/AcceptHighestBid';
import AwardedBlock from '../../RoleSpecificComponents/Admin/AwardedBlock';
import styled from 'styled-components';
import { useNoSelectorVehicleOfferAuctionById } from 'hooks';
import { auctionOfferStatuses } from 'utils/statusCalculator';
import { OfferAuctionStatus } from 'types/vehicle';
import { useViewedVehicle } from '../../../hooks/useViewedVehicle';
import { useCurrentUser } from '../../../../../../hooks/selectors';
import { HighestBid } from '../../RoleSpecificComponents/Admin/HighestBid';
import SellerAcceptedPublicOfferBlock from '../../../../../Shared/VehicleDetails/AdminControlsBlock/PublicOffersBlock/SellerAcceptedPublicOfferBlock';
import PublicOfferBlock from '../../../../../Shared/VehicleDetails/AdminControlsBlock/PublicOffersBlock';
import AdminTopOfferBlock from '../../../../../Shared/VehicleDetails/AdminControlsBlock/TopOfferBlock';
import { StopNegotiationBlock } from '../../../../../Shared/VehicleDetails/AdminControlsBlock/StopNegotiationBLock';
import ReactivateControls from '../../RoleSpecificComponents/Admin/PullVehicleControls/ReactivateBlock';
import CounterBid from '../../RoleSpecificComponents/Admin/CounterBid';
import { ReservePriceSection } from '../../../../../Shared/VehicleDetails/components';

const StyledDivider = styled(Divider)`
  && {
    margin: 24px 0;
  }
`;

const AdminActiveOffers = () => {
  const vehicle = useViewedVehicle();
  const offerAuction = useNoSelectorVehicleOfferAuctionById(vehicle?.id || 0);
  const user = useCurrentUser();

  const status = auctionOfferStatuses(vehicle, offerAuction, user);

  if (!vehicle) return null;

  const currentTime = moment.utc();
  const isVehicleTimerEnded = vehicle.date_end && moment.utc(vehicle.date_end).isBefore(currentTime);

  if (status === ADMIN_VEHICLE_STATUS.SELLER_ACCEPTED) {
    return (
      <Grid container>
        <PullVehicleControls />
        {vehicle.buyer_id ? <AcceptHighestBid /> : <HighestBid />}

        <SellerAcceptedPublicOfferBlock offerAuction={offerAuction} vehicle={vehicle} />
        <AwardBuyerBlock />
      </Grid>
    );
  }

  if (
    status === ADMIN_VEHICLE_STATUS.SELLER_COUNTERED ||
    status === ADMIN_VEHICLE_STATUS.BUYER_NOT_COUNTERED ||
    status === ADMIN_VEHICLE_STATUS.PUBLIC_ADMIN_COUNTER_SENT ||
    status === ADMIN_VEHICLE_STATUS.SELLER_NOT_COUNTERED
  ) {
    return (
      <Grid container>
        <PullVehicleControls />
        <HighestBid />
        <ReservePriceSection vehicle={vehicle} />
        <Grid item xs={12}>
          <StyledDivider />
        </Grid>
        <PublicOfferBlock offerAuction={offerAuction} vehicle={vehicle} />
        <AwardBuyerBlock />
      </Grid>
    );
  }

  return (
    <Grid container>
      {![VEHICLE_STATUSES.AWARDED, VEHICLE_STATUSES.DEACTIVATED].includes(vehicle.status) && <PullVehicleControls />}
      {[
        ADMIN_VEHICLE_STATUS.BUYER_COUNTER_SENT,
        ADMIN_VEHICLE_STATUS.SELLER_COUNTER_SENT,
        ADMIN_VEHICLE_STATUS.ADMIN_COUNTER_SENT
      ].includes(status) &&
        offerAuction?.status === OfferAuctionStatus.PRIVATE_BIDS &&
        offerAuction?.privateOffers?.length > 0 && <StopNegotiationBlock vehicle={vehicle} />}
      {![VEHICLE_STATUSES.PULLED, VEHICLE_STATUSES.AWARDED, VEHICLE_STATUSES.DEACTIVATED].includes(vehicle.status) &&
        isVehicleTimerEnded &&
        !vehicle.buyer_id && <HighestBid />}
      <ReservePriceSection vehicle={vehicle} />
      <Grid item xs={12}>
        <StyledDivider />
      </Grid>
      {![VEHICLE_STATUSES.PULLED, VEHICLE_STATUSES.AWARDED, VEHICLE_STATUSES.DEACTIVATED].includes(vehicle.status) &&
        isVehicleTimerEnded &&
        vehicle.buyer_id && <AcceptHighestBid />}
      {vehicle.status === VEHICLE_STATUSES.ACTIVE && <AdminTopOfferBlock offerAuction={offerAuction} />}
      {![VEHICLE_STATUSES.PULLED, VEHICLE_STATUSES.AWARDED, VEHICLE_STATUSES.DEACTIVATED].includes(vehicle.status) &&
        isVehicleTimerEnded && <CounterBid />}
      {![VEHICLE_STATUSES.AWARDED, VEHICLE_STATUSES.DEACTIVATED].includes(vehicle.status) && <AwardBuyerBlock />}
      {vehicle.status === VEHICLE_STATUSES.AWARDED && <AwardedBlock />}
      {vehicle.status === VEHICLE_STATUSES.DEACTIVATED && <ReactivateControls />}
    </Grid>
  );
};

export default AdminActiveOffers;
