import ReactImageGallery from 'react-image-gallery';
import { RenderLeftNav, RenderRightNav } from '../nav';
import { FullscreenButton } from '../fullscreen';
import { Dialog } from '@material-ui/core';
import styled from 'styled-components';
import {
  closeFullScreen,
  useImages,
  useInitialFullScreenIndex,
  useOpenedFullScreenImageDialog
} from '../../../../../../../services/viewVehicleSlice/viewVehicleSlice';
import { useDispatch } from 'react-redux';

const StyledDialog = styled(Dialog)`
  .fullscreen-image-gallery {
    max-height: unset;
    width: 900px;
    border-radius: 30px;
    max-width: unset;
    padding: 0;
  }

  @media (max-width: 1024px) {
    .fullscreen-image-gallery {
      width: 100%;
      margin: 0 20px;
    }
  }
`;

type Props = {
  imageGalleryRef: React.RefObject<ReactImageGallery>;
};

export const FullScreenCarouselDialog = ({ imageGalleryRef }: Props) => {
  const fullScreenImageModalOpen = useOpenedFullScreenImageDialog();
  const initialFullScreenIndex = useInitialFullScreenIndex();

  const dispatch = useDispatch();

  const close = () => dispatch(closeFullScreen());

  return (
    <StyledDialog
      classes={{ paperScrollPaper: 'fullscreen-image-gallery' }}
      open={fullScreenImageModalOpen}
      onBackdropClick={close}
      onClose={close}
    >
      {fullScreenImageModalOpen && initialFullScreenIndex != null && (
        <FullScreenCarousel imageGalleryRef={imageGalleryRef} startIndex={initialFullScreenIndex} />
      )}
    </StyledDialog>
  );
};

const FullScreenCarousel = ({ imageGalleryRef, startIndex }: Props & { startIndex: number }) => {
  const dispatch = useDispatch();
  const close = () => dispatch(closeFullScreen());

  const images = useImages();

  return (
    <ReactImageGallery
      showPlayButton={false}
      showFullscreenButton={true}
      renderLeftNav={(onclick, disabled) => <RenderLeftNav isFullScreen onClick={onclick} disabled={disabled} />}
      renderRightNav={(onclick, disabled) => <RenderRightNav isFullScreen onClick={onclick} disabled={disabled} />}
      renderFullscreenButton={(onclick, isFullScreen) => (
        <FullscreenButton type="fullscreen" onClick={close} isFullscreen={isFullScreen} />
      )}
      items={images.map(i => ({ original: i.url, thumbnail: i.url_thumb, description: i.notes || '' }))}
      showIndex
      additionalClass="fullscreen"
      startIndex={startIndex}
      useBrowserFullscreen={false}
      showThumbnails={false}
      onSlide={newI => {
        if (imageGalleryRef.current) {
          imageGalleryRef.current.slideToIndex(newI);
        }
      }}
      infinite
    />
  );
};
