import styled from 'styled-components';
import { SectionConfig } from '../../../services/api/vehicle-sections/types';
import { useGetSubSectionConfig } from '../../CreateVehicle/hooks/useGetSubSectionConfig';
import { useState } from 'react';
import { CollapseIcon } from '../../../components/Icons/CollapseIcon';
import { ExpandIcon } from '../../../components/Icons/ExpandIcon';
import { DragIndicator } from '@material-ui/icons';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

const Wrapper = styled.div<{ isDragging: boolean }>`
  background: #fff;
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 9px 8px;
  width: 100%;
  opacity: ${props => (props.isDragging ? '0.6' : 1)};
`;

const IconWrapper = styled.div`
  display: flex;
`;

type Props = {
  sectionId: keyof SectionConfig;
  subSectionId: string;
};

const Title = styled.div`
  font-size: 14px;
  font-weight: 600;
  line-height: 16.41px;
  color: #000;
`;

const RequiredSign = styled.span`
  color: rgba(175, 41, 48, 1);
`;

export const CollapsibleSubSection = ({ sectionId, subSectionId }: Props) => {
  const config = useGetSubSectionConfig(sectionId, subSectionId);
  const [isExpanded, setIsExpanded] = useState(false);

  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: `${sectionId}.${subSectionId}`
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  };

  if (!config) return null;

  return (
    <Wrapper isDragging={isDragging} ref={setNodeRef} style={style} {...attributes} {...listeners}>
      <IconWrapper>{isExpanded ? <CollapseIcon /> : <ExpandIcon fillColor="rgba(153, 153, 153, 1)" />}</IconWrapper>
      <Title>
        {config.label}
        {config.required ? <RequiredSign>*</RequiredSign> : null}
      </Title>
      <IconWrapper
        id={`activator-${sectionId}-${subSectionId}`}
        style={{ color: 'rgba(204, 204, 204, 1)', marginLeft: 'auto' }}
      >
        <DragIndicator />
      </IconWrapper>
    </Wrapper>
  );
};
