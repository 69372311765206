import styled from 'styled-components';
import { SectionConfig } from '../../../services/api/vehicle-sections/types';
import { useGetSectionConfigById } from '../../CreateVehicle/hooks/useGetSubSectionConfig';
import { ExpandIcon } from '../../../components/Icons/ExpandIcon';
import { CameraIcon } from '../icons/Camera';
import { DragIndicator } from '../../CreateVehicle/icons/DragIndicator';

const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Header = styled.div<{ isExpanded: boolean }>`
  display: flex;
  align-items: center;
  gap: 8px;
  border-bottom-left-radius: ${props => (props.isExpanded ? '0' : '8px')};
  border-bottom-right-radius: ${props => (props.isExpanded ? '0' : '8px')};
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background: rgba(34, 90, 145, 1);
  padding: 8px 16px 8px 8px;

  & > span {
    font-size: 16px;
    font-weight: 700;
    line-height: 18.75px;
    color: #fff;
  }
`;

const IconWrapper = styled.div<{ floatLeft?: boolean }>`
  display: flex;
  color: #fff;
  margin-left: ${props => (props.floatLeft ? 'auto' : 0)};
`;

type Props = {
  sectionId: string;
};

export const DraggableSectionOverlay = ({ sectionId }: Props) => {
  const config = useGetSectionConfigById(sectionId as keyof SectionConfig);

  const isCheckboxSection = sectionId === 'title_issues' || sectionId === 'power_equipment';

  if (!config) return null;

  return (
    <SectionWrapper>
      <Header isExpanded={false}>
        <IconWrapper>
          <ExpandIcon fillColor="#fff" />
        </IconWrapper>
        <span>{config?.label}</span>
        {!isCheckboxSection && (
          <IconWrapper floatLeft>
            <CameraIcon />
          </IconWrapper>
        )}
        <IconWrapper style={{ color: '#fff' }} floatLeft={isCheckboxSection} id={`activator-${sectionId}`}>
          <DragIndicator />
        </IconWrapper>
      </Header>
    </SectionWrapper>
  );
};
