import styled from 'styled-components';
import { VehicleSection } from '../../../../../../../types/vehicle';
import { useEffect, useMemo, useState } from 'react';
import { ExpandIcon } from '../../../../../../../components/Icons/ExpandIcon';
import { CollapseIcon } from '../../../../../../../components/Icons/CollapseIcon';
import { useGetSubSectionConfig } from '../../../../../../CreateVehicle/hooks/useGetSubSectionConfig';
import { gradeColorMap } from '../../../../../../CreateVehicle/components/Grades';
import { SubSectionImages } from './SubSectionImages';
import { SubSectionNotes } from './SubSectionNotes';
import { Collapse } from '@material-ui/core';

type Props = {
  item: VehicleSection;
};

const Wrapper = styled.div`
  background: rgba(249, 249, 249, 1);
  border-radius: 8px;
  width: 100%;
  padding: 12px 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Title = styled.div`
  font-size: 18px;
  font-weight: 400;
  line-height: 21px;
  color: #000;

  @media (max-width: 1024px) {
    font-size: 14px;
    line-height: 16.41px;
  }
`;

const Header = styled.div`
  display: flex;
  gap: 22px;
  align-items: center;

  @media (max-width: 1024px) {
    gap: 8px;
  }
`;

const GradeLabel = styled.div<{ color: string }>`
  border-radius: 15px;
  font-size: 14px;
  line-height: 16.5px;
  padding: 5px 8px;
  color: #fff;
  background: ${props => props.color};

  @media (max-width: 1024px) {
    font-size: 10px;
    line-height: 11.72px;
  }
`;

const CollapseIconWrapper = styled.div<{ renderingNotes?: boolean }>`
  cursor: pointer;
  margin-left: ${props => (props.renderingNotes ? '0' : 'auto')};
`;

const NotesWrapper = styled.div`
  margin-left: auto;
`;

export const SubSection = ({ item }: Props) => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  const config = useGetSubSectionConfig(item.section.name, item.section.sub_section_name);

  const toggleCollapse = () => {
    if (item.images.length === 0 && !item.notes) {
      return;
    }
    setIsCollapsed(c => !c);
  };

  const renderingNotes = isCollapsed && !!item.notes;

  const canBeCollapsed = useMemo(() => item.images.filter(i => !!i.url).length > 0, [item.images]);

  useEffect(() => {
    if (!canBeCollapsed) {
      setIsCollapsed(true);
    }
  }, [canBeCollapsed]);

  return (
    <Wrapper>
      <Header>
        <Title>{config?.label}</Title>
        {item.grade && <GradeLabel color={gradeColorMap[item.grade.color]}>{item.grade.label}</GradeLabel>}
        {renderingNotes && (
          <NotesWrapper>
            <SubSectionNotes notes={item.notes!} />
          </NotesWrapper>
        )}
        {canBeCollapsed && (
          <CollapseIconWrapper renderingNotes={renderingNotes} onClick={toggleCollapse}>
            {isCollapsed ? <ExpandIcon /> : <CollapseIcon />}
          </CollapseIconWrapper>
        )}
      </Header>
      <>
        {!isCollapsed && item.images.length > 0 && <SubSectionImages images={item.images.filter(i => !!i.url)} />}
        {!isCollapsed && item.notes && <SubSectionNotes fullWidth notes={item.notes} />}
      </>
    </Wrapper>
  );
};
