import { GradesField } from '../Fields/GradesField';
import { NotesField } from '../Fields/NotesField';

type Props = {
  sectionName: string;
};

export const NotesView = ({ sectionName }: Props) => {
  return (
    <>
      <NotesField sectionName={sectionName} />
      <GradesField sectionName={sectionName} />
    </>
  );
};
