import { useGetVehicleByIdQuery } from '../../../../../services/api/upload-vehicle';
import { useGetVehicleId } from '../../../../CreateVehicle/hooks/useGetVehicleId';
import ImageGallery from 'react-image-gallery';
import './index.scss';
import styled from 'styled-components';
import { RenderLeftNav, RenderRightNav } from './components/nav';
import Slider from 'react-slick';
import { useRef } from 'react';
import { PlayImageIcon } from '../../icons/PlayImageIcon';
import {
  openFullScreenWithId,
  useImages
} from '../../../../../services/viewVehicleSlice/viewVehicleSlice';
import { useDispatch } from 'react-redux';
import ReactImageGallery from 'react-image-gallery';
import { useCurrentUser } from '../../../../../hooks/selectors';
import { BiddingLabel } from './components/BiddingLabel';

const Wrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  gap: 16px;
  max-width: 560px;
`;

const SliderImage = styled.div<{ showNextButton?: boolean }>`
  border-radius: 30px;
  position: relative;

  & > img {
    border: 1px solid rgba(204, 204, 204, 1);
    border-radius: 30px;
    width: calc(100% - 6px) !important;
    height: 100% !important;
    min-height: 133px;
    opacity: ${props => (props.showNextButton ? '0.6' : '1')};
  }

  @media (max-width: 1024px) {
    & > img {
      min-height: unset;
      aspect-ratio: 1;
      width: 100%;
      border-radius: 20px;
    }
  }
`;

// @media (min-width: 1800px) {
//   & > img {
//     min-height: 184px;
//   }
// }

const PlayImageWrapper = styled.div`
  position: absolute;
  top: 50%;
  cursor: pointer;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const SliderWrapper = styled.div`
  & * .slick-list {
  }
  & * .slick-slide > div {
  }
`;

const BidInfoLabel = styled.div<{ isHighest: boolean }>`
  position: absolute;
  left: 12px;
  top: 10px;
  padding: 5px 27px;
  background: ${props => (props.isHighest ? 'rgba(71, 151, 71, 1)' : 'rgba(175, 41, 48, 1)')};
  color: #fff;
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  border-radius: 20px;

  @media (max-width: 1024px) {
    font-size: 10px;
    line-height: 12px;
    padding: 6px 24px;
  }
`;

const getFillSliderImages = (length: number) => {
  const remainder = length % 4;
  if (remainder === 0) return [];
  return Array(4 - remainder).fill(0);
};

type Props = {
  imageGalleryRef: React.RefObject<ReactImageGallery>;
};

export const ImageCarousel = ({ imageGalleryRef }: Props) => {
  const vehicleId = useGetVehicleId();
  const { data: vehicle } = useGetVehicleByIdQuery(vehicleId, { skip: !vehicleId });

  const currentUser = useCurrentUser();

  const sliderRef = useRef<Slider>(null);

  const dispatch = useDispatch();

  const images = useImages();

  if (!vehicle) return null;

  const sliderSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4
  };

  const handleSlickSlide = (i: number) => {
    if (!sliderRef.current) return;
    sliderRef.current.slickNext();
  };

  const fillArray = getFillSliderImages(images.length);

  return (
    <Wrapper>
      {images.length > 0 && (
        <ImageGallery
          ref={imageGalleryRef}
          showPlayButton={false}
          showFullscreenButton={false}
          renderLeftNav={(onclick, disabled) => <RenderLeftNav onClick={onclick} disabled={disabled} />}
          renderRightNav={(onclick, disabled) => <RenderRightNav onClick={onclick} disabled={disabled} />}
          items={images.map(i => ({ original: i.url, thumbnail: i.url_thumb, description: i.notes || '' }))}
          showIndex
          useBrowserFullscreen={false}
          showThumbnails={false}
          infinite={true}
        />
      )}
      {currentUser.role === 'buyer' && <BiddingLabel vehicle={vehicle} />}
      <SliderWrapper>
        <Slider {...sliderSettings} ref={sliderRef}>
          {images.map((img, i) => {
            const showNextButton = (i + 1) % 4 === 0 && i !== images.length - 1;
            const handleClick = () => {
              if (showNextButton) {
                return;
              }

              dispatch(openFullScreenWithId(img.id));
            };
            return (
              <SliderImage onClick={handleClick} showNextButton={showNextButton} key={img.id}>
                <img src={img.url} alt={img.id.toString()} />
                {showNextButton && (
                  <PlayImageWrapper onClick={() => handleSlickSlide(i)}>
                    <PlayImageIcon />
                  </PlayImageWrapper>
                )}
              </SliderImage>
            );
          })}
          {fillArray.map(() => (
            <SliderImage />
          ))}
        </Slider>
      </SliderWrapper>
    </Wrapper>
  );
};
