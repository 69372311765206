import { useMemo } from 'react';
import { useGetSectionsOrderQuery } from '../../../services/api/vehicle-sections';
import styled from 'styled-components';
import { CollapsibleSection } from './CollapsibleSection';

import { DndContext, DragOverlay, closestCorners, useSensor, useSensors } from '@dnd-kit/core';
import { DragIconMouseSensor, DragIconTouchSensor } from '../../../services/dndkit/sensors';
import { useSectionDragEnd, useSectionDragStart } from '../../../services/dndkit/useSectionDragEvents';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { DraggableSectionOverlay } from '../overlay/DraggableSectionOverlay';
import { useMovedSectionId } from '../../../services/dndkit/sectionDragSlice';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const CollapsibleSections = () => {
  const { data: order } = useGetSectionsOrderQuery();

  const sectionIds = useMemo(() => order?.map(o => o[0]) || [], [order]);

  const touchSensor = useSensor(DragIconTouchSensor, {
    activationConstraint: {
      delay: 300,
      tolerance: 8
    }
  });

  const mouseSensor = useSensor(DragIconMouseSensor, {
    activationConstraint: {
      distance: 8
    }
  });

  const onDragStart = useSectionDragStart();
  const onDragEnd = useSectionDragEnd();

  const sensors = useSensors(mouseSensor, touchSensor);

  const movedSection = useMovedSectionId();

  return (
    <DndContext collisionDetection={closestCorners} sensors={sensors} onDragEnd={onDragEnd} onDragStart={onDragStart}>
      <SortableContext strategy={verticalListSortingStrategy} items={sectionIds} id="outer-sortable">
        <Wrapper>
          {sectionIds.map(sectionId => {
            if (sectionId === 'dealer_comments') {
              return null;
            }

            return <CollapsibleSection sectionId={sectionId} key={sectionId} />;
          })}
        </Wrapper>
      </SortableContext>
      <DragOverlay>{movedSection && <DraggableSectionOverlay sectionId={movedSection} />}</DragOverlay>
    </DndContext>
  );
};
