import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { GradesComponent } from '../Grades';
import { SubSection } from 'services/api/vehicle-sections/types/common';
import { PhotoUploadComponent } from './PhotoUpload/PhotoUpload';
import { HeaderComponent } from './Header';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { useDndContext } from '@dnd-kit/core';
import { Footer } from './Footer';
import { AdditionalGradeSections } from './AdditionalGradeSections';
import { Field, FormSpy } from 'react-final-form';
import { ImageField } from '../../../../services/api/upload-vehicle';
import { SoundPlayer } from './SoundPlayer';
import { SoundRecordingProvider } from '../../context/SoundRecordingContext';
import { RecordingPlayer } from './SoundRecorder/RecordingPlayer';
import { useIsSubsectionVisible } from '../../hooks/useIsSubsectionVisible';
import { useFieldArray } from 'react-final-form-arrays';

const Wrapper = styled.div<{ isCollapsed?: boolean; hasError?: boolean }>`
  border: ${props => (props.hasError ? '1px solid rgba(175, 41, 48, 1)' : '1px solid rgba(217, 217, 217, 1)')};
  background: ${props => (props.hasError ? 'rgba(175, 41, 48, 0.05)' : 'rgba(217, 217, 217, 0.2)')};
  position: relative;
  border-radius: 8px;
  padding: ${props => (props.isCollapsed ? '16px 8px' : '16px 8px 8px 8px')};
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const createSectionValidator = (subSectionConfig: SubSection) => (values: any) => {
  if (!subSectionConfig.required) return;
  const hasEmptyImageField = values.images.some((image: ImageField) => {
    return image.type !== 'user_defined' && !image.url;
  });
  if (hasEmptyImageField) return { error: subSectionConfig.label };
  return;
};

type Props = {
  subSectionConfig: SubSection;
  name: string;
};

export const CollapsibleSubSection = ({ subSectionConfig, name }: Props) => {
  const [isCollapsed, setIsCollapsed] = useState(!subSectionConfig?.required);
  const toggleCollapse = () => setIsCollapsed(c => !c);

  const { fields } = useFieldArray(`${name}.images`, { subscription: { length: true } });

  useEffect(() => {
    if (fields.length && fields.length > 0) {
      setIsCollapsed(false);
    }
  }, [fields.length]);

  const isSubSectionVisible = useIsSubsectionVisible(subSectionConfig);

  const dndContext = useDndContext();

  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: name });

  const sectionValidator = createSectionValidator(subSectionConfig);

  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  };

  if (!isSubSectionVisible) {
    return null;
  }

  if (isCollapsed || dndContext.active) {
    return (
      <SoundRecordingProvider subSectionConfig={subSectionConfig} name={name}>
        <Wrapper isCollapsed ref={setNodeRef} style={style} {...attributes} {...listeners}>
          <HeaderComponent
            name={name}
            isCollapsed={isCollapsed || !!dndContext.active}
            isDragging={!!dndContext.active}
            toggleCollapse={toggleCollapse}
            subSectionConfig={subSectionConfig}
          />
          {subSectionConfig.possibleGrades && <GradesComponent name={name} grades={subSectionConfig.possibleGrades} />}
        </Wrapper>
      </SoundRecordingProvider>
    );
  }

  return (
    <FormSpy subscription={{ submitFailed: true }}>
      {({ submitFailed }) => {
        return (
          <Field subscription={{ value: true, error: true }} name={name} validate={sectionValidator}>
            {({ meta, input: { value } }) => (
              <SoundRecordingProvider subSectionConfig={subSectionConfig} name={name}>
                <Wrapper
                  hasError={submitFailed && !!meta.error}
                  ref={setNodeRef}
                  style={style}
                  {...attributes}
                  {...listeners}
                >
                  <HeaderComponent
                    isCollapsed={isCollapsed}
                    toggleCollapse={toggleCollapse}
                    name={name}
                    subSectionConfig={subSectionConfig}
                  />
                  {subSectionConfig.possibleGrades && (
                    <GradesComponent name={name} grades={subSectionConfig.possibleGrades} />
                  )}
                  <PhotoUploadComponent name={name} subSectionConfig={subSectionConfig} />
                  {subSectionConfig.additionalSections && (
                    <AdditionalGradeSections
                      sectionId={name.split('.')[0]}
                      additionalSubSections={subSectionConfig.additionalSections}
                    />
                  )}
                  <Footer name={name} subSectionConfig={subSectionConfig} />
                  <RecordingPlayer />
                  {value.audio_url && <SoundPlayer name={name} />}
                </Wrapper>
              </SoundRecordingProvider>
            )}
          </Field>
        );
      }}
    </FormSpy>
  );
};
