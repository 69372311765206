import styled from 'styled-components';
import Webcam from 'react-webcam';
import { ImageUploadContextProvider, useImageUploadContext } from '../../context/ImageUploadContext';
import { SectionConfig } from '../../../../services/api/vehicle-sections/types';
import { useCameraOpened } from '../../../../services/api/listerUploadVehicle';
import { TakePhotoView } from './views/TakePhotoView';
import { ConfirmPhotoView } from './views/ConfirmPhotoView';
import { useWindowSize } from 'usehooks-ts';

const Wrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 8888;
  top: 0;
  left: 0;
  background: #000;
`;

export const CameraComponent = () => {
  const openedSectionId = useCameraOpened();
  const [sectionId, subSectionId] = (openedSectionId || '').split('.');

  return (
    <ImageUploadContextProvider sectionId={sectionId as keyof SectionConfig} subSectionId={subSectionId}>
      {!!openedSectionId && <InnerContent />}
    </ImageUploadContextProvider>
  );
};

const InnerContent = () => {
  const { state, cameraRef } = useImageUploadContext();
  const { width = 0, height = 0 } = useWindowSize();

  const videoConstraints = {
    facingMode: 'environment',
    aspectRatio: width > height ? width / height : height / width
  };

  return (
    <Wrapper id="camera-wrapper">
      <Webcam
        audio={false}
        ref={cameraRef}
        id="camera"
        screenshotQuality={1}
        screenshotFormat={'image/jpeg'}
        hidden={state !== 'taking_photo'}
        style={{ width: '100%', height: '100%' }}
        videoConstraints={videoConstraints}
      />
      {state === 'taking_photo' ? <TakePhotoView /> : <ConfirmPhotoView />}
    </Wrapper>
  );
};
