import { useDispatch } from 'react-redux';
import { Divider, Button } from '@material-ui/core';
import styled from 'styled-components';
import moment from 'moment';
import { pullVehicle, sellerAcceptBid, modalsToggle } from 'actions';
import { VEHICLE_STATUSES, AUCTION_STATUSES, SELLER_VEHICLE_STATUS } from 'constants';
import ConfirmModal from 'components/Modals/ConfirmModal';
import { useNoSelectorVehicleOfferAuctionById } from 'hooks';
import { auctionOfferStatuses } from 'utils/statusCalculator';
import { OfferAuctionStatus } from 'types/vehicle';
import { useViewedVehicle } from '../../../hooks/useViewedVehicle';
import { useCurrentUser } from '../../../../../../hooks/selectors';
import StatusComponent from '../../../../../Shared/VehicleDetails/RoleRestrictedSection/SellerRestrictedSection/StatusComponent';
import CounterComponent from '../../../../../Shared/VehicleDetails/RoleRestrictedSection/SellerRestrictedSection/CounterComponent';

const StyledDivider = styled(Divider)`
  && {
    margin: 24px 0;
  }
`;

const StyledButton = styled(Button)`
  && {
    color: #e74549;
    text-decoration: underline;
    text-transform: initial;
    padding: 0;
    min-height: auto;
  }
`;

const confirmPullModal = 'confirmPullModal';

export const SellerActiveOffer = () => {
  const currentVehicle = useViewedVehicle();
  const currentUser = useCurrentUser();
  const offerAuction = useNoSelectorVehicleOfferAuctionById(currentVehicle?.id || 0);

  const dispatch = useDispatch();

  if (!currentVehicle) return null;

  const openPullModal = () => {
    dispatch(modalsToggle(confirmPullModal));
  };

  let blockToRender = null;
  const highestBid = currentVehicle.bids.reduce(
    (acc: any, bid: any) => {
      if (bid.amount >= acc.amount && bid.id > acc.id) {
        return bid;
      }
      return acc;
    },
    { amount: 0, id: 0 }
  );

  const isSellerCreatedVehicle = currentVehicle.user_id === currentUser.id;

  const vehicleOfferAuctionStatus = auctionOfferStatuses(currentVehicle, offerAuction, currentUser);

  const currentTime = moment.utc();
  const isVehicleTimerEnded =
    currentVehicle.end_time_spacing !== null &&
    moment(currentVehicle.auction.date_end)
      .utc()
      .add(currentVehicle.end_time_spacing, 'seconds')
      .isBefore(currentTime);

  const acceptBid = () => dispatch(sellerAcceptBid({ vehicle_id: currentVehicle.id }).request);

  if (!isSellerCreatedVehicle) {
    return null;
  }
  if (
    currentVehicle.status === VEHICLE_STATUSES.ACTIVE &&
    currentVehicle.auction.status === AUCTION_STATUSES.ACTIVE &&
    !isVehicleTimerEnded
  ) {
    blockToRender = <StyledButton onClick={openPullModal}>Pull vehicle from the auction</StyledButton>;
  } else if (
    currentVehicle.status === VEHICLE_STATUSES.PULLED ||
    currentVehicle.status === SELLER_VEHICLE_STATUS.PENDING
  ) {
    blockToRender = <StatusComponent vehicle={currentVehicle} bid={highestBid} sellerAcceptBid={acceptBid} />;
  } else if (isVehicleTimerEnded || AUCTION_STATUSES.ENDED === currentVehicle.auction.status) {
    if (
      !currentVehicle.buyer_id &&
      currentVehicle.auction.status !== AUCTION_STATUSES.ENDED &&
      vehicleOfferAuctionStatus === SELLER_VEHICLE_STATUS.NEED_SELLER_REVIEW
    ) {
      blockToRender = (
        <>
          <StatusComponent vehicle={currentVehicle} bid={highestBid} sellerAcceptBid={acceptBid} />
          {highestBid.amount > 0 && offerAuction?.status === OfferAuctionStatus.PRIVATE_BIDS && (
            <>
              <StyledDivider />
              <CounterComponent bid={highestBid} vehicle={currentVehicle} />
            </>
          )}
        </>
      );
    } else {
      blockToRender = <StatusComponent vehicle={currentVehicle} bid={highestBid} sellerAcceptBid={acceptBid} />;
    }
  } else if (currentVehicle.status === VEHICLE_STATUSES.AWARDED) {
    blockToRender = (
      <StatusComponent bid={currentVehicle.highest_bid} vehicle={currentVehicle} sellerAcceptBid={acceptBid} />
    );
  }
  return (
    <>
      {blockToRender}
      <StyledDivider />
      <ConfirmModal
        submitText={'Submit'}
        modalId={confirmPullModal}
        title={`Are you sure you want to pull this vehicle from the auction?${
          !isVehicleTimerEnded ? ' Once it is pulled, no more bids can be made.' : ''
        }`}
        handleSubmit={() => {
          // todo: refactor
          dispatch(pullVehicle({ vehicle_id: currentVehicle.id }).request);
          dispatch(modalsToggle(confirmPullModal));
        }}
      />
    </>
  );
};

export default SellerActiveOffer;
