import styled from 'styled-components';
import { CameraHeader } from '../components/Header';
import { TakePhoto } from '../components/TakePhoto';

export const TakePhotoView = () => {
  return (
    <>
      <CameraHeader />
      <TakePhoto />
    </>
  );
};
