import React from 'react';
import { MinimizeScreen } from '../../../icons/FullScreenIcons';

type Props = {
  isFullscreen: boolean;
  onClick: React.MouseEventHandler<HTMLElement>;
  type: 'main' | 'fullscreen';
};

export const FullscreenButton = React.memo(({ onClick }: Props) => {
  return (
    <button
      type="button"
      className="image-gallery-icon image-gallery-fullscreen-button"
      onClick={onClick}
      aria-label="Open Fullscreen"
    >
      <MinimizeScreen />
    </button>
  );
});
