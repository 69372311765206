import styled from 'styled-components';
import { useImageUploadContext } from '../../../context/ImageUploadContext';
import { Field } from 'react-final-form';
import { NotesIcon } from '../../../../CreateVehicle/icons/NotesIcon';
import { useWindowSize } from 'usehooks-ts';
import { ConfirmImageControls } from './ConfirmImageControls';

const Wrapper = styled.div`
  padding: 8px 16px 29px 16px;
  background: rgba(0, 0, 0, 0.7);
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;

  @media (orientation: portrait) {
    display: flex;
    flex-direction: column;
    gap: 8px;
    height: 144px;
  }
`;

const InputWrapper = styled.div`
  border: 1px solid rgba(255, 255, 255, 0.4);
  padding: 8px 16px;
  display: flex;
  gap: 4px;
  border-radius: 30px;
  align-items: center;
`;

const StyledInput = styled.input`
  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  background: transparent;
  border: none;
  width: 100%;
  color: #fff;
`;

const NotesIconWrapper = styled.div`
  display: flex;

  & > svg {
    width: 24px;
    height: 24px;
  }
`;

export const ImageNotes = () => {
  const { sectionId, subSectionId, currentImageIndex } = useImageUploadContext();
  const fieldName = `${sectionId}.${subSectionId}.images.${currentImageIndex}.notes`;

  const { width = 0, height = 0 } = useWindowSize();
  const renderControls = height > width;

  return (
    <Wrapper>
      <Field name={fieldName}>
        {props => (
          <InputWrapper>
            <NotesIconWrapper>
              <NotesIcon disableOuterBackground isActive={!!props.input.value} />
            </NotesIconWrapper>
            <StyledInput placeholder="Note..." {...props.input} />
          </InputWrapper>
        )}
      </Field>
      {renderControls && <ConfirmImageControls />}
    </Wrapper>
  );
};
