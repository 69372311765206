import { Icon } from '@material-ui/core';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import {
  CustomersIcon,
  BidsHistoryIcon,
  DashboardIcon,
  LiveAuctionIcon,
  ManageVehiclesIcon,
  MapIcon,
  MyBidsIcon,
  TransactionsIcon,
  VehiclesIcon
} from '../Icons/MobileNav';
import { useSelector } from 'react-redux';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 24px;
  font-size: 10px;
  font-weight: 500;
  position: fixed;
  bottom: 0;
  width: 100vw;
  background: #fff;
  z-index: 999;

  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;

  &.hidden {
    transform: translateY(100%);
    opacity: 0;
  }

  &.visible {
    transform: translateY(0);
    opacity: 1;
  }
`;

const NavWrapper = styled.div<{ isActive: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2px;
  cursor: pointer;
  color: ${props => (props.isActive ? '#3994de' : '#A7A9B7')};
`;

const Label = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;
  position: relative;
`;

const BulletPoint = styled.div`
  background: #3994de;
  width: 8px;
  height: 8px;
  border-radius: 100%;
  position: absolute;
  left: -12px;
`;

const activeIconColor = '#3994DE';
const iconColor = '#A7A9B7';

const buyerNavItems = [
  {
    label: 'Live Auctions',
    link: '/live-auction',
    NavIcon: LiveAuctionIcon
  },
  {
    label: 'My Bids',
    link: '/buyer/bids',
    NavIcon: MyBidsIcon
  },
  {
    label: 'Bids History',
    link: '/buyer/bids-history',
    NavIcon: BidsHistoryIcon
  },
  {
    label: 'Transactions',
    link: '/buyer/transactions',
    NavIcon: TransactionsIcon
  }
];

const sellerNavItems = [
  {
    label: 'Live Auctions',
    link: '/live-auction',
    NavIcon: LiveAuctionIcon
  },
  {
    label: 'Dashboard',
    link: '/seller/dashboard',
    NavIcon: MyBidsIcon
  },
  {
    label: 'Sales History',
    link: '/seller/vehicle-sales/history',
    NavIcon: BidsHistoryIcon
  },
  {
    label: 'Manage Vehicles',
    link: '/seller/vehicles',
    NavIcon: ManageVehiclesIcon
  }
];

const listerNavItems = [
  {
    label: 'Dashboard',
    link: '/lister/dashboard',
    NavIcon: DashboardIcon
  },
  {
    label: 'Map',
    link: '/lister/map',
    NavIcon: MapIcon
  },
  {
    label: 'Vehicles',
    link: '/admin/vehicles',
    NavIcon: VehiclesIcon
  },
  {
    label: 'Customers',
    link: '/admin/customers',
    NavIcon: CustomersIcon
  }
];

export const MobileNavigation = () => {
  const location = useLocation();
  const history = useHistory();

  const user = useSelector((state: any) => state.user.user);

  if (!user?.role || (user.role === 'admin' && user.job_title !== 'Lister')) return null;

  const navItems = (() => {
    if (user.job_title === 'Lister') return listerNavItems;
    return user.role === 'seller' ? sellerNavItems : buyerNavItems;
  })();

  return (
    <Wrapper id={'mobile-nav'}>
      {navItems.map(item => {
        const isActive = location.pathname === item.link;
        return (
          <NavWrapper key={item.label} isActive={isActive} onClick={() => history.push(item.link)}>
            <Icon style={{ color: 'blue' }}>{<item.NavIcon fillColor={isActive ? activeIconColor : iconColor} />}</Icon>
            <Label>
              {location.pathname === item.link && <BulletPoint />}
              <span>{item.label}</span>
            </Label>
          </NavWrapper>
        );
      })}
    </Wrapper>
  );
};
