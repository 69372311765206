import styled from 'styled-components';
import { useImageUploadContext } from '../../../context/ImageUploadContext';
import { CameraHeader } from '../components/Header';
import { ImageNotes } from '../components/ImageNotes';
import { ConfirmImageControls } from '../components/ConfirmImageControls';
import { useWindowSize } from 'usehooks-ts';

const Wrapper = styled.div<{ imageUrl: string }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-image: ${({ imageUrl }) => `url(${imageUrl})`};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ConfirmPhotoView = () => {
  const { currentImageSrc } = useImageUploadContext();
  const { width = 0, height = 0 } = useWindowSize();

  if (!currentImageSrc) return null;

  const isLandscape = width > height;

  return (
    <Wrapper imageUrl={currentImageSrc}>
      <CameraHeader />
      <ImageNotes />
      {isLandscape && <ConfirmImageControls />}
    </Wrapper>
  );
};
