import { Field } from 'react-final-form';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const gradeColorMap: Record<string, string> = {
  poor: 'rgba(175, 41, 48, 1)',
  fair: 'rgba(243, 175, 103, 1)',
  good: 'rgba(71, 151, 71, 1)'
};

const GradeInput = styled.div<{ isActive: boolean; slug: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 0;
  font-family: Roboto;
  font-size: 14px;
  line-height: 16px;
  border-radius: 8px;
  color: ${props => (props.isActive ? '#fff' : '#000')};
  font-weight: ${props => (props.isActive ? '600' : '400')};
  border: 1px solid rgba(153, 153, 153, 1);
  background: ${props => (props.isActive ? gradeColorMap[props.slug] : 'rgba(34, 34, 34, 0.04)')};
`;

const gradesArray = [
  { slug: 'poor', label: 'Poor' },
  { slug: 'fair', label: 'Fair' },
  { slug: 'good', label: 'Good' }
];

export const GradesField = ({ sectionName, readonly = false }: { sectionName: string; readonly?: boolean }) => {
  const fieldName = `${sectionName}.grade`;
  return (
    <Field name={fieldName}>
      {props => (
        <Wrapper>
          {gradesArray.map(g => (
            <GradeInput
              onClick={() => (!readonly ? props.input.onChange(g.slug) : undefined)}
              key={g.slug}
              isActive={props.input.value === g.slug}
              slug={g.slug}
            >
              {g.label}
            </GradeInput>
          ))}
        </Wrapper>
      )}
    </Field>
  );
};
