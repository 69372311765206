import { useNoSelectorVehicleOfferAuctionById } from 'hooks';
import { auctionOfferStatuses } from 'utils/statusCalculator';
import { useViewedVehicle } from '../../../../hooks/useViewedVehicle';
import { useCurrentUser } from '../../../../../../../hooks/selectors';
import styled from 'styled-components';
import {
  ADMIN_VEHICLE_LABELS,
  ADMIN_VEHICLE_STATUS,
  BUYER_VEHICLE_LABELS,
  BUYER_VEHICLE_STATUS,
  SELLER_VEHICLE_LABELS,
  SELLER_VEHICLE_STATUS
} from '../../../../../../../constants';
import { User } from '../../../../../../../types/user';
import { Vehicle } from '../../../../../../../types/vehicle';

const LabelButton = styled.div<{ color: string; background: string }>`
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  padding: 6px 8px;
  font-size: 12px;
  font-weight: 600;
  line-height: 14px;
  background: ${props => props.background};
  min-width: fit-content;
  color: ${props => props.color};
`;

const defaultColorStyle = { background: 'rgba(253, 183, 20, 0.41)', color: '#000' };

const greenLabel = { background: '#06A000', color: '#fff' };
const redLabel = { background: 'rgba(175, 41, 48, 1)', color: '#fff' };
const yellowLabel = { background: 'rgba(253, 183, 20, 0.41)', color: '#000' };

const colorMap: Record<string, (s: string) => { background: string; color: string }> = {
  buyer: (status: string) => {
    if (
      [
        BUYER_VEHICLE_STATUS.HIGH_BID,
        BUYER_VEHICLE_STATUS.AWARDED,
        BUYER_VEHICLE_STATUS.AWARDED_NOT_CHARGED,
        BUYER_VEHICLE_STATUS.AWARDED_PENDING_CHARGE
      ].includes(status)
    ) {
      return greenLabel;
    }

    if ([BUYER_VEHICLE_STATUS.PULLED, BUYER_VEHICLE_STATUS.DECLINED].includes(status)) {
      return redLabel;
    }

    return yellowLabel;
  },
  seller: (status: string) => {
    if ([SELLER_VEHICLE_STATUS.AWARDED, SELLER_VEHICLE_STATUS.IN_PROGRESS].includes(status)) {
      return greenLabel;
    }

    if ([SELLER_VEHICLE_STATUS.PULLED].includes(status)) {
      return redLabel;
    }

    return yellowLabel;
  },
  admin: (status: string) => {
    if ([ADMIN_VEHICLE_STATUS.AWARDED, ADMIN_VEHICLE_STATUS.IN_PROGRESS].includes(status)) {
      return greenLabel;
    }

    if ([ADMIN_VEHICLE_STATUS.PULLED, ADMIN_VEHICLE_STATUS.UNDECIDED].includes(status)) {
      return redLabel;
    }

    return yellowLabel;
  }
};

const modifyLabel = (label: string, status: string, vehicle: Vehicle, user: User) => {
  if (user.role === 'buyer' && status === BUYER_VEHICLE_STATUS.OUT_BID && !vehicle.highest_bid) {
    return 'In Progress';
  }

  return label;
};

export const StatusLabel = () => {
  const vehicle = useViewedVehicle();
  const currentUser = useCurrentUser();

  const offerAuction = useNoSelectorVehicleOfferAuctionById(vehicle?.id || 0);
  const status = auctionOfferStatuses(vehicle, offerAuction, currentUser);

  if (!vehicle) return null;

  const labelMap: Record<string, Record<string, any>> = {
    admin: ADMIN_VEHICLE_LABELS,
    buyer: BUYER_VEHICLE_LABELS,
    seller: SELLER_VEHICLE_LABELS
  };

  const { label = 'N/A' } = labelMap[currentUser.role][status] || {};

  const { background, color } = colorMap[currentUser.role](status) || defaultColorStyle;

  const modifiedLabel = modifyLabel(label, status, vehicle, currentUser);

  return (
    <LabelButton background={background} color={color}>
      <span>{modifiedLabel}</span>
    </LabelButton>
  );
};
