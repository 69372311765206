import { Field, useForm } from 'react-final-form';
import styled from 'styled-components';

const InputWrapper = styled.div`
  border: 1px solid rgba(153, 153, 153, 1);
  border-radius: 8px;
  padding: 24.5px 16px;
`;

const Input = styled.input`
  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  border: none;
  width: 100%;
  padding: 0;
  color: #000;
`;

export const NotesField = ({ sectionName, readonly = false }: { sectionName: string; readonly?: boolean }) => {
  const fieldName = `${sectionName}.notes`;
  const form = useForm();
  const state = form.getState();

  return (
    <Field name={fieldName}>
      {props => (
        <InputWrapper>
          <Input {...props.input} readOnly={readonly} placeholder="Add note..." />
        </InputWrapper>
      )}
    </Field>
  );
};
