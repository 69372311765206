import { Fragment } from 'react';
import { useDispatch } from 'react-redux';
import { Grid, Typography, Divider, Button } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import styled from 'styled-components';

import { modalsToggle } from 'actions';
import ConfirmModal from 'components/Modals/ConfirmModal';
import { USER_STATUSES, ADMIN_JOB_TITLES } from 'constants';
import { SendOfferModal } from 'components/Offers';
import moment from 'moment';
import { useViewedVehicle } from '../../../../hooks/useViewedVehicle';
import { useCurrentUser } from '../../../../../../../hooks/selectors';
import {
  useGetBuyerFeeQuery,
  useRetryFeeChargeMutation,
  useUnawardVehicleMutation
} from '../../../../../../../services/api/vehicle-actions';

const modalUnawardTitle = 'unawardVehicleModal';
const chargeModalTitle = 'retryChargeBuyer';

const StyledViewReportOfSale = styled(Typography)`
  && {
    color: #005a96;
    text-decoration: underline;
    flex-basis: 100%;
    cursor: pointer;
    text-align: right;
    font-weight: 500;
    margin-top: 17px;
  }
`;

const StyledDivider = styled(Divider)`
  && {
    margin: 24px 0;
  }
`;

const StyledDescription = styled(Typography)`
  && {
    color: #616161;
    margin-top: 8px;
  }
`;

const StyledText = styled.span`
  && {
    color: #212121;
  }
`;

const StyledSpan = styled(Typography)`
  && {
    text-decoration: underline;
    cursor: pointer;
    font-weight: 500;
  }
`;

const StyledButton = styled(Button)`
  && {
    margin: 16px 0;
  }
`;

const AwardedBlock = () => {
  const currentVehicle = useViewedVehicle();
  const currentUser = useCurrentUser();
  const [retryTransaction] = useRetryFeeChargeMutation();
  const [unawardVehicle] = useUnawardVehicleMutation();
  const dispatch = useDispatch();

  const { data: fee } = useGetBuyerFeeQuery(currentVehicle?.price || 0, {
    skip: !currentVehicle || !currentVehicle.price
  });

  if (!currentVehicle) return null;

  const handleClickUnaward = () => {
    const isTimeEnded = currentVehicle.date_end && moment.utc(currentVehicle.date_end).isBefore(moment.utc());
    if (!isTimeEnded) {
      dispatch(modalsToggle(modalUnawardTitle));
    } else {
      dispatch(modalsToggle(`unaward-${currentVehicle.id}`));
    }
  };

  const handleUnaward = () => {
    unawardVehicle(currentVehicle.id);

    dispatch(modalsToggle(modalUnawardTitle));
  };

  const handleClickViewReport = (role: string) => {
    window.open(currentVehicle[`${role}_report_of_sale`] || currentVehicle.seller_report_of_sale);
  };

  return (
    <Fragment>
      {![ADMIN_JOB_TITLES.SALESMAN, ADMIN_JOB_TITLES.LISTER].includes(currentUser.job_title) && (
        <StyledSpan onClick={handleClickUnaward}>Unaward vehicle</StyledSpan>
      )}
      <ConfirmModal
        modalId={modalUnawardTitle}
        title="Are you sure you want to unaward this vehicle?"
        handleSubmit={() => {
          handleUnaward();
        }}
        submitText="Unaward"
      />
      <Grid item xs={12}>
        <StyledDivider />
      </Grid>
      {!currentVehicle.charge_completed && currentVehicle.awarded_user.status !== USER_STATUSES.DISABLED && (
        <Fragment>
          <StyledText>The buyer updated his/her payment method. You may retry charging now.</StyledText>
          <StyledButton variant="contained" color="primary" onClick={() => modalsToggle(chargeModalTitle)}>
            charge buyer
          </StyledButton>
          <Grid item xs={12}>
            <StyledDivider />
          </Grid>
          <ConfirmModal
            submitText={'Confirm'}
            title="Are your sure you want to retry charging?"
            handleSubmit={() =>
              retryTransaction({
                userId: currentVehicle.awarded_user.id,
                vehicleId: currentVehicle.id,
                auction_id: currentVehicle.auction_id,
                amount: fee
              })
            }
            modalId={chargeModalTitle}
          />
        </Fragment>
      )}
      <StyledDescription>
        This vehicle was awarded to <StyledText>{currentVehicle.awarded_user.dealership_name}</StyledText> for the
        amount of{' '}
        <StyledText>
          <NumberFormat displayType="text" prefix="$" value={currentVehicle.vehicle_price} thousandSeparator />
        </StyledText>
      </StyledDescription>

      {currentVehicle.admin_report_of_sale && (
        <>
          <StyledViewReportOfSale onClick={() => handleClickViewReport('admin')}>
            View Admin Report of Sale
          </StyledViewReportOfSale>
          <StyledViewReportOfSale onClick={() => handleClickViewReport('buyer')}>
            View Buyer Report of Sale
          </StyledViewReportOfSale>
          <StyledViewReportOfSale onClick={() => handleClickViewReport('seller')}>
            View Seller Report of Sale
          </StyledViewReportOfSale>
        </>
      )}

      <Grid item xs={12}>
        <StyledDivider />
      </Grid>
      <SendOfferModal
        maxAmount={currentVehicle.reserve_price}
        form={`unaward-${currentVehicle.id}`}
        vehicle={currentVehicle}
        type="unaward"
      />
    </Fragment>
  );
};

export default AwardedBlock;
