import styled from 'styled-components';
import { SectionConfig } from '../../../services/api/vehicle-sections/types';
import { useGetSubSectionConfig } from '../../CreateVehicle/hooks/useGetSubSectionConfig';
import { ExpandIcon } from '../../../components/Icons/ExpandIcon';
import { DragIndicator } from '../../CreateVehicle/icons/DragIndicator';

const Wrapper = styled.div`
  background: #fff;
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 9px 8px;
  width: 100%;
`;

const IconWrapper = styled.div`
  display: flex;
`;

const Title = styled.div`
  font-size: 14px;
  font-weight: 600;
  line-height: 16.41px;
  color: #000;
`;

const RequiredSign = styled.span`
  color: rgba(175, 41, 48, 1);
`;

type Props = {
  sectionName: string;
};

export const DraggableSubSectionOverlay = ({ sectionName }: Props) => {
  const [sectionId, subSectionId] = sectionName.split('.');
  const config = useGetSubSectionConfig(sectionId as keyof SectionConfig, subSectionId);

  if (!config) return null;

  return (
    <Wrapper>
      <IconWrapper>
        <ExpandIcon fillColor="rgba(153, 153, 153, 1)" />
      </IconWrapper>
      <Title>
        {config.label}
        {config.required ? <RequiredSign>*</RequiredSign> : null}
      </Title>
      <IconWrapper
        id={`activator-${sectionId}-${subSectionId}`}
        style={{ color: 'rgba(204, 204, 204, 1)', marginLeft: 'auto' }}
      >
        <DragIndicator />
      </IconWrapper>
    </Wrapper>
  );
};
