import styled from 'styled-components';
import { User } from '../../../../../types/user';
import { useCurrentUser } from '../../../../../hooks/selectors';
import { useViewedVehicle } from '../../hooks/useViewedVehicle';
import { Vehicle } from '../../../../../types/vehicle';
import { Link } from 'react-router-dom';

const Wrapper = styled.div`
  display: flex;
  gap: 4px;
  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
  color: #000;
  padding-left: 8px;

  @media (min-width: 1024px) {
    font-size: 16px;
    line-height: 18.75px;
  }
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: #000;

  &:hover {
    text-decoration: underline;
  }
`;

const resolveSecondPart = (user: User) => {
  if (user.role === 'buyer') {
    return { label: 'My Bids', link: '/buyer/bids' };
  }

  return { label: 'My Bids', link: '/buyer/bids' };
};

const resolveCrumbs = (vehicle: Vehicle, user: User) => {
  const crumbs = [{ label: 'Homepage', link: '/' }];
  const vehicleTitle = `${vehicle.year} ${vehicle.make} ${vehicle.model}`;
  const vehicleCrumb = { label: vehicleTitle, link: `/vehicles/${vehicle.id}/view` };
  const activeAuction = vehicle.auction;
  if (!activeAuction) {
    return [...crumbs, vehicleCrumb];
  }

  const secondPart = resolveSecondPart(user);
  crumbs.push(secondPart);
  crumbs.push(vehicleCrumb);

  return crumbs;
  // crumbs.push({ label: `Auction ${activeAuction.id}`, link: '' });
};

export const Breadcrumbs = () => {
  const vehicle = useViewedVehicle();
  if (!vehicle) return null;
  const user = useCurrentUser();

  const crumbs = resolveCrumbs(vehicle, user);

  return (
    <Wrapper>
      {crumbs.map(({ link, label }, i) => {
        return (
          <>
            <StyledLink key={label} to={link}>
              {label}
            </StyledLink>
            {i !== crumbs.length - 1 && <span key={label + link}>/</span>}
          </>
        );
      })}
    </Wrapper>
  );
};
