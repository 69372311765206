import styled from 'styled-components';
import { NotesIcon, PhotosIcon, ActionIcon } from './icons';

const Wrapper = styled.div`
  display: flex;
  gap: 30px;
  margin: 0 auto;
`;

const ControlWrapper = styled.div<{ isActive: boolean }>`
  display: flex;
  gap: 8px;
  font-size: 12px;
  font-weight: 500;
  align-items: center;
  line-height: 16px;
  color: ${props => (props.isActive ? 'rgba(57, 148, 222, 1)' : 'rgba(117, 117, 117, 1)')};
`;

const controls = [
  {
    label: 'Notes',
    Icon: NotesIcon,
    slug: 'notes'
  },
  {
    label: 'Photos',
    Icon: PhotosIcon,
    slug: 'photos'
  },
  {
    label: 'Actions',
    Icon: ActionIcon,
    slug: 'actions'
  }
];

type Props = {
  activeControl: string;
  setActiveControl: (v: string) => void;
};

export const BottomControls = ({ activeControl, setActiveControl }: Props) => {
  return (
    <Wrapper>
      {controls.map(c => (
        <ControlWrapper onClick={() => setActiveControl(c.slug)} key={c.slug} isActive={activeControl === c.slug}>
          <c.Icon isActive={activeControl === c.slug} />
          <span>{c.label}</span>
        </ControlWrapper>
      ))}
    </Wrapper>
  );
};
