import styled from 'styled-components';
import { useImageUploadContext } from '../../../context/ImageUploadContext';

const Wrapper = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  gap: 6px;
  right: 16px;
  top: 64px;
  align-items: flex-end;

  @media (orientation: portrait) {
    position: relative;
    right: unset;
    top: unset;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
  }
`;

const ActionButton = styled.div<{ isHighlighted?: boolean }>`
  border: 1px solid rgba(255, 255, 255, 0.4);
  background: rgba(0, 0, 0, 0.7);
  color: ${props => (props.isHighlighted ? 'rgba(116, 192, 255, 1)' : '#fff')};
  font-size: 18px;
  font-weight: 400;
  line-height: 21.09px;
  padding: 16px;
  width: fit-content;
  border-radius: 30px;

  @media (orientation: portrait) {
    border: none;
    background: inherit;
    font-size: 16px;
    font-weight: 400;
    line-height: 18.75px;
    padding: 0;
  }
`;

const ImageAmountCounter = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: 3px solid rgba(255, 255, 255, 1);
  font-size: 17px;
  font-weight: 700;
  line-height: 22px;
  border-radius: 30px;
  color: #fff;

  @media (orientation: portrait) {
    position: fixed;
    right: 16px;
    bottom: 160px;
  }
`;

export const ConfirmImageControls = () => {
  const { currentImageIndex, confirmImage, retakeImage } = useImageUploadContext();
  return (
    <Wrapper>
      <ActionButton onClick={retakeImage}>Retake</ActionButton>
      <ActionButton>Edit</ActionButton>
      <ActionButton onClick={confirmImage} isHighlighted>
        Take Next
      </ActionButton>
      <ImageAmountCounter>{currentImageIndex + 1}</ImageAmountCounter>
    </Wrapper>
  );
};
