type Props = {
  isActive: boolean;
};

const getFillColor = (isActive: boolean) => (isActive ? '#3994DE' : '#666666');

export const NotesIcon = ({ isActive }: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <g opacity="0.8">
      <path
        d="M7.85872 8.65039C8.06983 8.65039 8.25456 8.57122 8.41289 8.41289C8.57122 8.25456 8.65039 8.06983 8.65039 7.85872C8.65039 7.64762 8.57102 7.4629 8.41227 7.30457C8.25352 7.14623 8.07039 7.06707 7.86289 7.06707C7.64901 7.06707 7.4629 7.14644 7.30457 7.30519C7.14623 7.46392 7.06707 7.64704 7.06707 7.85454C7.06707 8.06844 7.14623 8.25456 7.30457 8.41289C7.4629 8.57122 7.64762 8.65039 7.85872 8.65039ZM7.85872 12.7879C8.0674 12.7879 8.25152 12.7101 8.41107 12.5546C8.57062 12.399 8.65039 12.2143 8.65039 12.0004C8.65039 11.7865 8.57102 11.6004 8.41227 11.4421C8.25352 11.2837 8.07039 11.2046 7.86289 11.2046C7.64901 11.2046 7.4629 11.2837 7.30457 11.4421C7.14623 11.6004 7.06707 11.7865 7.06707 12.0004C7.06707 12.2143 7.14683 12.399 7.30637 12.5546C7.46592 12.7101 7.65003 12.7879 7.85872 12.7879ZM7.85872 16.9337C8.06983 16.9337 8.25456 16.8545 8.41289 16.6962C8.57122 16.5379 8.65039 16.3532 8.65039 16.1421C8.65039 15.9309 8.57102 15.7462 8.41227 15.5879C8.25352 15.4296 8.07039 15.3504 7.86289 15.3504C7.64901 15.3504 7.4629 15.4298 7.30457 15.5885C7.14623 15.7473 7.06707 15.9304 7.06707 16.1379C7.06707 16.3518 7.14623 16.5379 7.30457 16.6962C7.4629 16.8545 7.64762 16.9337 7.85872 16.9337ZM4.73372 20.8504C4.30663 20.8504 3.93597 20.6933 3.62174 20.379C3.30751 20.0648 3.15039 19.6941 3.15039 19.2671V4.73372C3.15039 4.30663 3.30751 3.93597 3.62174 3.62174C3.93597 3.30751 4.30663 3.15039 4.73372 3.15039H15.3087C15.5256 3.15039 15.7322 3.19266 15.9287 3.27719C16.1252 3.36174 16.2963 3.47781 16.4421 3.62539L20.3754 7.55872C20.523 7.70445 20.639 7.87557 20.7236 8.07207C20.8081 8.26857 20.8504 8.47522 20.8504 8.69204V19.2671C20.8504 19.6941 20.6933 20.0648 20.379 20.379C20.0648 20.6933 19.6941 20.8504 19.2671 20.8504H4.73372ZM4.73372 19.2671H19.2671V8.88372H15.9129C15.6907 8.88372 15.5025 8.80663 15.3483 8.65247C15.1941 8.4983 15.1171 8.31011 15.1171 8.08789V4.73372H4.73372V19.2671Z"
        fill={getFillColor(isActive)}
      />
    </g>
  </svg>
);

export const PhotosIcon = ({ isActive }: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M5 21C4.45 21 3.97917 20.8042 3.5875 20.4125C3.19583 20.0208 3 19.55 3 19V5C3 4.45 3.19583 3.97917 3.5875 3.5875C3.97917 3.19583 4.45 3 5 3H12.45C12.7833 3 13.0375 3.14583 13.2125 3.4375C13.3875 3.72917 13.4083 4.04167 13.275 4.375C13.1917 4.64167 13.125 4.90833 13.075 5.175C13.025 5.44167 13 5.71667 13 6C13 7.38333 13.4875 8.5625 14.4625 9.5375C15.4375 10.5125 16.6167 11 18 11C18.2833 11 18.5583 10.975 18.825 10.925C19.0917 10.875 19.3583 10.8083 19.625 10.725C19.9583 10.6083 20.2708 10.6333 20.5625 10.8C20.8542 10.9667 21 11.2167 21 11.55V19C21 19.55 20.8042 20.0208 20.4125 20.4125C20.0208 20.8042 19.55 21 19 21H5ZM6 17H18L14.25 12L11.25 16L9 13L6 17ZM18 9C17.7167 9 17.4792 8.90417 17.2875 8.7125C17.0958 8.52083 17 8.28333 17 8V7H16C15.7167 7 15.4792 6.90417 15.2875 6.7125C15.0958 6.52083 15 6.28333 15 6C15 5.71667 15.0958 5.47917 15.2875 5.2875C15.4792 5.09583 15.7167 5 16 5H17V4C17 3.71667 17.0958 3.47917 17.2875 3.2875C17.4792 3.09583 17.7167 3 18 3C18.2833 3 18.5208 3.09583 18.7125 3.2875C18.9042 3.47917 19 3.71667 19 4V5H20C20.2833 5 20.5208 5.09583 20.7125 5.2875C20.9042 5.47917 21 5.71667 21 6C21 6.28333 20.9042 6.52083 20.7125 6.7125C20.5208 6.90417 20.2833 7 20 7H19V8C19 8.28333 18.9042 8.52083 18.7125 8.7125C18.5208 8.90417 18.2833 9 18 9Z"
      fill={getFillColor(isActive)}
    />
  </svg>
);

export const ActionIcon = ({ isActive }: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M11 21V15H13V17H21V19H13V21H11ZM3 19V17H9V19H3ZM7 15V13H3V11H7V9H9V15H7ZM11 13V11H21V13H11ZM15 9V3H17V5H21V7H17V9H15ZM3 7V5H13V7H3Z"
      fill={getFillColor(isActive)}
    />
  </svg>
);
