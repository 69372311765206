import { useDispatch } from 'react-redux';
import { Grid, Button } from '@material-ui/core';
import styled from 'styled-components';
import { ADMIN_VEHICLE_STATUS, AUCTION_STATUSES } from 'constants';
import { ActiveOfferInfo, OfferHistory } from 'components/Offers';
import { OfferAuctionStatus } from 'types/vehicle';
import { auctionOfferStatuses } from 'utils/statusCalculator';
import { useViewedVehicle } from '../../../../hooks/useViewedVehicle';
import { useNoSelectorVehicleOfferAuctionById } from 'hooks';
import { useCurrentUser } from 'hooks/selectors';
import { OfferAuction } from 'types/vehicle';
import { SectionDivider } from '../../../../../../Shared/VehicleDetails/components';
import { CounterSection } from '../CounterSection';
import { ControlsComponent } from './ControlsComponent';

const modalTitle = 'confirmCounterModal';

const StyledGrid2 = styled(Grid)`
  padding: 0 32px 0 0;
`;
const StyledBold = styled.span`
  font-weight: 500;
  font-size: 18px;
`;

const CounterButton = styled(Button)`
  background-color: #3994de;

  &:hover {
    background-color: #3994de;
  }
`;

const parseAdminAuctionOfferInfo = (offerAuction: OfferAuction) => {
  const res = {
    sellerAmount: 0,
    buyerAmount: 0,
    adminAmount: 0
  };
  if (!offerAuction) return res;
  if (offerAuction.status === OfferAuctionStatus.PRIVATE_BIDS) {
    const buyerAmount = offerAuction.privateOffers?.find(offer => offer.sender.role === 'buyer')?.amount;
    const sellerAmount = offerAuction.privateOffers?.find(offer => !offer.is_admin && offer.sender.role === 'seller')
      ?.amount;
    const adminAmount = offerAuction.privateOffers?.find(offer => offer.is_admin)?.amount;

    res.buyerAmount = buyerAmount ?? res.buyerAmount;
    res.sellerAmount = sellerAmount ?? res.sellerAmount;
    res.adminAmount = adminAmount ?? res.adminAmount;
  }
  return res;
};

const CounterBid = () => {
  const currentVehicle = useViewedVehicle();
  const offerAuction = useNoSelectorVehicleOfferAuctionById(currentVehicle?.id || 0);
  const user = useCurrentUser();

  if (!currentVehicle || !offerAuction) return null;

  const { sellerAmount, adminAmount, buyerAmount } = parseAdminAuctionOfferInfo(offerAuction);
  const status = auctionOfferStatuses(currentVehicle, offerAuction, user);
  const isAuctionActive = currentVehicle.auction.status !== AUCTION_STATUSES.ENDED;

  return (
    <>
      {isAuctionActive && status !== ADMIN_VEHICLE_STATUS.BUYER_COUNTER_SENT && (
        <>
          <CounterSection vehicle={currentVehicle} text="Counter the Highest Bid" />
          <SectionDivider />
        </>
      )}

      <ActiveOfferInfo offerAuction={offerAuction} vehicle={currentVehicle} />

      {status === ADMIN_VEHICLE_STATUS.BUYER_COUNTER_SENT && <ControlsComponent vehicle={currentVehicle} />}
      {Boolean(offerAuction?.privateOffers?.length) && (
        <Grid item xs={12}>
          <SectionDivider />
        </Grid>
      )}
    </>
  );
};

export default CounterBid;
