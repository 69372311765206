import { useDispatch } from 'react-redux';
import { modalsToggle } from '../../../../../../../actions';
import { useViewedVehicle } from '../../../../hooks/useViewedVehicle';
import { useGetRelistDataQuery } from '../../../../../../../services/api/admin-auction-vehicles';
import styled from 'styled-components';
import { AuctionCreateModal } from '../../../../../../../components';
import { RelistDialog } from '../../../../../../../components/RelistDialog/RelistDialog';
import { openRelistDialog } from '../../../../../../../services/auctionVehicles/auctionVehiclesSlice';
import { VEHICLE_STATUSES } from '../../../../../../../constants';
import { useMemo } from 'react';
import { useGetActiveAuctionsQuery } from '../../../../../../../services/api/live-auction';

const modalNewAuction = 'newAuctionModal';

const StyledRelistSpan = styled.span`
  color: #06a000;
  text-decoration: underline;
  cursor: pointer;
  font-weight: 500;
  display: block;
  margin-bottom: 16px;
`;

const StyledDeactivateSpan = styled.span`
  color: #616161;
  text-decoration: underline;
  cursor: pointer;
  font-weight: 500;
  display: block;
`;

const StyledDusabledSpan = styled.span`
  color: rgba(0, 0, 0, 0.12);
`;

const StyledSmallerSpan = styled.span`
  font-size: 14px;
`;

export const RelistVehicleBlock = () => {
  const currentVehicle = useViewedVehicle();
  const dispatch = useDispatch();
  const { data: auctions, isLoading } = useGetActiveAuctionsQuery();
  const hasActiveAuction = useMemo(() => Boolean(auctions?.frontline) || Boolean(auctions?.autoaxess), [auctions]);

  if (!currentVehicle || isLoading) return null;

  const openVehicleRelistDialog = () => dispatch(openRelistDialog(currentVehicle.id));

  if (!hasActiveAuction) {
    return <NoAuctionRelistBlock />;
  }

  return (
    <>
      <StyledRelistSpan onClick={openVehicleRelistDialog}>Re-list vehicle in auction</StyledRelistSpan>
      <RelistDialog vehicle={currentVehicle} />
    </>
  );
};

const NoAuctionRelistBlock = () => {
  const dispatch = useDispatch();
  const openNewAuctionModal = () => {
    dispatch(modalsToggle(modalNewAuction));
  };

  return (
    <>
      <>
        <StyledDusabledSpan>Re-list vehicle in auction</StyledDusabledSpan>
        <br />
        <StyledSmallerSpan>
          There is no an active auction to relist the vehicle in.
          <br />
          <StyledDeactivateSpan onClick={openNewAuctionModal}>Create a New auction</StyledDeactivateSpan>
          <br />
        </StyledSmallerSpan>
        <AuctionCreateModal />
      </>
    </>
  );
};
