import styled from 'styled-components';
import { AskQuestionIcon } from '../../icons/AskQuestion';
import { useEffect, useRef, useState } from 'react';
import { Button, Dialog } from '@material-ui/core';
import { Field, Form, useFormState } from 'react-final-form';
import { useOnClickOutside, useWindowSize } from 'usehooks-ts';
import { useViewedVehicle } from '../../hooks/useViewedVehicle';
import { useCurrentUser } from '../../../../../hooks/selectors';
import { useAskQuestionMutation } from '../../../../../services/api/auth';
import { useDispatch } from 'react-redux';
import { snackShow } from '../../../../../actions';

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 9999%;
  width: 66px;
  height: 66px;
  padding: 16px;
  background: rgba(34, 90, 145, 1);
  position: absolute;
  bottom: 50px;
  right: 240px;

  @media (max-width: 1024px) {
    position: fixed;
    bottom: 100px;
    right: 16px;
    bottom: 86px;
  }
`;

const FormWrapper = styled.form`
  border: 1px solid rgba(204, 204, 204, 1);
  background: rgba(249, 249, 249, 1);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  padding: 30px 8px 8px 8px;
  gap: 8px;
  border-radius: 30px;
  min-width: 574px;
  position: absolute;
  bottom: 120px;
  right: 240px;

  @media (max-width: 1024px) {
    position: static;
    padding: 16px;
    min-width: unset;
  }
`;

const Title = styled.div`
  color: rgba(33, 33, 33, 1);
  font-family: Roboto;
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  padding-left: 16px;

  @media (max-width: 1024px) {
    font-size: 14px;
    line-height: 16.41px;
  }
`;

const InputWrapper = styled.div`
  display: flex;
  width: 100%;
  background: #fff;
  border-radius: 30px;
  padding: 16px;
  display: flex;
  gap: 9px;

  @media (max-width: 1024px) {
    padding: 8px;
    flex-direction: column;
  }
`;

const StyledButton = styled(Button)`
  background: rgba(34, 90, 145, 1);
  font-size: 16px;
  line-height: 18.75px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  width: 160px;
  padding: 9.63px 16px;

  @media (max-width: 1024px) {
    width: 100%;
  }
`;

const StyledInput = styled.input`
  border: 1px solid rgba(153, 153, 153, 1);
  border-radius: 24px;
  background: rgba(186, 186, 186, 0.1);
  font-size: 14px;
  line-height: 18px;
  padding: 9px 16px;
  flex: 1 1 100%;
`;

export const AskAQuestion = () => {
  const [formVisible, setFormVisible] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const formRef = useRef<HTMLFormElement>(null);

  const user = useCurrentUser();
  const vehicle = useViewedVehicle();

  const [askQuestionMutation] = useAskQuestionMutation();

  const toggleFormVisible = () => setFormVisible(c => !c);
  useOnClickOutside(formRef, e => {
    if (ref.current && ref.current.contains(e.target as any)) {
      return;
    }
    setFormVisible(false);
  });

  const dispatch = useDispatch();

  const { width = 0 } = useWindowSize();

  if (!vehicle || user.role !== 'buyer') return null;

  const staticMessageData = {
    vehicle: `${vehicle.year} ${vehicle.make} ${vehicle.model} ${vehicle.vin}`,
    fullName: `${user.first_name} ${user.last_name}`,
    email: user.email,
    phone: user.buyer.phone_number,
    vehicleId: vehicle.id
  };

  const handleSubmit = async (values: { question: string }) => {
    if (!values.question) {
      dispatch(snackShow({ type: 'error', message: 'Fill the question' }));
      return;
    }
    setFormVisible(false);
    await askQuestionMutation({ ...staticMessageData, message: values.question });
    dispatch(snackShow({ message: 'Question asked!' }));
  };

  return (
    <>
      <IconWrapper ref={ref} onClick={toggleFormVisible}>
        <AskQuestionIcon />
      </IconWrapper>
      {formVisible && (
        <Form
          onSubmit={handleSubmit}
          component={p =>
            width > 1024 ? (
              <AskQuestionForm handleSubmit={p.handleSubmit} formRef={formRef} />
            ) : (
              <AskQuestionDialog handleSubmit={p.handleSubmit} formRef={formRef} />
            )
          }
        />
      )}
    </>
  );
};

const AskQuestionForm = ({
  formRef,
  handleSubmit
}: {
  formRef: React.RefObject<HTMLFormElement>;
  handleSubmit: any;
}) => {
  const formState = useFormState();
  return (
    <FormWrapper onSubmit={handleSubmit} ref={formRef}>
      <Title>Ask a Question</Title>
      <InputWrapper>
        <Field name="question">{props => <StyledInput placeholder="Ask your question..." {...props.input} />}</Field>
        <StyledButton disabled={formState.submitting} type="submit" variant="contained" color="primary">
          Submit
        </StyledButton>
      </InputWrapper>
    </FormWrapper>
  );
};

const StyledDialog = styled(Dialog)`
  & .ask-question-paper {
    width: 100%;
    margin: 0 16px;
    border-radius: 30px;
    margin-top: auto;
    margin-bottom: 166px;
  }
`;

const AskQuestionDialog = ({
  formRef,
  handleSubmit
}: {
  formRef: React.RefObject<HTMLFormElement>;
  handleSubmit: any;
}) => {
  return (
    <StyledDialog open={true} classes={{ paperScrollPaper: 'ask-question-paper' }}>
      <AskQuestionForm formRef={formRef} handleSubmit={handleSubmit} />
    </StyledDialog>
  );
};
