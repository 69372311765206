import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import styled from 'styled-components';
import Select from 'react-select';
import { modalsToggle, auctionVehiclesSetModal } from 'actions';
import ConfirmModal from 'components/Modals/ConfirmModal';
import { useGetUsersQuery } from '../../../../../../../services/api/vehicle-actions';
import { useViewedVehicle } from '../../../../hooks/useViewedVehicle';
import { User } from '../../../../../../../types/user';
import { AdminAwardBuyerModal } from '../../../../../AuctionDetail/components';
import { SectionDivider, SectionTitle } from '../../../../../../Shared/VehicleDetails/components';

const modalTitle = 'confirmAwardModal';
const chargeModalTitle = 'auctionDetailAwardBuyerModal';

const StyledRoundedBlock = styled.div`
  border-radius: 4px;
  background: #f5f5f5;
  padding: 8px;
  width: 100%;
`;

const StyledGrid = styled(Grid)`
  padding: 0 32px 0 8px;
`;

const selectStyles = {
  control: (styles: any) => ({
    ...styles,
    backgroundColor: 'none',
    border: 'none',
    fontSize: '16px'
  })
};

const styles = {};

const AwardBuyer = () => {
  const { data: { users: allUsers = [] } = { users: [] } } = useGetUsersQuery();
  const vehicle = useViewedVehicle();
  const dispatch = useDispatch();
  const auctionVehicleModal = useSelector((state: any) => state.auctions.auctionVehiclesModal);
  const [buyerForAward, setBuyerForAward] = useState<User | null>(null);

  const users = useMemo(() => allUsers.filter(u => u.role === 'buyer'), [allUsers]);

  useEffect(() => {
    if (vehicle) {
      dispatch(auctionVehiclesSetModal(vehicle));
    }
  }, [vehicle]);

  if (!vehicle) return null;

  const handleChangeBuyer = (item: User) => {
    setBuyerForAward(item);
  };

  const renderDropDown = () => {
    const uniqueBidders = vehicle.bids.reduce((acc: any[], { bidder }: any) => {
      const isBidderAlreadyExists = acc.find(existingBidder => existingBidder.value === bidder.id);
      if (isBidderAlreadyExists) return acc;
      acc.push({
        value: bidder.id,
        label: `Bidder: ${bidder.dealership_name}`
      });
      return acc;
    }, [] as any[]);

    const usersWithoutBidders = (users || []).reduce<User[]>((acc, user) => {
      const isBidderAlreadyExists = uniqueBidders.find((existingBidder: any) => existingBidder.value === user.id);
      if (isBidderAlreadyExists) return acc;
      acc.push({
        value: user.id,
        label: user.dealership_name
      });
      return acc;
    }, []);

    return [...uniqueBidders, ...usersWithoutBidders];
  };

  const handleClickAward = () => {
    dispatch(modalsToggle(modalTitle));
  };

  const handleAward = () => {
    const preSelectedBuyer = buyerForAward;
    dispatch(auctionVehiclesSetModal({ ...vehicle, preSelectedBuyer, predefinedAwardPrice: null }));
    dispatch(modalsToggle(modalTitle));
    dispatch(modalsToggle(chargeModalTitle));
  };

  return (
    <>
      <SectionTitle>Award Vehicle to Buyer</SectionTitle>
      <StyledRoundedBlock>
        <Grid container>
          <StyledGrid item xs={12} sm={8}>
            <Select
              options={renderDropDown()}
              value={buyerForAward}
              onChange={handleChangeBuyer}
              styles={selectStyles}
              placeholder="Enter / Select Buyer"
            />
          </StyledGrid>
          <Grid item xs={12} sm={4}>
            <Button onClick={handleClickAward} color="primary" variant="contained" fullWidth disabled={!buyerForAward}>
              Award
            </Button>
          </Grid>
        </Grid>
      </StyledRoundedBlock>
      {(vehicle.bids || vehicle.vehicle_price) && (
        <ConfirmModal
          submitText={'Submit'}
          modalId={modalTitle}
          title={`You are about to award ${(buyerForAward || {}).label} the vehicle ${vehicle.year} ${vehicle.make} ${
            vehicle.model
          }?`}
          handleSubmit={() => {
            handleAward();
          }}
        />
      )}

      {auctionVehicleModal && <AdminAwardBuyerModal initialValues={{ vehicle_id: vehicle.id }} vehicle={vehicle} />}
      <SectionDivider />
    </>
  );
};

export default withStyles(styles)(AwardBuyer);
