import { AppBar, Icon, Toolbar, Typography } from '@material-ui/core';
import styled from 'styled-components';
import { PageWrapper } from '../../../components';
import { useSearchParams } from '../../../hooks';
import { useHistory } from 'react-router-dom';

const TypographyBreadcrumbs = styled(Typography)`
  font-size: 16px;
  flex-grow: 1;
`;

const StyledAppBar = styled(AppBar)`
  background: #fff;
  top: 72px;
`;

const MuiIcon = styled(Icon)`
  display: flex;
  margin-right: 8px;
  color: #000;
`;

const viewToLabel: Record<string, string> = {
  sections_list: 'Vehicles',
  section_expanded_view: 'Sections'
};

export const Header = () => {
  const searchParams = useSearchParams();
  const currentViewId = searchParams.get('view') || 'sections_list';

  const history = useHistory();

  if (currentViewId === 'section_upload_success') {
    return null;
  }

  const label = viewToLabel[currentViewId];

  const goBack = () => {
    if (currentViewId === 'sections_list') {
      return history.push('/admin/vehicles');
    }

    if (currentViewId === 'section_expanded_view') {
      return history.push('/lister/upload-vehicle/18975');
    }
  };

  return (
    <StyledAppBar position="fixed" color="default">
      <PageWrapper className="">
        <Toolbar disableGutters>
          <MuiIcon onClick={goBack}>arrow_back</MuiIcon>
          <TypographyBreadcrumbs variant="title">{label}</TypographyBreadcrumbs>
        </Toolbar>
      </PageWrapper>
    </StyledAppBar>
  );
};
