import React, { Component, Fragment } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Grid, Button, Divider } from '@material-ui/core';
import styled from 'styled-components';
import NumberFormat from 'react-number-format';
import { modalsToggle, adminApproveBid } from 'actions';
import ConfirmModal from 'components/Modals/ConfirmModal';
import ChargeBuyerModal from 'components/ChargeBuyerModal';
import { useViewedVehicle } from '../../../../hooks/useViewedVehicle';
import { useApproveHighBidMutation } from '../../../../../../../services/api/vehicle-actions';

const modalTitle = 'confirmApproveModal';
const chargeModalTitle = 'acceptHighBidCharge';

const StyledDivider = styled(Divider)`
  && {
    margin: 24px 0;
  }
`;

const StyledGrid = styled(Grid)`
  padding: 0 32px 8px 0;
  font-size: 14px;
`;

const StyledSpanTitle = styled.span`
  font-weight: 500;
  padding-left: 0;
  display: block;
  font-size: 18px;
  margin-bottom: 4px;
`;

const StyledGraySpan = styled.span`
  color: #616161;
`;

export const AcceptHighestBid = () => {
  const currentVehicle = useViewedVehicle();
  const dispatch = useDispatch();

  const [approveHighestBid] = useApproveHighBidMutation();

  if (!currentVehicle) return null;

  const highestBid = currentVehicle.highest_bid;

  const openApproveModal = () => {
    dispatch(modalsToggle(modalTitle));
  };

  const handleApprove = () => {
    dispatch(modalsToggle(modalTitle));
    dispatch(modalsToggle(chargeModalTitle));
  };

  return (
    <Fragment>
      <Grid container item xs={12}>
        <StyledGrid item xs={12} sm={8}>
          <StyledSpanTitle>Seller Accepted</StyledSpanTitle>
          <span>{`${currentVehicle.user.dealership_name}`}</span>
          <StyledGraySpan> has accepted the highest bid of </StyledGraySpan>
          <NumberFormat value={highestBid.amount} displayType="text" thousandSeparator prefix="$" />
          <StyledGraySpan> from </StyledGraySpan>
          <span>{` ${highestBid.bidder.dealership_name}`}</span>
        </StyledGrid>
        <Grid item xs={12} sm={4}>
          <Button onClick={openApproveModal} color="primary" variant="contained" fullWidth>
            Approve
          </Button>
        </Grid>
        <Grid item xs={12}>
          <StyledDivider />
        </Grid>
      </Grid>
      <ConfirmModal
        submitText={'Submit'}
        modalId={modalTitle}
        title={
          <>
            You are about to approve the high bid of{' '}
            <NumberFormat displayType="text" prefix="$" value={highestBid.amount} thousandSeparator /> from{' '}
            {highestBid.bidder.dealership_name}.
          </>
        }
        handleSubmit={handleApprove}
      />
      {(currentVehicle.bids.length || currentVehicle.vehicle_price) && (
        <ChargeBuyerModal
          modalId={chargeModalTitle}
          vehicle={currentVehicle}
          chargeAction={(data: any) => approveHighestBid({ vehicleId: currentVehicle.id, data })}
        />
      )}
    </Fragment>
  );
};
