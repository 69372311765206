import styled from 'styled-components';
import { useViewedVehicle } from '../../../hooks/useViewedVehicle';
import { BarCodeIcon } from '../../../icons/BarCodeIcon';
import { StatusLabel } from './Status';
import { useState } from 'react';
import { useWindowSize } from 'usehooks-ts';
import { Dialog } from '@material-ui/core';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  width: 100%;
`;

const LeftPart = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: column;
  align-items: baseline;

  @media (max-width: 1024px) {
    gap: 8px;
  }
`;

const Title = styled.div`
  font-size: 22px;
  font-weight: 700;
  line-height: 26px;

  @media (max-width: 1024px) {
    font-size: 16px;
    line-height: 18.75px;
  }
`;

const VinRow = styled.div`
  display: flex;
  font-size: 12px;
  align-items: center;
  font-weight: 500;
  line-height: 14px;
  color: rgba(153, 153, 153, 1);

  @media (max-width: 1024px) {
    font-size: 10px;
    line-height: 11.72px;
  }
`;

const VinValue = styled.span`
  font-weight: 400;
  margin-right: 2px;
`;

const BarCodeIconWrapper = styled.div`
  display: flex;
  position: relative;
`;

const BarCodeImageWrapper = styled.div`
  padding: 5px 11px;
  border: 1px solid rgba(153, 153, 153, 1);
  border-radius: 20px;
  position: absolute;
  top: -30px;
  background: #fff;
  right: -350px;

  @media (max-width: 1024px) {
    right: 0;
  }
`;

const StyledDialog = styled(Dialog)`
  .barcode-dialog {
    padding: 5px 11px;
    border: 1px solid rgba(153, 153, 153, 1);
    border-radius: 20px;
  }
`;

export const VehicleInfoHeader = () => {
  const vehicle = useViewedVehicle();
  const [barcodeHovered, setBarcodeHovered] = useState(false);
  const [modalOpened, setModalOpened] = useState(false);
  const closeModal = () => setModalOpened(false);

  const { width = 0 } = useWindowSize();
  const isMobile = width < 1024;

  if (!vehicle) return null;

  const handleBarcodeClick = () => {
    if (!isMobile) return;
    setModalOpened(true);
  };

  return (
    <Wrapper>
      <LeftPart>
        <Title>{`${vehicle.year} ${vehicle.make} ${vehicle.model}`}</Title>
        <VinRow>
          <span>VIN: </span>
          <VinValue>{vehicle.vin}</VinValue>
          <BarCodeIconWrapper
            onClick={handleBarcodeClick}
            onMouseOver={!isMobile ? () => setBarcodeHovered(true) : undefined}
            onMouseOut={!isMobile ? () => setBarcodeHovered(false) : undefined}
          >
            <BarCodeIcon />
            {barcodeHovered && vehicle.vin_code_url && (
              <BarCodeImageWrapper>
                <img src={vehicle.vin_code_url} alt="vincode" />
              </BarCodeImageWrapper>
            )}
          </BarCodeIconWrapper>
        </VinRow>
      </LeftPart>
      <StatusLabel />
      {isMobile && vehicle.vin_code_url && (
        <BarcodeModal isOpen={modalOpened} close={closeModal} url={vehicle.vin_code_url} />
      )}
    </Wrapper>
  );
};

type BarcodeModalProps = {
  isOpen: boolean;
  close: () => void;
  url: string;
};

const BarcodeModal = ({ isOpen, close, url }: BarcodeModalProps) => {
  return (
    <StyledDialog classes={{ paperScrollPaper: 'barcode-dialog' }} open={isOpen} onBackdropClick={close}>
      <img src={url} alt="vincode" />
    </StyledDialog>
  );
};
