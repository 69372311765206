import { Button } from '@material-ui/core';
import { FormSpy, useField, useForm, useFormState } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { useState } from 'react';
import { ConfirmSubmitDialog } from '../components/ConfirmSubmitDialog';
import { useSubmitVehicleForReviewMutation, useSubmitVehicleMutation } from '../../../services/api/upload-vehicle';
import { useGetVehicleId } from './useGetVehicleId';
import { useGetCurrentAuctionsQuery } from '../../../services/api/auctions-list';
import { VEHICLE_STATUSES } from '../../../constants';
import { snackShow } from '../../../actions';
import ConfirmModal from '../../../components/Modals/ConfirmModal';

const StyledButton = styled(Button)<{ uiType: 'cancel' | 'draft'; renderMobileIcon?: boolean }>`
  height: 43px;
  font-weight: 700;
  border-radius: 6px;
  width: ${props => (props.renderMobileIcon ? 'auto' : '140px')};

  .material-icons {
    display: ${props => (props.renderMobileIcon ? 'block' : 'none')};

    @media only screen and (min-width: 1024px) {
      display: none;
    }
  }

  span > span {
    @media only screen and (max-width: 1024px) {
      display: ${props => (props.renderMobileIcon ? 'none' : 'block')};
    }
  }

  @media (min-width: 1024px) {
    width: ${props => (props.uiType === 'cancel' ? '136px' : '210px')};
  }
`;

type Props = {
  renderMobileIcon?: boolean;
};

export const ConfirmLoadingImagesModal = ({ handleLeave }: any) => {
  return (
    <ConfirmModal
      title="Pictures are saving in the background, please wait"
      handleSubmit={handleLeave}
      submitText={'Leave'}
      modalId={'saving-images'}
    />
  );
};

const CancelButton = ({ renderMobileIcon }: Props) => {
  const history = useHistory();
  const currentUser = useSelector((state: any) => state.user.user);
  const leavePage = () => history.push(`/${currentUser.role}/vehicles`);
  const handleCancel = () => {
    const confirmation = window.confirm('Are you sure want to cancel vehicle creation?');
    if (confirmation) {
      leavePage();
    }
  };

  return (
    <StyledButton
      renderMobileIcon={renderMobileIcon}
      type="button"
      uiType="cancel"
      variant="contained"
      color="secondary"
      onClick={handleCancel}
    >
      <i className="material-icons">close</i>
      <span>Cancel</span>
      {/* <ConfirmLoadingImagesModal handleLeave={leavePage} /> */}
    </StyledButton>
  );
};

const SubmitButtonSpy = ({ renderMobileIcon }: Props) => {
  return (
    <FormSpy subscription={{ hasValidationErrors: true, errors: true }}>
      {({ hasValidationErrors, errors }) => (
        <SubmitButton errors={errors} hasValidationErrors={hasValidationErrors} renderMobileIcon={renderMobileIcon} />
      )}
    </FormSpy>
  );
};

const SubmitForReviewButton = ({ renderMobileIcon }: Props) => {
  const [submitForReview] = useSubmitVehicleForReviewMutation();
  const history = useHistory();
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);

  const form = useForm();
  const { hasValidationErrors, errors } = useFormState({ subscription: { hasValidationErrors: true, errors: true } });

  const vehicleId = useGetVehicleId();
  const currentUser = useSelector((state: any) => state.user.user);

  const {
    input: { value: status }
  } = useField('status');

  const dispatch = useDispatch();

  if (status !== VEHICLE_STATUSES.DRAFT) {
    return null;
  }

  const handleProceed = async () => {
    await submitForReview({ vehicleId });
    history.push(`/${currentUser.role}/vehicles`);
  };

  const handleSubmit = async () => {
    if (hasValidationErrors) {
      const keys = Object.keys(errors || {});
      if (keys.some(k => fieldsRequiredForSubmit.includes(k))) {
        for (const key of keys) {
          form.blur(key);
        }
        return dispatch(snackShow({ message: 'Fill required fields first', type: 'error' }));
      }
      setConfirmModalOpen(true);
    } else {
      await handleProceed();
    }
  };

  return (
    <>
      <StyledButton
        renderMobileIcon={renderMobileIcon}
        type="button"
        uiType="draft"
        variant="contained"
        color="primary"
        onClick={handleSubmit}
      >
        <i className="material-icons">save</i>
        <span>Submit For Review</span>
      </StyledButton>
      <ConfirmSubmitDialog
        handleProceed={handleProceed}
        closeModal={() => setConfirmModalOpen(false)}
        isOpen={confirmModalOpen}
      />
    </>
  );
};

const fieldsRequiredForSubmit = [
  'year',
  'make',
  'model',
  'trim',
  'engine',
  'transmission',
  'drive_train',
  'mileage',
  'bluebook_value',
  'bluebook_mileage'
];

const SubmitButton = ({
  renderMobileIcon,
  hasValidationErrors,
  errors
}: Props & { hasValidationErrors: boolean; errors: any }) => {
  const [submitVehicle] = useSubmitVehicleMutation();
  const history = useHistory();
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);

  const vehicleId = useGetVehicleId();
  const {
    input: { value: auctionType }
  } = useField('auction_type', { subscription: { value: true } });
  const currentUser = useSelector((state: any) => state.user.user);

  const { data } = useGetCurrentAuctionsQuery();
  const { autoaxess: currentAutoaxessAuction, frontline: currentFlAuction } = data || {};
  const currentAuction = auctionType === 'autoaxess' ? currentAutoaxessAuction : currentFlAuction;

  const {
    input: { value: status }
  } = useField('status');

  const form = useForm();
  const dispatch = useDispatch();

  if (
    !currentUser?.options?.reviewer ||
    ![VEHICLE_STATUSES.DRAFT, VEHICLE_STATUSES.UNDER_REVIEW].includes(status) ||
    !currentAuction
  ) {
    return null;
  }

  const handleProceed = async () => {
    await submitVehicle({ vehicleId, auctionType });
    history.push(`/${currentUser.role}/vehicles`);
  };

  const handleSubmit = async () => {
    if (hasValidationErrors) {
      const keys = Object.keys(errors || {});
      if (keys.some(k => fieldsRequiredForSubmit.includes(k))) {
        for (const key of keys) {
          form.blur(key);
        }
        return dispatch(snackShow({ message: 'Fill required fields first', type: 'error' }));
      }
      setConfirmModalOpen(true);
    } else {
      await handleProceed();
    }
  };

  return (
    <>
      <StyledButton
        renderMobileIcon={renderMobileIcon}
        type="button"
        uiType="draft"
        variant="contained"
        color="primary"
        disabled={!currentAuction}
        onClick={handleSubmit}
      >
        <i className="material-icons">send</i>
        <span>Submit</span>
      </StyledButton>
      <ConfirmSubmitDialog
        handleProceed={handleProceed}
        closeModal={() => setConfirmModalOpen(false)}
        isOpen={confirmModalOpen}
      />
    </>
  );
};

export const useGetFormControlButtons = () => {
  return { CancelButton, SubmitButton: SubmitButtonSpy, SubmitForReviewButton };
};
